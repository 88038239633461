import React, { Component } from "react";
import History5 from "../assets/images/History5.jpg";
import History6 from "../assets/images/History6.jpg";
import ImageHistory4 from "../assets/images/History4.jpg";

export class History3 extends Component {
	render() {
		return (
			<div className="flex flex-col my-4">
				<div className="flex flex-col my-4">
					<div className="font-bold">
						<p>
							<span className="text-4xl text-fifth">3. </span>Le Rwanda indépendant
						</p>
					</div>
					<div className="">
						<p>
							Le Rwanda indépendant devint un État sectaire divisé selon des lignes ethniques et régionales. La première République (1962-1973) négligea le développement du pays, se concentrant presque exclusivement sur l'érection d'un État violemment anti-Tutsi tout en marginalisant les personnes venant de l'extérieur de la région centrale du pays, base du régime. La deuxième république (1973-1994) instaurée par le président Habyarimana adopta une stratégie de développement visant à créer une nation de paysans autosuffisants, répondant à leurs besoins en nourriture. Elle était centrée sur la communauté locale et fermée au monde. Cette stratégie était sous-tendue par un système officiel d'exclusion ethnique et régionale. Cette fois, c’était la région du Nord d’où était originaire le président Habyarimana qui accaparait les ressources du pays. Les problèmes socio-économiques structurels exacerbés par la politique d'exclusion du régime conduirent à l'effondrement social et économique du pays pendant la seconde moitié des années 80, une période de disette généralisée et de violence sociale non-ethnique croissante.
						</p>
						<p className="my-4 font-semibold">La crise du début des années 1990</p>
						<p className="my-4">
							En 1990, les réfugiés rwandais vivants dans les pays voisins s’organisèrent sous la bannière du Front Patriotique Rwandais (FPR) et lancèrent une lutte armée pour transformer l'État rwandais, alors discriminatoire et économiquement défaillant. Dans le même temps, une opposition interne menée par des élites marginalisées du centre et du sud a également défié le gouvernement et a plaidé pour la démocratisation du pays. Pour contrer cette opposition, le régime Habyarimana tenta de redéfinir les termes du conflit politique qui l’opposait au FPR, le transformant en une confrontation ethnique. Pour ce faire, il créa une puissante coalition Hutu-power, en y intégrant une grande partie de l'ancienne opposition intérieure.
						</p>
						<div className="">
							<img src={ImageHistory4} alt="" className="w-full" />
						</div>
						<p className="my-4 font-semibold">
							Le génocide contre les Tutsis de 1994
						</p>
						<p>
							Après avoir échoué à déloger l'armée du FPR du nord du pays, le gouvernement rwandais accepta de mener des pourparlers de paix avec le FPR et signa les accords de paix d'Arusha le 4 Août 1993. Ceux-ci stipulait la fin de la guerre et la mise en place d'un gouvernement d'unité nationale à base élargie qui devait inclure le FPR et d'autres partis d'opposition. Les deux armées en conflit devaient également être intégrées.
						</p>
						<p className="my-4">
							La coalition Hutu-Power s'opposa avec virulence au processus de paix. Pour faire avorter la paix, conserver le pouvoir et préserver l'identité sectaire de l'État, les partisans de la ligne dure du régime abattirent l'avion du président Habyarimana et, avec le soutien de la coalition Hutu-Power, utilisèrent la mort du président Habyarimana comme prétexte pour déclencher le génocide contre les Tutsis. En cent jours, plus d'un million de Tutsis furent tués de façon indiscriminée dans tout le pays sous les yeux de la communauté internationale. Après trois mois de combat, le FPR mis fin au génocide en mettant en déroute les forces génocidaires et libera ensuite le pays.
						</p>
					</div>
				</div>
				<div className="grid gap-8 md:grid-cols-2">
					<div className="">
						<img src={History5} alt="" />
					</div>
					<div className="h-full ">
						<img src={History6} alt="" className="" />
					</div>
				</div>
			</div>
		);
	}
}

export default History3;
