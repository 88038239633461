import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
	HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import image022 from "../assets/images/Umushyikirano2.jpg";

export class UmushyikiranoHowItWorks extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Comment fonctionne <span className="italic">Umushyikirano</span>?</p>
				<div className="">
					<p className="my-4 text-xl font-semibold">Le rassemblement</p>
					<p className="my-4">
						Chaque année, environ un millier de personnes assistent à l'événement, tandis que des milliers d'autres suivent les débats en direct à la radio et à la télévision ou par le biais de plateformes en ligne. Grâce à cette technologie, la diaspora rwandaise peut également participer pleinement à l’évènement.
					</p>
					<p className="my-4">
						Chaque édition d'<span className="italic">Umushyikirano</span>  définit un thème. Les participants sont invités à poser des questions liées à ce sujet ou à toute autre question de développement. Dès qu'un participant a posé une question, que ce soit en présentiel ou en distanciel, le responsable de ce domaine est tenu d'y répondre. Toutes les questions sont enregistrées. L'année suivante, la session d'<span className="italic">Umushyikirano</span>  commence par la lecture d'un rapport qui détaille les actions entreprises en réponse aux questions et demandes formulées par le public l'année précédente. Ce rapport comprend les dates auxquelles les actions ont été menées à bien.
					</p>
					<p className="my-4">
						<span className="italic">Umushyikirano</span>  accueille également des rwandais de la diaspora, venus voir l’évolution du pays dans le cadre du programme "Come and See, Go and Tell". Il s'agit d'une initiative pour les inciter à visiter le Rwanda et à constater par eux-mêmes les efforts de reconstruction et de réconciliation. L'objectif étant que, de retour dans leurs pays hôtes, les membres de la diaspora mobilisent leurs familles et leurs communautés à rentrer chez elles, au Rwanda.
					</p>
					<div className="my-6">
						<div className="my-4">
							<img src={image022} alt="" />
						</div>
						<p className="text-xl font-semibold">Planification</p>
						<p className="my-4">
							Les réunions préparatoires à <span className="italic">Umushyikirano</span>  commencent en octobre de chaque année. Ces réunions sont présidées par le directeur de cabinet du PMO, responsable de la coordination générale. Les décisions budgétaires, le thème, les présentations à faire et autres questions relèvent également de sa responsabilité.
						</p>
						<p className="my-4">
							Des représentants des institutions suivantes participent également à l'organisation du Conseil national du dialogue:
						</p>
						<ul className="ml-8 hgs-list">
							<li>
								Bureau du Président (responsable de l'ordre du jour, du protocole et de la communication).
							</li>
							<li>
								Ministère des Finances et de la Planification Economique (responsable des questions budgétaires).
							</li>
							<li>
								Ministère de la Santé (responsable des premiers secours et des questions médicales d'urgence).
							</li>
							<li>
								Ministère de l'Administration Locale (chargé d'inviter les représentants des collectivités locales).
							</li>
							<li>
								Ministère des Affaires Etrangères et de la Coopération Internationale (responsable de l'invitation donnée aux membres de la diaspora et aux représentants internationaux).
							</li>
							<li>Ministère de l'Infrastructure (responsable du transport).</li>
							<li>
								Bureau du Porte-parole du gouvernement (co-responsable de la communication, de l'interaction sur les réseaux sociaux et de la plateforme multimédia gouvernementale « AskGov »).
							</li>
						</ul>
					</div>
					<div className="my-4">
						<p className="text-xl font-semibold">Processus de mise en œuvre</p>
						<p className="my-6">
							Le succès de la mise en œuvre des résolutions prises lors des réunions d’<span className="italic">Umushyikirano</span>  a eu des répercussions importantes. Cette mise en œuvre a été analysée au niveau local par le biais d'enquêtes auprès des citoyens, et au niveau national par l'analyse de divers rapports sur <span className="italic">Umushyikirano</span>  proposés par le cabinet du PMO.
						</p>
					</div>
				</div>
				<Link
					to="/hgs/umushyikirano/impact"
					className="flex flex-row items-center justify-end mt-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					L'impact d' <span className="italic">Umushyikirano</span>  &nbsp; <HiOutlineArrowNarrowRight />
				</Link>
				<Link
					to="/hgs/umushyikirano/overview"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; Aperçu
				</Link>
			</div>
		);
	}
}

export default UmushyikiranoHowItWorks;
