import React, { Component } from "react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { Link } from "react-router-dom";
import Infographic_17 from "../assets/images/hgs-infographics/Infographic_17.jpg";
import Designs_15 from "../assets/images/hgs-infographics/INFOGRAPHIC_fr-16.jpg";

export class UbudeheImpact extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">L'impact d'<span className="italic">Ubudehe</span></p>
				<div className="">
					<p className="my-4">
						<span className="italic">Ubudehe</span> a permis d'obtenir des résultats significatifs pour les ménages pauvres. Parmi ceux-ci, citons les suivants :
					</p>
					<div className="my-4">
						<ul className="ml-8 hgs-list">
							<li>Amélioration des revenus.</li>
							<li>Capacité améliorée de payer les dépenses santé, alimentaires, et les frais scolaires.</li>
							<li>Augmentation de la capacité de rénovation des maisons.</li>
							<li>Renforcement des liens intracommunautaires d'amitié et de solidarité.</li>
						</ul>
					</div>
					<p className="my-4">
						Les bénéfices tirés de <span className="italic">Ubudehe</span> au niveau communautaire sont les suivants :
					</p>
					<div className="my-4">
						<ul className="ml-8 hgs-list">
							<li>
								La promotion de l'élevage (avec comme effets induits une amélioration de l’alimentation et une croissance des revenus).
							</li>
							<li>
								La construction et la réparation de routes rurales et autres infrastructures.
							</li>
							<li>
								Collectivement, les ménages ont augmenté la demande économique au bénéfice des entreprises locales.
							</li>
							<li>
								L'amélioration du développement agricole grâce à l'augmentation des investissements des ménages dans les produits tels que les engrais.
							</li>
							<li>
								La construction d'infrastructures administratives telles que des immeubles de bureaux pour pallier la pénurie de ces espaces.
							</li>
						</ul>
					</div>
					<p className="my-4">
						Dans l'ensemble, <span className="italic">Ubudehe</span> a entraîné une forte augmentation de la participation des menages aux activités économiques, comme le montre la figure ci-dessous, ce qui a conduit à l'amélioration substantielle et durable du bien-être des bénéficiaires.
					</p>
					<div className="my-2">
						<p className="font-semibold">Participation à des activités productives avant et pendant <span className="italic">Ubudehe</span></p>
						<div className="">
							<img src={Infographic_17} alt="" className="w-full" /> 
						</div>
					</div>
					<p className="mb-4">
						L'impact le plus significatif de <span className="italic">Ubudehe</span> est la manière dont il a transformé l'engagement des citoyens dans leur propre développement. <span className="italic">Ubudehe</span> a presque atteint une couverture nationale et les communautés à travers le pays sont activement impliquées dans le développement de leurs propres cartographies sociales, notamment à travers des représentations visuelles et à travers la collecte de données sur l'étendue et la nature de la pauvreté dans leur village. Ces informations sont utilisées pour déterminer les objectifs de développement nationaux dont la responsabilité incombe aux ministères et autres départements.
					</p>
					<p className="my-4">
						Une autre réalisation majeure de <span className="italic">Ubudehe</span> est la manière dont le programme a réussi à rassembler les communautés pour une action collective basée sur leurs propres priorités.
					</p>
					<p className="my-4">
						Les transferts de fonds sur les comptes bancaires de chaque communauté a facilité des milliers d'initiatives communautaires telles que l'achat de bétail, la réalisation d'activités agricoles, la construction d'installations d'eau potable, de salles de classe, de terrasses, de centres de santé ainsi que de silos pour le stockage des récoltes.
					</p>
					<div>
						<img src={Designs_15} alt="" className="w-full" />
					</div>
					<p className="my-4">
						Le programme <span className="italic">Ubudehe</span> a été et continue d'être un facteur majeur dans la réduction globale de la pauvreté au Rwanda. En effet, l'extrême pauvreté est passée de 24% à 16% de 2010/2011 à 2016/2017. Le taux de pauvreté générale est passé de 45% en 2010/2011 à 38% en 2016/2017.
					</p>
					<p className="my-4">
						Cependant, <span className="italic">Ubudehe</span> a eu plus qu'un impact économique. Il a eu également une importance certaine pour l'accès à la santé, à l'éducation et au logement. Il a permis une amélioration de la nutrition, de la cohésion sociale et d'une prise en charge personnelle des communautés.
					</p>
				</div>
				<Link
					to="/hgs/ubudehe/organization"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; Organisation et coordination
				</Link>
			</div>
		);
	}
}

export default UbudeheImpact;
