import React, { Component } from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import ReactPlayer from "react-player/lazy";
import { Link } from "react-router-dom";
import abunziOverviewImage from "../assets/images/hgs-content-image043.jpeg";
import image044 from "../assets/images/hgs-content-image044.jpeg";

export class AbunziOverview extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Aperçu</p>
				<div className="flex flex-row items-start my-4">
					<div className="-mt-4 text-5xl text-secondary">&ldquo;</div>
					<div>
						<p className="text-2xl italic font-semibold text-center">
							Contrairement aux tribunaux ordinaires dont les décisions désignent un gagnant et un perdant qui, dans la plupart des cas, continuent à vivre en conflit, les <span className="italic">Abunzi</span> s'efforcent d'aider les parties à reprendre leurs anciennes relations normales ou du moins à atténuer l'ampleur de leur litige
							<strong className="text-secondary">&rdquo;</strong>
						</p>
					</div>
				</div>
				<p className="">
					Un participant à une discussion de groupe dans le district de Kicukiro.
				</p>
				<div className="w-full my-8">
					<ReactPlayer
						width="100%"
						height="30rem"
						controls={true}
						loop={false}
						muted={true}
						url="https://youtu.be/sHy-zvRLJhM"
					/>
				</div>
				<div className="">
					<p className="">
						Le système <span className="italic">Abunzi</span> combine des méthodes traditionnelles et modernes de résolution des conflits. C'est également l'une des initiatives endogènes du Rwanda (HGI) bien que des mécanismes similaires existent dans d’autres pays.
					</p>
					<p className="my-4">
						Le mot <span className="italic">Abunzi</span> signifie les « réconciliateurs » ou les « rassembleurs ». Traditionnellement, au Rwanda, les <span className="italic">Abunzi</span> étaient des personnes connues au sein de leur communauté pour leur intégrité personnelle. Elles étaient sollicitées pour intervenir en cas de conflit. Chaque partie en conflit choisissait une personne considérée comme digne de confiance, connue pour sa capacité à résoudre les problèmes d'une maniére impartiale. Le but de ce système était de régler les conflits, de réconcilier les parties et de rétablir l'harmonie au sein de la communauté concernée.
					</p>
					<p className="">
						La réintroduction du système <span className="italic">Abunzi</span> en 2004 a été motivée en partie par le désir de réduire les arriérés des cas et en partie par celui de décentraliser la justice. L'objectif était de rendre cette dernière plus abordable et accessible aux citoyens. Aujourd'hui, les <span className="italic">Abunzi</span> sont pleinement intégrés au système judiciaire rwandais.
					</p>
					<p className="my-4">
						Ce mécanisme de résolution des conflits, enraciné dans la culture, est perçu comme plus accessible et moins contraignant. Il prend alors un côté plus humain. Les personnes ayant soumis leur litige aux <span className="italic">Abunzi</span> en ont retiré plus de satisfaction car les <span className="italic">Abunzi</span> venaient de leur propre communauté et avaient dès lors une meilleure compréhension des problèmes et dynamiques locales. Etant donné que le système <span className="italic">Abunzi</span> a rapidement fait preuve de son efficacité à résoudre les conflits et à rendre la justice, la nécessité de structurer et de formaliser leur travail s'est accrue. En conséquence, les <span className="italic">Abunzi</span> recoivent une formation sur la médiation des conflits domestiques, ainsi qu'un soutien logistique de la part d'organisations gouvernementales et non gouvernementales, ceci afin de renforcer la qualité de leur travail.
					</p>
					<p className="my-4">
						Il y a, à l’heure actuelle, plus de 30 000 <span className="italic">Abunzi</span> qui opèrent à travers le Rwanda.
					</p>
				</div>
				<div className="">
					<img src={abunziOverviewImage} alt="" />
				</div>
				<p className="my-4">
					Selon le rapport annuel de 2019 du ministère de la Justice, au cours de l'année fiscale se terminant en juin 2019, les <span className="italic">Abunzi</span> ont reçu 48 989 affaires civiles, dont 47 898 (97,8 %) ont été clôturées (au niveau des secteurs et des cellules) et 1 091 (2,2 %) étaient encore en cours à la fin de l'exercice. Comme les <span className="italic">Abunzi</span> travaillent sur base de volontariat, c’est-à-dire sans rémunération, ils reçoivent en contrepartie un encouragement raisonnable. Le même rapport décrit l'encouragement accordé aux <span className="italic">Abunzi</span> au cours de l'année fiscale 2018-2019. Un total de 15 183 <span className="italic">Abunzi</span> et 56 799 personnes à leur charge ont bénéficié d'une assurance maladie communautaire complète. Un total de 13 030 <span className="italic">Abunzi</span> a bénéficié chaque mois d’unités pour communique pour communiquer avec leurs collègues et le personnel du Ministère de la Justice. Toujours durant l’année fiscale 2018-2019, un total de 13 100 <span className="italic">Abunzi</span> a reçu des bicyclettes pour faciliter leur travail au quotidien.
				</p>
				<p className="text-xl font-semibold">
					Résolution des conflits par la participation communautaire
				</p>
				<div className="">
					<p className="my-8">
						L'adoption de mécanismes alternatifs de résolution des conflits au Rwanda est née de la reconnaissance d'une crise croissante dans le système judiciaire. En effet, il devenait quasi impossible de résoudre les litiges de proximité de manière efficace et rentable à travers les tribunaux classiques. Il a donc été décidé d'examiner les approches traditionnelles de médiation et de conciliation comme alternatives. Cela permettait non seulement d'alléger la pression sur les tribunaux conventionnels, mais aussi de permettre la mise en place d'un système judiciaire plus décentralisé.
					</p>
					<div
						className="my-8"
					>
						<img src={image044} alt="" />
					</div>
					<p className="my-4 text-xl font-semibold">
						Création et finalité des <span className="italic">Abunzi</span>
					</p>
					<p className="my-4">
						En 2004, le gouvernement a officialisé le rôle des <span className="italic">Abunzi</span> comme mécanisme alternatif de résolution des conflits au niveau des circonscriptions administratives les plus en aval de l’échelle (les cellules et les secteurs). Les <span className="italic">Abunzi</span> traitent principalement les conflits familiaux, tels que ceux liés aux propriété foncières ou aux successions. En institutionnalisant <span className="italic">Abunzi</span>, les litiges au niveau des populations pouvaient être résolus au niveau local, sans qu'il soit nécessaire de les soumettre aux tribunaux classiques.
					</p>
					<p className="my-4">
						En devenant partie du système judiciaire dans son ensemble, le besoin de professionnaliser le travail des <span className="italic">Abunzi</span> s'est accru. Le récent partenariat entre le ministère de la Justice <span className="italic">Abunzi</span> et le Comité international de la Croix-Rouge offre aux <span className="italic">Abunzi</span> des formations sur les lois nationales, les techniques de médiation et de conciliation ainsi que le renforcement du sens de l'éthique pour améliorer la qualité et l'accessibilité à la justice.
					</p>
				</div>
				<Link
					to="/hgs/abunzi/how-it-works"
					className="flex flex-row items-center justify-end py-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					Comment fonctionne le système <span className="italic">Abunzi</span> ? &nbsp;{" "}
					<HiOutlineArrowNarrowRight />
				</Link>
			</div>
		);
	}
}

export default AbunziOverview;
