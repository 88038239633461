import React, { Component } from "react";
import Post2 from "../assets/images/Post-Genocid2.jpg";

export class History4 extends Component {
	render() {
		return (
			<div className="flex flex-col my-4">
				<div className="">
					<p>
						En juillet 1994, le FPR a mis en place un gouvernement d'unité nationale basé sur les accords de paix d'Arusha en excluant les partis impliqués dans le génocide. Après quatre ans de réhabilitation post-conflit, le gouvernement a organisé en 1998 les discussions du village Urugwiro ou « Urugwiro Village Discussions » pour préparer une transformation durable du pays. Ces discussions eurent lieu tous les samedis de mai 1998 à mars 1999. Elles rassemblèrent 164 participants de tous les partis politiques et de toutes les couches de la société, y compris des notables de l'époque de la monarchie et des deux républiques précédentes.
					</p>
					<p className="my-4">
						Deux grandes orientations politiques ont émané des discussions du village Urugwiro : renforcer l'unité nationale en tant qu'objectif ultime et vers lequel toutes les politiques devaient converger, et placer les citoyens au centre des stratégies de développement. Les principales institutions et programmes du nouveau Rwanda ont été conçus à cette époque : la Constitution de 2003 consacrant les principes de la démocratie consensuelle, la décentralisation, la Commission Nationale d'Unité et de Réconciliation, les tribunaux Gacaca et la stratégie de transformation économique Vision 2020.
					</p>
					<div className="">
						<img src={Post2} alt="" className="w-full" />
					</div>
					<p className="my-4 font-semibold">
						La structure politique et administrative du Rwanda
					</p>
					<p className="my-4">
						Après neuf ans de transition post-génocide, le pays a adopté en 2003 une nouvelle Constitution et d'autres institutions et programmes inspirés des discussions du village Urugwiro, visant alors à renforcer l'unité nationale, à établir un État inclusif et équitable et à transformer l'économie.
					</p>
				</div>
			</div>
		);
	}
}

export default History4;
