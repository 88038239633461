import React, { Component } from "react";
import HGSAnimatedContainer from "./HGSAnimatedContainer";
import PageName from "./PageName";
import image026 from "../assets/images/Image-Ubudehe1.JPG";
import image037 from "../assets/images/hgs-content-image037.jpeg";

export class WhatAre4 extends Component {
	render() {
		return (
			<div className="relative px-4 md:p-40">
				<div className="hidden md:flex">
					<PageName
						title="Que sont les HGI"
						color="primary"
						accentColor="secondary"
					/>
				</div>
				<div className="flex flex-col justify-center md:flex-row lg:gap-20 md:gap-8">
					<div className="lg:my-20 md:my-12">
						<HGSAnimatedContainer
							to="/hgs/ubudehe/overview"
							image={image026}
							title="Ubudehe"
							subtitle="PROTECTION SOCIALE"
							text={
								<p>
									Ubudehe fait référence à la pratique culturelle rwandaise de longue date 
									d'actions collectives et de soutien mutuel pour résoudre des 
									problèmes au sein d'une communauté. Aujourd'hui, Ubudehe est le programe phare 
									de protection sociale du Rwanda.
								</p>
							}
						/>
					</div>
					<div>
						<HGSAnimatedContainer
							to="/hgs/umuganda/overview"
							image={image037}
							title="Umuganda"
							subtitle="Économie"
							text={
								<p>
									Le mot Umuganda signifie «se rassembler avec pour
									objectif commun d’atteindre un résultat». Dans la
									culture rwandaise, les membres d'une communauté faisaient appel à leur
									famille, amis et voisins pour les aider à terminer une
									tâche difficile.
								</p>
							}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default WhatAre4;
