import React, { Component, Fragment } from 'react'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { Route, Switch } from 'react-router-dom';
import HGSSideNav from '../components/HGSSideNav';
import PageName from '../components/PageName';
import UmwihereroImprovement from '../components/UmwihereroImprovement';
import UmwihereroOverview from '../components/UmwihereroOverview';
import UmwihereroResolutions from '../components/UmwihereroResolutions';
import { umwihereroSubmenus } from '../utils/menus';

export class Umwiherero extends Component {
	render() {
		const { match } = this.props;
		return (
			<Fragment>
				<div className="flex flex-row h-screen w-full relative">
					<div className="hidden md:flex w-4/12">
						<HGSSideNav
							hgsTitle="Umwiherero"
							hgsSubTitle="GOUVERNANCE ET JUSTICE"
							hgsIntro={
								<p className="text-justify">
									Umwiherero, désigne une tradition de la culture rwandaise durant laquelle les dirigeants se retiraient dans un lieu isolé pour réfléchir aux problèmes rencontrés par leurs communautés, afin d'identifier des solutions. 
								</p>
							}
							submenus={umwihereroSubmenus}
						/>
					</div>	
					<div className="flex md:w-8/12 px-4 lg:px-10 xl:px-24 pt-40 overflow-y-scroll bg-white relative">
						<div>
							<PageName
								title="Que sont les HGI"
								color="primary"
								accentColor="secondary"
								subTitle={
									<>
										Umushyikirano &nbsp; <HiOutlineArrowNarrowRight />
									</>
								}
								to="/hgs/umushyikirano/overview"
							/>
						</div>
						<Switch>
							<Route
								exact
								path={`${match.path}`}
								component={UmwihereroOverview}
							/>
							<Route
								exact
								path={`${match.path}/overview`}
								component={UmwihereroOverview}
							/>
							<Route
								exact
								path={`${match.path}/improvement`}
								component={UmwihereroImprovement}
							/>
							<Route
								exact
								path={`${match.path}/resolutions`}
								component={UmwihereroResolutions}
							/>
						</Switch>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default Umwiherero
