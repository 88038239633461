import React, { Component } from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import image006 from "../assets/images/hgs-content-image006.jpeg";
import image002 from "../assets/images/hgs-content-image002.jpeg";
import participant from "../assets/images/hgs-infographics/INFOGRAPHIC_fr-15.jpg";

export class IngandoOverview extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Aperçu</p>
				{/* <div className="w-full my-4">
					<ReactPlayer
						width="100%"
						height="30rem"
						controls={true}
						loop={false}
						muted={true}
						url="https://www.youtube.com/watch?v=n6J6iiNDtws&ab_channel=TheGovernmentofRwanda"
					/>
				</div> */}
				<div className="">
					<p className="">
						Le mot <span className="italic">Ingando</span> vient du verbe kugandika. Il signifie « aller séjourner dans un endroit éloigné de chez soi, souvent en groupe, pour une raison précise ». Traditionnellement, l’<span className="italic">Ingando</span> était une retraite au cours de laquelle les anciens, les dirigeants ou les jeunes quittaient leur maison et séjournaient dans un endroit où ils méditaient et partageaient des idées sur la manière de résoudre les problèmes affectant leur communauté ou affectant la nation. Selon des sources historiques, l’<span className="italic">Ingando</span> était confiné au domaine militaire et désignait un camp militaire ou une zone de rassemblement où les troupes recevaient des briefings sur l’organisation et leur mission dans la préparation de la bataille. On rappelait à ces hommes l’impératif d’oublier leurs différends et de se concentrer sur l'objectif de protection de la nation. L’<span className="italic">Ingando</span> pouvait également être un lieu où s'arrêter et se reposer ou bien un lieu retiré au sein duquel des adeptes d’une religion pratiquaient leurs rites.
					</p>
					<p className="my-4">
						Le terme <span className="italic">Ingando</span> a depuis évolué pour décrire un endroit où un groupe de personnes se rassemble pour établir un objectif commun. Les formations <span className="italic">Ingando</span> ont servi de facto de groupes de réflexion dans lesquels le partage d'idées était encouragé. L’<span className="italic">Ingando</span> incluait également la pratique du travail communautaire nommé Umuganda. Ces formations ont créé un cadre pour la réévaluation des idéologies de division présentes au Rwanda pendant les périodes coloniale et postcoloniale. L’<span className="italic">Ingando</span> a été conçu pour offrir aux jeunes un espace leur permettant de préparer un avenir meilleur, dans lequel les idéologies négatives du passé ne les influenceraient plus.
					</p>
					<p className="my-4">
						L'un des objectifs initiaux de l'<span className="italic">Ingando</span> est de réduire la peur, la suspicion et d'encourager à la réconciliation entre les survivants du génocide et ceux dont les membres de la famille avaient participé au génocide. Les formations <span className="italic">Ingando</span> servaient également à réduire la distance entre certains groupes de la population rwandaise et les autorités du pays. Grâce à l’initiative <span className="italic">Ingando</span>, les participants apprenaient à connaître l'histoire ainsi que les politiques actuelles de développement et de réconciliation. Ils étaient encouragés à jouer un rôle proactif dans la reconstruction de leur nation.
					</p>
				</div>
				<div>
					<div className="my-4">
						<img src={image006} alt="" />
					</div>
					<p className="my-4 text-2xl font-semibold">
						Connexion à  <span className="italic text-secondary">Itorero</span>
					</p>
					<p className="my-4">
						Les activités et formations de l'Ingando avaient été offertes à divers groupes d'étudiants jusqu'en 2007, année durant laquelle une autre initiative endogène, l’Itorero (Programme d'éducation civique), a été mise en place. Des étudiants vivant au Rwanda et des étudiants de la diaspora ont alors commencé à participer à l’Itorero, qui se concentrait sur la réintroduction de valeurs culturelles perdues afin de renforcer les liens entre les communautés. Aujourd'hui, les groupes qui continuent à participer aux <span className="italic">Ingando</span> sont ceux qui suivent un processus de réintégration à la société, par exemple des anciens combattants démobilisés récemment rentrés chez eux, des démobilisés vivant dans le pays ainsi que des enfants de la rue.
					</p>
				</div>
				<p className="text-2xl font-semibold">Les objectifs d'<span className="italic">Ingando</span></p>
				<div className="">
					<p className="my-4">Les objectifs d'<span className="italic">Ingando</span> étaient les suivants :</p>
					<div className="px-4 my-6 bg-hgsBgLight">
						<ul className="py-4 ml-8 hgs-list">
							<li>
								<span className="font-semibold">Promouvoir</span> une plate-forme d'échange d'idées et d'expériences entre Rwandais ;
							</li>
							<li>
								<span className="font-semibold">Encourager</span> les rwandais à améliorer la gestion de leurs communautés ;
							</li>
							<li><span className="font-semibold">Encourager</span> l'autonomie au sein de la communauté ;</li>
							<li><span className="font-semibold">Encourager</span> la culture du volontariat chez les rwandais ;</li>
							<li>
								<span className="font-semibold">Conduire</span> chaque segment de la population vers la paix et la réconciliation ;
							</li>
							<li><span className="font-semibold">Promouvoir</span> la cohésion sociale par l'éducation civique ;</li>
							<li>
								<span className="font-semibold">Aider</span> à construire des abris pour les survivants du génocide et autres citoyens vulnérables.
							</li>
						</ul>
					</div>

					<div className="my-6">
						<div className="my-4">
							<img src={image002} alt="" />
						</div>
						<p className="my-4">
							Les formations <span className="italic">Ingando</span> avaient également un aspect socio-économique puisqu'elles comprenaient des activités de service communautaire.
						</p>
						{/* this needs a look */}
						<p className="my-4">
							Les participants portaient des uniformes militaires pour être plus à l'aise avec l’institution militaire. Ils faisaient également l'expérience de la vie en plein air en dehors de leur zone de confort, ce qui les poussaient à s’adapter physiquement et mentalement à des environnements difficiles.
						</p>
						<p className="my-4">
							La formation visait à changer les perceptions négatives sur différents aspects de la structure politique du pays et à réduire la distance que certaines personnes percevaient entre elles, entre le Gouvernement et ses politiques. La formation <span className="italic">Ingando</span> visait également à apprendre aux participants à faire face à certains défis et les surmonter, développant ainsi leur compétence de résilience.
						</p>
						<p className="my-4">
							Les lauréats des formations de l’<span className="italic">Ingando</span> ont acquis de nouvelles compétences pour les aider à se stabiliser financièrement et à s'organiser en coopératives. Dans le cas des étudiants, ceux qui ont participé aux <span className="italic">Ingando</span> ont développé leurs aptitudes à résoudre les conflits et à combattre les idéologies du génocide dans leurs milieux scolaires. Les formations dispensées auprès des auteurs du génocide ont également été utiles lors des procès Gacaca. L’<span className="italic">Ingando</span> les a incités à s’ouvrir et à parler de leur rôle pendant le génocide et à confesser leurs crimes. Leur aveux ont contribué à la création d'un environnement permettant aux auteurs du génocide de réintégrer leurs villages et d'effectuer des activités de service communautaire pour aider à reconstruire la vie des victimes.
						</p>
					</div>
					<div>
						<p className="my-6 text-2xl font-semibold">
							Participants à l'<span className="italic"><span className="italic">Ingando</span></span>
						</p>
						<p>
							Entre 1999 et 2010, avant que l'<span className="italic">Ingando</span> pour les civils ne soit repris à travers le programme Itorero, plus de 90 000 personnes ont participé aux formations. Celles-ci ont été organisées par la Commission Nationale pour l'Unité et la Réconciliation.
						</p>
						<div>
							<img src={participant} alt="" style={{ width: "100%" }} />
						</div>
					</div>
				</div>
				<Link
					to="/hgs/ingando/impact"
					className="flex flex-row items-center justify-end py-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					L'impact d'<span className="italic">Ingando</span>  &nbsp; <HiOutlineArrowNarrowRight />
				</Link>
			</div>
		);
	}
}

export default IngandoOverview;
