import React, { Component } from "react";
import info1 from "../assets/images/cp-infographics/cp-infographics025.jpg";
import info2 from "../assets/images/cp-infographics/cp-infographics024.png";
import info3 from "../assets/images/cp-infographics/cp-infographics011.png";
import image006 from "../assets/images/cp-content-image002.jpeg";

export class Economy extends Component {
	render() {
		return (
			<div className="flex flex-col">
				<div
					className="w-full my-4 bg-center bg-no-repeat bg-cover"
					style={{
						backgroundImage: `url(${image006})`,
						height: "25rem",
					}}
				></div>
				<div className="">
					<p className="my-4">
						La phase de développement post-génocide du pays a réellement commencé avec le lancement par le président Kagame de la stratégie Vision 2020 en juillet 2000. Ce document est le fruit des discussions du village Urugwiro : il a d'abord été rédigé par des experts et a ensuite été soumis à l’appréciation d’un large public. La Vision 2020 est devenue le cadre stratégique de la trajectoire générale de développement du pays. D'autres stratégies à moyen terme ont ensuite été considérées comme des étapes de mise en œuvre.
					</p>
					<p className="my-4">
						La Vision 2020 visait à faire du Rwanda un pays à revenu intermédiaire en l'an 2020 au plus tard et à transformer son économie, alors basée sur l'agriculture de subsistance, en une économie de la connaissance. Elle a donc fixé un certain nombre d'objectifs socio-économiques à atteindre d'ici 2020. Après 2000, trois documents de stratégie économique à portée intermédiaire ont été adoptés et mis en œuvre : le Document Stratégique de Réduction de la Pauvreté (PSRP) adopté en 2002, la Stratégie de Développement Economique et de Réduction de la Pauvreté 1 (EDPRS 1) adoptée en 2008, et la Stratégie de Développement Economique et de Réduction de la Pauvreté 2 (EDPRS 2) mise en œuvre de 2013 à 2018. En 2007, les Objectifs du Millénaire pour le Développement (OMD) ont été ajoutés à ce cadre stratégique. La Vision 2020 avait en outre pour but de faire en sorte que l'économie soit davantage entrainée par le secteur privé et à faire de ce dernier le catalyseur de la transformation économique du pays. Les deux politiques EDPRS ont encouragé le pays à faire du secteur privé le moteur de la croissance économique et à garantir qu'il constitue la "part dominante des investissements".
					</p>
					<p className="my-4">
						En 2012, certains objectifs de la Vision 2020 ont été revus à la hausse en raison de progrès survenus plus rapidement que prévu.
					</p>
					<p className="my-4">
						Une deuxième génération de stratégies et de politiques de transformation a débuté en 2017, notamment la <span className="font-bold">Vision 2050,</span> qui vise à faire du Rwanda un pays à revenu intermédiaire, tranche supérieure, d'ici 2035 et un pays à revenu élevé d'ici 2050.
					</p>
					<p className="my-4">
						Cette aspiration sera concrétisée par une série de Stratégies Nationales de Transformation d’une durée fixe de sept années. Elles sont étayées par des stratégies sectorielles détaillées qui visent aussi à atteindre les objectifs de développement durable. L'actuelle <span className="font-bold"> Stratégie Nationale de Transformation 1 (NST1) </span> est un pont entre la Vision 2020 et la Vision 2050. Elle devrait déterminer les bases de décennies de croissance et de transformation durables qui accéléreront la progression vers l'atteinte d'un niveau de vie élevé pour tous les rwandais. La NST1 devrait reprendre là où la stratégie de développement économique et de réduction de la pauvreté (EDPRS 2) s'est arrêtée. Elle devrait donc pérenniser l’accélération de la transformation et de la croissance économique, menée par le secteur privé. La NST1 a été l'instrument principal de la mise en œuvre des trois dernières années de la Vision 2020 et jouera encore ce rôle pour les quatre premières années de la Vision 2050.
					</p>
					<div className="flex flex-row flex-wrap gap-4">
						<div
							className="info-border "
							style={{ height: "6rem", width: "fit-content" }}
						>
							<img src={info2} alt="" className="h-full" />
						</div>
						<div
							className="info-border "
							style={{ height: "6rem", width: "fit-content" }}
						>
							<img src={info1} alt="" className="h-full" />
						</div>
						<div
							className="info-border "
							style={{ height: "6rem", width: "fit-content" }}
						>
							<img src={info3} alt="" className="h-full" />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Economy;
