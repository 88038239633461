import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
	HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import Overview2 from "../components/Overview2";
import Overview3 from "../components/Overview3";
import PageName from "../components/PageName";

export class CPOverview extends Component {
	render() {
		return (
			<div className="text-justify">
				<div className="-mx-4 lg:-mx-10 xl:-mx-24">
					<PageName
						title="Profil du pays"
						color="primary"
						accentColor="fifth"
						subTitle={
							<>
								Histoire &nbsp; <HiOutlineArrowNarrowRight />
							</>
						}
						to="/country/history"
					/>
					<p className="text-3xl font-semibold  px-4 lg:px-10 xl:px-24">Les faits</p>
					<div>
						<Overview2 />
					</div>
					<div>
						<Overview3 />
					</div>
					<div>
						<Link
							to="/country/history"
							className=" px-4 lg:px-10 xl:px-24 pt-12 flex flex-row items-center justify-end md:justify-start font-semibold hover:text-fifth text-primary"
						>
							Histoire &nbsp; <HiOutlineArrowNarrowRight />
						</Link>
						<Link
							to="/country/introduction"
							className=" px-4 lg:px-10 xl:px-24 mt-4 pb-12 flex flex-row items-center justify-end md:justify-start font-semibold text-xs hover:text-fifth text-primary"
						>
							<HiOutlineArrowNarrowLeft /> &nbsp; Aperçu
						</Link>
					</div>
				</div>
			</div>
		);
	}
}

export default CPOverview;
