import React, { Component, Fragment } from "react";
import ComponentCarousel from "../components/ComponentCarousel";
import WhatAre from "../components/WhatAre";
import PageBottomNav from "../components/PageBottomNav";
import WhatAre2 from "../components/WhatAre2";
import WhatAre3 from "../components/WhatAre3";
import WhatAre4 from "../components/WhatAre4";
import WhatAre5 from "../components/WhatAre5";
import WhatAre6 from "../components/WhatAre6";
import { hgsMenus } from "../utils/menus";

export class HGSWhatAre extends Component {
	render() {
		const components = [
			<WhatAre />,
			<WhatAre2 />,
			<WhatAre3 />,
			<WhatAre4 />,
			<WhatAre5 />,
			<WhatAre6 />,
		];
		return (
			<Fragment>
				<div className="flex-col hidden md:flex">
					<div>
						<ComponentCarousel components={components} />
						<PageBottomNav menus={hgsMenus} color="secondary" />
					</div>
				</div>
				<div className="flex flex-col text-justify bg-white md:hidden">
					<div>
						<WhatAre />
						<WhatAre2 />
						<WhatAre3 />
						<WhatAre4 />
						<WhatAre5 />
						<WhatAre6 />
					</div>
				</div>
			</Fragment>
		);
	}
}

export default HGSWhatAre;
