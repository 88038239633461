import React, { Component } from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import HGSAnimatedContainer from "./HGSAnimatedContainer";
import PageName from "./PageName";
import image027 from "../assets/images/hgs-content-image027.jpeg";
import image047 from "../assets/images/Signing-of-Imihigo.jpg";
import { Link } from "react-router-dom";

export class WhatAre5 extends Component {
	render() {
		return (
			<div className="relative px-4 md:p-40">
				<div className="hidden md:flex">
					<PageName
						title="Que sont les HGI"
						color="primary"
						accentColor="secondary"
						subTitle={
							<>
								comment sont-ils apparus? <HiOutlineArrowNarrowRight />
							</>
						}
						to="/hgs/how-did-they-emerge"
					/>
				</div>
				<div className="flex flex-col justify-center md:flex-row lg:gap-20 md:gap-8">
					<div className="lg:my-20 md:my-12">
						<HGSAnimatedContainer
							to="/hgs/umushyikirano/overview"
							image={image027}
							title="Umushyikirano"
							subtitle="Gouvernance et justice"
							text={
								<p>
									Le Conseil national de dialogue connu sous le nom d'Umushyikirano est l'une des
									initiatives endogènes du Rwanda. Le mot Umushyikirano
									signifie un espace où les participants peuvent
									échanger des idées, partager des expériences et se rendre mutuellement des comptes.
								</p>
							}
						/>
					</div>
					<div>
						<HGSAnimatedContainer
							to="/hgs/imihigo/overview"
							image={image047}
							title="Imihigo"
							subtitle="Économie"
							text={
								<p>
									Imihigo était une pratique culturelle pré-coloniale au Rwanda dans laquelle un 
									individu - le plus souvent des leaders et des chefs - se fixait des cibles ou des objectifs à atteindre dans une 
									période de temps spécifique. 
								</p>
							}
						/>
					</div>
				</div>
				<div className="flex md:hidden">
					<Link
						to="/hgs/how-did-they-emerge"
						className="flex flex-row items-center justify-end w-full py-12 font-semibold hover:text-secondary text-primary"
					>
						comment sont-ils apparus? &nbsp; <HiOutlineArrowNarrowRight />
					</Link>
				</div>
			</div>
		);
	}
}

export default WhatAre5;
