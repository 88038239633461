import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import image027 from "../assets/images/Umushyikirano3.jpg";
import Infographic_15 from "../assets/images/hgs-infographics/Infographic_15.jpg"
import Infographic_16 from "../assets/images/hgs-infographics/INFOGRAPHICS-02.jpg";
import Infographic_17 from "../assets/images/hgs-infographics/INFOGRAPHICS-03.jpg";
import Infographic_18 from "../assets/images/hgs-infographics/info-21.jpg";
import Infographic_13 from "../assets/images/hgs-infographics/Infographic_13.jpg";
import Infographic_10 from "../assets/images/hgs-infographics/Infographic_10.jpg";
import Infographic_12 from "../assets/images/hgs-infographics/Infographic_12.jpg";
import Infographic_14 from "../assets/images/hgs-infographics/Infographic_14.jpg";

export class UmushyikiranoImpact extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">L'impact d'<span className="italic">Umushyikirano</span></p>
				<div className="">
					<p className="my-4">
						Les principales réalisations d'<span className="italic">Umushyikirano</span>  sont la participation des Rwandais à la prise de décisions au niveau national, à l'accélération des programmes gouvernementaux et à la définition des priorités par les citoyens sur base des résolutions déposées chaque année. <span className="italic">Umushyikirano</span>  joue également un rôle important dans la création de liens entre les rwandais de la diaspora et leur pays d'origine.
					</p>
					<div className="my-6">
						<div className="my-4">
							<img src={image027} alt="" />
						</div>
					</div>
					<div className="my-4">
						<p className="my-6 text-xl font-semibold">
							L'impact des précédentes éditions d'<span className="italic">Umushyikirano</span>
						</p>
						<p className="my-4">
							<strong>Quinzième édition d’<span className="italic">Umushyikirano</span> </strong>
							<br />
							<span className="text-xs">(18 - 19 décembre 2017)</span>
						</p>
						<p className="my-4">
							Le 15e Conseil National du dialogue a eu lieu les 18 et 19 décembre 2017 au Centre de Convention de Kigali. Ont été discutées les stratégies nationales de transformation, d'éthique et du développement de la petite enfance et de la jeunesse. Le rapport de mise en œuvre, un an après le conseil de 2017, indiquait que 80 % des résolutions étaient en bonne voie de réalisation, 18,2 % étaient sous surveillance et 1,8 % étaient en retard par rapport à la planification initiale.
						</p>
					</div>
					<div className="my-6">
						<div className="flex justify-center my-4">
							<img src={Infographic_15} alt="" />
						</div>
					</div>
					<p className="my-4">
						Concernant le <span className="font-bold">thème de l'éducation</span>, le rapport portant sur les résolutions de 2017 indique qu'un total de 1 370 salles de classe ‘intelligentes’ (dotées d’équipements digitaux) ont été construites, 922 autres ont été étendues ou réhabilitées, et 1 344 toilettes ont été construites. Le rapport donne un chiffre de 62 616 enseignants formés au programme d’études basé sur les compétences en 2018 contre 54 718 en 2017, ainsi qu’un total de 4 417 mentors scolaires en 2018 contre 4 160 en 2017. 727 formateurs ont été formés afin d’améliorer les compétences des enseignants, contre un objectif de 500 en 2018, et que 713 étudiants ont été formés à l'enseignement à la petite enfance dans les écoles normales, contre un objectif de 600 pour la même année. Les enseignants des écoles primaires assuraient une double vacation de deux fois quatre heures par jour, mais le passage à une seule vacation pour les élèves de sixième année primaire a fait passer l’horaire scolaire de ces élèves de 6ème de quatre à six heures. Le rapport portant sur les résolutions de l’ <span className="italic">Umushyikirano</span>  de 2017 indique que le taux d'abandon scolaire est passé de 11 % en 2012 à 5,6 % en 2016 et que 55 533 élèves sont retournés à l'école en 2017. Enfin, toujours sur le thème de l'éducation, 20 programmes STIM (science, technologie, ingénierie et mathématiques) ont été accrédités en 2018, par rapport à un objectif initial de 30.
					</p>
					<div className="my-4">
						<img src={Infographic_16} alt="" className="w-2/3" />
					</div>
					<div className="flex justify-end my-8 mr-16">
						<img src={Infographic_17} alt="" className="w-2/3" />
					</div>
					<div className="my-4">
						<img src={Infographic_18} alt="" className="w-2/3" />
					</div>
					<p>
						Concernant le thème de <span className="font-bold">la santé</span> santé, 409 médecins ont reçu un soutien pour leurs études de spécialisation, 260 856 personnes ont été dépistées pour l'hépatite B et C et 145 303 citoyens ont reçu un traitement dans le cadre d’une campagne spéciale. Conformément à l’une des résolutions de 2017 portant sur la lutte contre la toxicomanie et le trafic illicite, la durée des peines pour ces crimes est passée de 2-5 ans à 7-25 ans. Dans ce même cadre, 4 123 délinquants ont été réhabilités et formés pour développer des compétences pratiques.
					</p>
					<p className="mt-2">
						Concernant la résolution sur le <span className="font-bold">programme pour la petite enfance, la nutrition et l'hygiène</span>, le rapport indique que 74 288 enfants et 13 111 femmes vulnérables ont reçu une alimentation enrichie de nutriments.
					</p>
					<div className="flex justify-center my-4 ml-16">
						<img src={Infographic_13} alt="" />
					</div>
					<p>
						Concernant la résolution visant à accroître  <span className="font-bold">l'accès à une électricité abordable</span>, le rapport a constaté que 138 290 ménages étaient connectés au réseau électrique en 2018, excédant alors l’objectif de 115 979 ménages. A l’inverse, 61 546 ménages étaient connectés hors du réseau électrique, pour un objectif plus élevé de l’ordre de 97 142 ménages. Deux cent dix-huit mégawatts de capacité de production d'électricité ont aussi été installés.
					</p>
					<div className="flex justify-center my-4 ml-16">
						<img src={Infographic_10} alt="" />
					</div>
					<p>
						Concernant la <span className="font-bold">promotion de la langue nationale (kinyarwanda)</span>,le rapport indique qu’un total de 18 écoles internationales ont introduit le kinyarwanda dans leur programme scolaire.
					</p>
					<div className="flex justify-center my-4 ml-16">
						<img src={Infographic_12} alt="" />
					</div>
					<p>
						Concernant le <span className="font-bold"> programme du service national Itorero</span>, le rapport indique que 55 270 Intore ont été formés et déployés dans le cadre du service national. Le rapport précise que 4 503 écoles et 10 526 ont maintenant des programmes Itorero. Il indique aussi que le programme Intore mu Biruhuko (camps de vacances) a été créé.
					</p>
					<div className="flex justify-center my-4 ml-16">
						<img src={Infographic_14} alt="" />
					</div>
					{/*<div className="px-8 py-4 my-6 bg-hgsBgLight">
						<p className="text-xl font-semibold">
							DISCUSSION TOPIC 1: National Strategy for Transformation
						</p>
						<p className="my-4">
							This strategy reinforces Rwandan’s choices over the last decade,
							builds on lessons learnt and anticipates future challenges. The
							plan will broaden and deepen the process of transformation by
							elevating the quality of life for Rwandans. This session will
							present a snapshot of where Rwanda is headed over the next several
							years. There will be in-depth discussions on shared goals and
							ambitions including reflection of programs such as universal
							access to quality healthcare, improved nutrition, education,
							employment, sustained security, modern housing and settlements.
						</p>
					</div>
					<div className="px-8 py-4 my-6 bg-hgsBgLight">
						<p className="text-xl font-semibold">
							DISCUSSION TOPIC 2: Values for prosperity
						</p>
						<p className="my-4">
							The National Strategy for Transformation hinges on values that
							promote economic and social progress, including self-reliance and
							self-determination, dignity, unity and Rwandan identity, community
							participation, transparency and openness, as well as innovation
							and national stability. Also needed is a common understanding of
							what is required to maximize early human development efforts as
							anchored in the Early Childhood Development Program. This includes
							values that promote issues such as hygiene and nutrition.
							Umushyikirano.
						</p>
						<p className="italic font-semibold text-secondary">
							#Umushyikirano2017
						</p>
					</div>
					<div className="px-8 py-4 my-6 bg-hgsBgLight">
						<p className="text-xl font-semibold">
							DISCUSSION TOPIC 3: Youth at the center of transformation
						</p>
						<p className="my-4">
							Young Rwandans between 16-30 years old represent 27% of the
							population while those between 0-15 years represent 42%. Rwanda’s
							youth are tomorrow’s entrepreneurs, active citizens and leaders.
							Equipping and engaging young people effectively to accelerate
							growth and create prosperity is key to achieving transformation.
						</p>
						<p className="my-4">
							The economic contribution of young Rwandans will depend on the
							skills they possess to compete in the labor market and participate
							in Rwanda’s transformational agenda. For this to happen, young
							people must be able to access quality education and employment
							opportunities.
						</p>
						<p className="my-4">
							Patriotism and active involvement in nation building, including
							political awareness and participation, are critical elements in
							nurturing the mindset and shared vision for transformation, and
							safeguarding the gains Rwanda has registered in recent years.
						</p>
						<p className="my-4">
							Young people will lead this panel discussion. Expected outcomes
							include; common understanding of young people’s skills and
							capacities to actively participate in leadership roles, awareness
							of interventions required to create jobs and drive youth
							employment.
						</p>
					</div>*/}
				</div>
				<Link
					to="/hgs/umushyikirano/how-it-works"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; Comment fonctionne Umushyikirano?
				</Link>
			</div>
		);
	}
}

export default UmushyikiranoImpact;
