import React, { Component } from "react";
import HGSAnimatedContainer from "./HGSAnimatedContainer";
import PageName from "./PageName";
import image008 from "../assets/images/hgs-content-image008.jpeg";
import image043 from "../assets/images/hgs-content-image043.jpeg";

export class WhatAre2 extends Component {
	render() {
		return (
			<div className="relative px-4 md:p-40">
				<div className="hidden md:flex">
					<PageName
						title="Que sont les HGI"
						color="primary"
						accentColor="secondary"
					/>
				</div>

				<div className="flex flex-col justify-center md:flex-row lg:gap-20 md:gap-8">
					<div className="lg:my-20 md:my-12">
						<HGSAnimatedContainer
							to="/hgs/abunzi/overview"
							image={image043}
							title="Abunzi"
							subtitle="Gouvernance et justice"
							text={
								<p>
									Les médiateurs communautaires, également connus sous le nom d'Abunzi, sont une forme hybride de justice combinant les méthodes traditionnelles et modernes de résolution de conflit. C’est aussi l’une des initiatives endogènes rwandaises, mais des mécanismes similaires existent ailleurs.
								</p>
							}
						/>
					</div>
					<div className="">
						<HGSAnimatedContainer
							to="/hgs/gacaca/overview"
							image={image008}
							title="Gacaca"
							subtitle="Gouvernance et justice"
							text={
								<p>
									La Gacaca contemporaine s'inspire du modèle 
									traditionnel en reproduisant un système de justice communautaire local
									dans le but de restaurer le tissu social.
									Les tribunaux Gacaca sont reconnus pour avoir jeté les bases de la paix,
									la réconciliation et l'unité au Rwanda. Les juridictions Gacaca
									ont officiellement completé leurs travaux en juin 2012.
								</p>
							}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default WhatAre2;
