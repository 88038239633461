import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
	HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { Link } from "react-router-dom";

export class UbudehePovertyLevels extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Niveaux de pauvreté <span className="italic">Ubudehe</span></p>
				<div className="">
					<p className="my-4">
						Le processus <span className="italic">Ubudehe</span> consiste essentiellement à classer tous les ménages dans l'un des niveaux appropriés du niveau de pauvreté. Toutefois, ces niveaux ont changé au fil du temps, comme décrit dans cette section.
					</p>
					<p className="my-4 text-xl font-semibold">
						<span className="italic">Ubudehe</span> jusqu'en 2015
					</p>
					<p className="my-4">
						De sa création en 2001 à sa situation en février 2015, <span className="italic">Ubudehe</span> a identifié les six niveaux suivantes de pauvreté:
					</p>
					<div className="p-4 bg-hgsBgLight">
						<ul className="ml-8 hgs-list">
							<li>
								<span className="italic font-semibold">Abatindi nyakujya</span>: cette catégorie de la population ne possède pas de propriété, vit de la mendicité et est entièrement dépendante des autres.
							</li>
							<li>
								<span className="italic font-semibold">Abatindi</span>: cette catégorie de la population a un logement médiocre, une mauvaise alimentation, dépend des autres et ne possède ni terre ni bétail.
							</li>
							<li>
								<span className="italic font-semibold">Abakene</span>: cette catégorie de la population est mal nourrie, possède un petit espace de terres, a une faible capacité de production et ne peut pas payer l'école secondaire à ses enfants.
							</li>
							<li>
								<span className="italic font-semibold">Abakene bifashije</span>: cette catégorie de la population possède des terres, du bétail et peut-être une bicyclette. Elle a une capacité de production moyenne. Elle peut payer l'école secondaire à ses enfants et a moins de difficultés à accéder aux soins de santé.
							</li>
							<li>
								<span className="italic font-semibold">Abakungu - jumba</span>: cette catégorie de la population possède un nombre important de terres, peut se permettre d’obtenir une alimentation équilibrée et vit dans des maisons décentes. Elle emploie d'autres personnes, possède du bétail et peut payer des études universitaires à ses enfants.
							</li>
							<li>
								<span className="italic font-semibold">Abakire</span>: cette catégorie de la population possède de moyens suffisants et peut obtenir des prêts bancaires, possède une maison au-dessus de la moyenne, une voiture, du bétail et des terres fertiles. Elle a accès à une nourriture suffisante et à un emploi permanent.
							</li>
						</ul>
					</div>
					<p className="my-4 text-xl font-semibold">
						Les niveaux <span className="italic">Ubudehe</span> de 2015 à 2020
					</p>
					<p className="my-4">
						En février 2015, le ministère de l'Administration Locale a lancé une nouvelle catégorisation de <span className="italic">Ubudehe</span>. La première raison motivant ce changement était que depuis la précédente classification, la situation économique de nombreux ménages s'était considérablement détériorée. La seconde raison était liée au fait que de nombreux ménages avaient refusé d'être correctement classés, compte tenu des noms péjoratifs qualifiant certains niveaux de pauvreté. Cela a faussé le processus de catégorisation.
					</p>
					<p className="my-4">
						De février 2015 à 2020, il y a eu quatre niveaux de pauvreté dans le programme <span className="italic">Ubudehe</span> :
					</p>
					<div className="p-4 bg-hgsBgLight">
						<ul className="ml-8 hgs-list">
							<li>
								<span className="font-semibold">Niveau 1: </span>
								Les citoyens très pauvres et vulnérables, sans abri et incapables de se nourrir sans assistance.
							</li>
							<li>
								<span className="font-semibold">Niveau 2: </span>
								Les citoyens qui louent leur logement ou possèdent une maison de standard très inférieur. Cependant, ils n'ont pas d'emploi rémunéré et ne peuvent se nourrir qu'une ou deux fois par jour.
							</li>
							<li>
								<span className="font-semibold">Niveau 3: </span>
								Les citoyens ayant un emploi rémunéré ou capables d'employer de la main-d'œuvre. Ce niveau comprend les petits agriculteurs qui ont dépassé le stade de l'agriculture de subsistance ou les propriétaires de petites et moyennes entreprises.
							</li>
							<li>
								<span className="font-semibold">Niveau 4: </span>
								Les citoyens classés dans ce niveau sont les dirigeants de grandes entreprises, les employés salariés à plein-temps dans des organisations, des industries ou des entreprises, les fonctionnaires, les propriétaires de magasins ou de marchés et les propriétaires de transports commerciaux ou de camions.
							</li>
						</ul>
					</div>
					<p className="my-4">
						Ces niveaux ont fait l'objet de constants recours et plaintes de la part de personnes voulant être placées dans des niveaux inférieurs. Certains d'entre eux ont recouru à la corruption et au népotisme pour atteindre leur objectif.
					</p>
					<p className="my-4">
						Les niveaux de 2015 ont fourni des cadres de mise en œuvre considérés comme médiocres par les gestionnaires des programmes sociaux. Ils ont favorisé une dépendance excessive envers l’État et une trop lente sortie des communautés de la pauvreté.
					</p>
					<p className="my-4 text-xl font-semibold">
						<span className="italic">Ubudehe</span> à partir de 2020
					</p>
					<p className="my-4">
						La nouvelle catégorisation de 2020 tente de reclasser plus équitablement les ménages en fonction de leur statut socio-économique. Beaucoup d’entre eux se sont plaints du fait que certains ménages ayant des niveaux de bien-être très différents soient classés dans le même niveau. Selon le même principe, des ménages se sont plaint des précédents niveaux 1 et 2 avaient presque le même niveau de vie.
					</p>
					<p className="my-4">
						L'approche <span className="italic">Ubudehe</span> 2020 met l'accent sur le principe de progression afin de favoriser une mobilité sociale ascendante. Ce nouveau modèle cherche à être efficient en termes de coûts, à répartir les ressources de manière équitable et à attribuer des rôles et des tâches dans toutes les niveaux. Les noms des niveaux ont également été modifiés, passant de chiffres à des lettres. Les niveaux de 2020 sont les suivantes :
					</p>
					<div className="p-4 bg-hgsBgLight">
						<ul className="ml-8 hgs-list">
							<li>
								<span className="font-semibold">A & B: </span>
								Ces niveaux de ménages ont des choix de vie variés et sont eux-mêmes suffisamment autonomes pour être capables de stimuler l'autonomisation de la communauté et aider d'autres ménages à sortir de la pauvreté. Les ménages des niveaux A et B ne bénéficient pas de régimes de protection sociale. Ils sont censés être des partenaires du développement communautaire grâce à leurs propres investissements et leurs compétences. Ces deux niveaux peuvent potentiellement créer des emplois par l'application de principes de la responsabilité sociale des entreprises.
							</li>
							<li>
								<span className="font-semibold">C & D: </span>
								Ces deux niveaux comprennent des ménages autonomes, qui peuvent bénéficier d'interventions de protection sociale et multisectorielles à condition de signer des contrats de performance (Imihigo). Ces derniers structurent un plan de sortie de cette assistance dans un délai de 2 ans. Ces niveaux auront pour objet principal le passage au niveau supérieur.
							</li>
							<li>
								<span className="font-semibold">E (niveau spécial): </span>
								Les personnes de ce niveau sont censées ne pas avoir étudié et ne seront pas obligées de signer des contrats de performance. Ces personnes peuvent être âgées, vulnérables ou ne pas avoir les compétences nécessaires pour occuper un emploi. Ce nouveau niveau bénéficie d'une protection sociale complète de l'État et a accès à toutes les facilités: du programme de protection social Vision 2020 Umurenge aux subventions pour les systèmes électriques domestiques à base d'énergie solaire, en passant par l'assurance maladie communautaire, les aliments nutritifs enrichis, l’initiative Girinka et d'autres programmes.
							</li>
						</ul>
					</div>
				</div>
				<Link
					to="/hgs/ubudehe/organization"
					className="flex flex-row items-center justify-end mt-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					Organisation et coordination &nbsp; <HiOutlineArrowNarrowRight />
				</Link>
				<Link
					to="/hgs/ubudehe/how-it-works"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; <p>Comment fonctionne <span className="italic">Ubudehe</span>?</p>
				</Link>
			</div>
		);
	}
}

export default UbudehePovertyLevels;
