import React, { Component } from "react";
//import info1 from "../assets/images/cp-infographics/cp-infographics002.png";
//import info2 from "../assets/images/cp-infographics/cp-infographics003.png";
//import info3 from "../assets/images/cp-infographics/cp-infographics004.png";
//import info4 from "../assets/images/cp-infographics/cp-infographics026.png";

export class Today2 extends Component {
	render() {
		return (
			<div className="flex flex-col">
				<div className="flex flex-col">
					<div className="my-4">
						<p>
							Un Forum des organisations politiques a également été créé pour faire émerger une concertation permanente entre les partis politiques.
						</p>
					</div>
					<div>
						<p className="mb-4">En 2020, les femmes représentaient :</p>
					</div>
					<div className="mb-4">
						<ul className="mx-8 overview-list">
							<li>
								52% des membres du cabinet.
							</li>
							<li>
								61% des membres de la Chambre des Députés.
							</li>
							<li>
								38% des membres du Sénat.
							</li>
						</ul>
						{/*<div
							className="pr-4 info-border"
							style={{ height: "6rem", width: "fit-content" }}
						>
							<img src={info1} alt="" className="h-full" />
						</div>
						<div
							className="pr-4 info-border"
							style={{ height: "6rem", width: "fit-content" }}
						>
							<img src={info2} alt="" className="h-full" />
						</div>
						<div
							className="pr-4 info-border"
							style={{ height: "6rem", width: "fit-content" }}
						>
							<img src={info3} alt="" className="h-full" />
						</div>*/}
					</div>
				</div>
				<div className="flex flex-col">
					<p className="text-xl font-semibold">Structure administrative</p>
					<p className="my-4">
						Le Rwanda est composé de deux principaux niveaux de gouvernement (central et local) et de six sous-entités administratives, dont celle de Kigali.
					</p>
					<p>
						Le gouvernement local comprend les niveaux suivants, du plus élevé au plus bas :
					</p>
					{/*<div className="my-4">
						<img src={info4} alt="" className="" />
					</div>*/}
					<div className="p-8 my-4 bg-yellow-100 border overview-list">
						<ul className="mx-8 overview-list">
							<li className>
								Quatre provinces et la ville de Kigali
							</li>
							<li>
								30 Districts
							</li>
							<li>
								416 Secteurs
							</li>
							<li>
								2 148 cellules
							</li>
							<li>
								14 837 Villages
							</li>
						</ul>
					</div>
					<p>
						Le gouvernement local joue un rôle clé dans les efforts de développement social du pays. Les services sociaux et administratifs sont fournis au niveau sectoriel. Ces entités supervisent les infrastructures de santé, d'éducation et de distribution d'eau. Elles peuvent recevoir des fonds d'investissement et gèrent environ 25 % du budget national.
					</p>
				</div>
			</div>
		);
	}
}

export default Today2;
