import React, { Component } from "react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { Link } from "react-router-dom";

export class AbunziPractices extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Pratiques bénéfiques</p>
				<div className="">
					<p className="my-8">
						L'enquête RGB a également demandé aux participants de souligner les attributs positifs du système <span className="italic">Abunzi</span>. Ce sont les suivants :
					</p>
					<ul className="ml-8 hgs-list">
						<li>
							<span className="font-semibold">Conseils préalables à l'audition :  </span>
							<p className="">
								Avant que les litges ne soient entendus, les <span className="italic">Abunzi</span> rappellent aux parties l'importance de la cohésion sociale et de la résolution des conflits par la médiation communautaire. Dans certains cas, à ce stade du litige, les deux parties choisissent de se retirer et de parvenir à un accord commun. Dans d'autres cas, les plaideurs sont plus enclins à accepter la décision de médiation plutôt que de faire appel, notamment suite aux conseils prodigués.
							</p>
						</li>
						<li>
							<span className="font-semibold">
								Rapprochement entre les parties et les <span className="italic">Abunzi</span> :
							</span>
							<p className="">
								Etant donné que les <span className="italic">Abunzi</span> sont membres de la même communauté que les parties en litige, ces dernières se sentent moins intimidées et plus à l'aise pour s'exprimer lors de ces séances, qu'elles soient publiques ou à huis clos.
							</p>
						</li>
						<li>
							<span className="font-semibold">
								L'intégrité des <span className="italic">Abunzi</span> :
							</span>
							<p className="">
								La majorité des participants ont déclaré que le fait que l'intégrité détermine la sélection des <span className="italic">Abunzi</span> donne confiance dans les comités de médiation et favorise un environnement dans lequel la justice prévaut.
							</p>
						</li>
						<li>
							<span className="font-semibold">
								La liberté des parties de choisir les <span className="italic">Abunzi</span> :
							</span>
							<p className="">
								Les participants ont souligné que la liberté de choisir les <span className="italic">Abunzi</span> contribue à assurer l'égalité de traitement pendant la médiation et à réduire la probabilité de corruption.
							</p>
						</li>
						<li>
							<span className="font-semibold">Une approche « gagnant-gagnant » :  </span>
							<p className="">
								Au cours de la médiation, les comités <span className="italic">Abunzi</span> évitent de désigner l'une ou l'autre des parties comme étant le gagnant ou le perdant car ces mots peuvent créer du ressentiment et exacerber le conflit. Or, l'objectif est de trouver une solution durable par la réconciliation.
							</p>
						</li>
					</ul>
				</div>
				<Link
					to="/hgs/abunzi/performance"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; <p> Evaluation de l'impact des <span className="italic">Abunzi</span></p>
				</Link>
			</div>
		);
	}
}

export default AbunziPractices;
