import React, { Component } from "react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { Link } from "react-router-dom";
import image010 from "../assets/images/Gacaca31.JPG";
import image008 from "../assets/images/Gacaca32.jpg";

export class GacacaAchievements extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Réalisations</p>
				<div className="">
					<p className="my-4 font-semibold">Statistiques</p>
					<p>Les cas jugés par <span className="italic">Gacaca</span> sont présentés dans les tableaux suivants. </p>
					<div className="w-full overflow-x-auto">
						<p className="my-4 font-semibold text-primary">
							Nombre de procès jugés par <span className="italic">Gacaca</span> selon la catégorie (la catégorie 1 a été 	jugée 	par des tribunaux ordinaires)
						</p>
						<table className="w-full text-xs text-left border border-collapse border-gray-400 hgs-table">
							<tr className="uppercase bg-hgsBgLight">
								<th>Catégorie</th>
								<th>Nombre de cas</th>
								<th colSpan="4" className="text-center">
									Coupables
								</th>
								<th>Acquittés</th>
								<th>%</th>
							</tr>
							<tr className="uppercase border border-gray-400 bg-hgsBgLight">
								<th></th>
								<th></th>
								<th>Total</th>
								<th>%</th>
								<th>Aveux</th>
								<th>%</th>
								<th></th>
								<th></th>
							</tr>
							<tr>
								<td>UN</td>
								<td>60,552</td>
								<td>53,426</td>
								<td>88.3%</td>
								<td>22,137</td>
								<td>41.4%</td>
								<td>7,126</td>
								<td>11.7%</td>
							</tr>
							<tr>
								<td>DEUX</td>
								<td>577,528</td>
								<td>361,590</td>
								<td>62.6%</td>
								<td>108,821</td>
								<td>30%</td>
								<td>215,938</td>
								<td>37.4%</td>
							</tr>
							<tr>
								<td>TROIS</td>
								<td>1,320,554</td>
								<td>1,266,632</td>
								<td>96%</td>
								<td>94,054</td>
								<td>7.4%</td>
								<td>54,002</td>
								<td>4%</td>
							</tr>
							<tr className="font-semibold border border-gray-400 text-secondary">
								<td>TOTAL</td>
								<td>1,958,634</td>
								<td>1,681,948</td>
								<td>86%</td>
								<td>225,012</td>
								<td>13.3%</td>
								<td>277,066</td>
								<td>14%</td>
							</tr>
						</table>
					</div>
					<div className="w-full overflow-x-auto">
						<p className="my-4 font-semibold text-primary">
							Nombre de procès jugés par les cours d'appel <span className="italic">Gacaca</span> (la catégorie 1 a été jugée par 	les tribunaux ordinaires)
						</p>
						<table className="w-full text-xs text-left border border-b-4 border-collapse hgs-table border-secondary">
							<tr className="uppercase bg-hgsBgLight">
								<th>Catégorie</th>
								<th>Nombre de cas</th>
								<th colSpan="4" className="text-center">
									Coupables
								</th>
								<th>Acquittés</th>
								<th>%</th>
							</tr>
							<tr className="uppercase border border-gray-400 bg-hgsBgLight">
								<th></th>
								<th></th>
								<th>Total</th>
								<th>%</th>
								<th>Aveux</th>
								<th>%</th>
								<th></th>
								<th></th>
							</tr>
							<tr>
								<td>UN</td>
								<td>19,177</td>
								<td>16,688</td>
								<td>87%</td>
								<td>6,731</td>
								<td>35%</td>
								<td>2,489</td>
								<td>13%</td>
							</tr>
							<tr>
								<td>DEUX</td>
								<td>134,394</td>
								<td>93,607</td>
								<td>80.6%</td>
								<td>26,019</td>
								<td>19%</td>
								<td>40,787</td>
								<td>30%</td>
							</tr>
							<tr>
								<td>TROIS</td>
								<td>25,170</td>
								<td>22,607</td>
								<td>90%</td>
								<td>562</td>
								<td>2.4%</td>
								<td>2,563</td>
								<td>10%</td>
							</tr>
							<tr className="font-semibold border border-gray-400 text-secondary">
								<td>TOTAL</td>
								<td>178,741</td>
								<td>132,902</td>
								<td>74%</td>
								<td>33,312</td>
								<td>25.8%</td>
								<td>45,839</td>
								<td>26%</td>
							</tr>
						</table>
						<p className="font-semibold text-right text-secondary">
							Source : Rapport administratif sur <span className="italic">Gacaca</span> du Service national des juridictions <span className="italic">Gacaca</span>.
						</p>
					</div>
					<p className="my-4">
						Les procès jugés par les cours d'appel sont au nombre de 178 741, soit 9 % des 1 958 634 affaires jugées par les tribunaux <span className="italic">Gacaca</span>.
					</p>
					<p className="text-xl font-semibold">Impact social</p>
					<p className="my-4">
						Les tribunaux <span className="italic">Gacaca</span> ont officiellement corturé leur travail le 18 juin 2012. A cette date, un total de 1 958 634 affaires liées au génocide avait été jugé dans tout le pays.
					</p>
					<div className="my-4">
						<img src={image010} alt="" className="w-full"/>
					</div>
					<p>
						Le Centre pour la gestion des conflits de l'Université nationale du Rwanda a réalisé une étude pour déterminer dans quelle mesure les rwandais estiment que la procédure <span className="italic">Gacaca</span> avait atteint ses cinq objectifs. Voici les résultats pour chacun des cinq objectifs (le pourcentage moyen étant de 88%) :
					</p>
					<div className="p-8 my-4 bg-hgsBgLight">
						<ol className="ml-4 list-decimal hgs-ordered-list">
							<li>
								Découvrir et divulguer la vérité sur le génocide : 84%.
							</li>
							<li>Accélérer les procès pour génocide : 87%.</li>
							<li>Mettre fin à la culture de l'impunité : 87%.</li>
							<li>Renforcer l'unité et la réconciliation : 87%.</li>
							<li>
								Démontrer la capacité des rwandais à résoudre leurs propres problèmes : 95%.
							</li>
						</ol>
					</div>
					<p className="my-4">
						Alors que beaucoup s'attendaient à ce que le traitement des affaires par les tribunaux prenne plus de temps, la procédure <span className="italic">Gacaca</span> a défié cette incrécudlité et a joué un rôle clé en rendant une justice relativement rapide à de nombreuses victimes du génocide.
					</p>
					<p className="my-4">
						La perception de la nature des relations entre les citoyens, y compris le niveau de cohésion sociale, a été fortement influencée par les tribunaux <span className="italic">Gacaca</span> qui venaient de cloturer leurs travaux. Trois ans après la fin officielle de la phase <span className="italic">Gacaca</span>, le sentiment général était qu'une étape importante avait été franchie, diminuant le poids de la mémoire du génocide au sein des communautés.
					</p>
					<p className="my-4">
						Le processus <span className="italic">Gacaca</span> a "brisé la glace" dans les communautés rwandaises, rendant possible une discussion en profondeur sur ce qui s'était passé pendant le génocide. Pour les survivants, le bénéfice le plus tangible des <span className="italic">Gacaca</span> a été que beaucoup d'entre eux ont enfin pu retrouver les restes des corps de leurs proches, les inhumer dignement et commencer ainsi leur deuil. La procédure <span className="italic">Gacaca</span> a offert une reconnaissance publique de la douleur des survivants les plus isolés. La procédure d'aveux et de demande de pardon par les bourreaux, même s'il était imparfait, a créé la base sur laquelle une interaction sociale plus saine était finalement devenue possible. Citons un survivant du génocide :
					</p>
					<div className="px-24 py-8 text-center bg-hgsBgLight">
						<p className="my-4 italic ">
							“Avant <span className="italic">Gacaca</span>, il n'y avait guère de confiance entre les gens. En fait, il n'y en avait pas du tout. Les réfugiés rapatriés ne voulaient pas dire la vérité sur la façon dont les gens avaient été tués, sur ceux qui les avaient tués et sur l'endroit où se trouvaient les corps. Depuis <span className="italic">Gacaca</span>, j'ai le sentiment que les gens se sont rapprochés au fur et à mesure que la vérité a été révélée. Même si certains ont caché la vérité, ceux qui étaient en prison ont dit la vérité. Ce sont les prisonniers qui ont dit la vérité sur la façon dont les gens avaient été tués. C'est ainsi que nous avons progressé : grâce aux prisonniers qui ont avoué après avoir compris qu'ils devaient dire la vérité. Nous avons appris, compris et finalement accepté.”
						</p>
					</div>
					<div className="my-4">
						<img src={image008} alt="" className="w-full"/>
					</div>
					<p className="my-4">
						Les sondages de World Values Survey qui avaient été menés à mi-parcours et à la fin du processus <span className="italic">Gacaca</span> suggèrent un renforcement de la cohésion sociale, principalement au vue des réponses à la question « Pouvez-vous faire confiance à la plupart des gens ? ». Dans l'enquête de 2007, les rwandais ont exprimé l'un des niveaux de confiance sociale les plus bas du monde avec seulement 5 % de réponses positives. En 2012, le Rwanda a considérablement amélioré son score, avec un niveau de confiance environnant les 17%, l'un des plus élevés de l'Afrique subsaharienne.
					</p>
				</div>
				<Link
					to="/hgs/gacaca/how-did-it-work"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; <p>comment fonctionnaient les tribunaux <span className="italic">Gacaca</span></p>
				</Link>
			</div>
		);
	}
}

export default GacacaAchievements;
