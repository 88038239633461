import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export class PageBottomNav extends Component {
	render() {
        const {menus, color} = this.props
		return (
			<div className="hidden md:flex absolute bottom-0 right-0">
				<ul className="list-none flex flex-row text-sm bg-primaryLight">
					{menus.map(({ id, route, name }) => (
						<NavLink
							key={id}
							to={route}
							className={`px-2 py-2 font-semibold hover:text-${color} text-gray-600`}
							activeClassName={`text-gray-900 bg-primaryLight border-b-4 border-${color}`}
						>
							<li>
								{name}
							</li>
						</NavLink>
					))}
				</ul>
			</div>
		);
	}
}

export default PageBottomNav;
