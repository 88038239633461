import React, { Component } from "react";
import History1 from "../assets/images/History1.jpg";

export class History extends Component {
	render() {
		return (
			<div className="flex flex-col my-4">
				<div className="flex flex-col my-4">
					<div className="font-bold">
						<p>
							<span className="text-4xl text-fifth"> 1. </span>
							Avant le colonialisme
						</p>
					</div>
					<div className="">
						<p>
							Pendant plus de deux mille ans de cohabitation, la population qui vivait sur le territoire rwandais actuel avait développé des communautés culturelles fortes basées sur la complémentarité de la production alimentaire, de l'échange et de l'intermariage. Leurs communautés étaient étroitement liées par un système de clans.
						</p>
						<p className="my-4">
							C'est dans ce paysage culturel et social que, partant d'une petite principauté située à l'est de la capitale Kigali il y a quatre siècles, le Rwanda s'est progressivement développé pour former un État centralisé qui a atteint son apogée à la veille de la période coloniale vers la fin du XIXème siècle.
						</p>
						<p>
							Cependant, au tournant du XIXe siècle, malgré ces convivialités sociales, le défrichement accru des terres associé à la croissance de la population et du bétail, a provoqué un durcissement des relations sociales autour de l'accès à ces dites terres, qui fut ensuite exploité par les colonisateurs.
						</p>
					</div>
				</div>
				<div className="">
					<img src={History1} alt="" className="w-full"/>
				</div>
			</div>
		);
	}
}

export default History;
