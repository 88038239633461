import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
	HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import image038 from "../assets/images/Itorero2.jpg";
import trained from "../assets/images/hgs-infographics/INFOGRAPHIC_fr-14.jpg"

export class ItoreroHowItWorks extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">
					<span className="italic">Itorero</span> ry'Igihugu - <span className="italic">Itorero</span> contemporain
				</p>
				<div className="">
					<div className="my-4">
						<img src={image038} alt="" />
					</div>
					<p className="my-4">
						La réintroduction de l'<span className="italic">Itorero</span> en 2009 visait à promouvoir un programme d'éducation civique basé sur les valeurs patriotiques, ceci afin de renforcer la contribution des citoyens rwandais au développement de leur pays et au bien-être de leur société.
					</p>
					<p className="my-4">
						Une commission nationale <span className="italic">Itorero</span> (National Iterero commission - NIC), <span className="italic">Itorero</span> ry'Igihugu en Kinyarwanda, a été créée pour piloter le programme. elle est une commission indépendante chargée de la planification, de la mise en œuvre, de la coordination, du suivi et de l'évaluation des programmes <span className="italic">Itorero</span> à tous les niveaux de la société.
					</p>
					<p className="my-4 text-xl font-semibold">
						La vision de la Commission nationale <span className="italic">Itorero</span>
					</p>
					<p className="my-4">
						La vision de la NIC est d'aider à développer une société avec un état d'esprit et des valeurs communes, et ce afin de favoriser l'unité et le patriotisme, la conscientisation des objectifs du pays et la manière dont ils peuvent être atteints.
					</p>
					<p className="my-4">Les principaux objectifs de la NIC sont les suivants :</p>
					<ul className="ml-8 hgs-list">
						<li>
							Former les Rwandais à :
							<ul className="ml-8 hgs-list">
								<li>mieux comprendre leurs valeurs communes ;</li>
								<li>
									être de bons citoyens actifs et patriotes, qui contribuent activement au développement national ;
								</li>
								<li>
									être des Intore dignes de ce nom, à tous les niveaux de la société et à être des catalyseurs pour un changement positif ;
								</li>
								<li>
									embrasser une culture du volontariat par le biais du service national.
								</li>
							</ul>
						</li>
						<li>
							Promouvoir le respect des valeurs culturelles positives comme socle de la coexistence et du développement national.
						</li>
						<li>
							Collaborer avec d'autres institutions publiques, privées ou bien avec des organisations non gouvernementales ayant des objectifs similaires.
						</li>
					</ul>
					<p className="my-4 text-xl font-semibold">
						Les valeurs d'<span className="italic"><span className="italic">Itorero</span></span> (Indangagaciro)
					</p>
					<p className="my-4">
						Au cœur du programme <span className="italic">Itorero</span> se trouve un ensemble de valeurs, définies par un cadre éthique. L’<span className="italic">Itorero</span> contemporain promeut les valeurs suivantes :
					</p>
					<ul className="ml-8 hgs-list">
						<li>L’esprit d’unité</li>
						<li>Le patriotisme</li>
						<li>L’altruisme</li>
						<li>L’intégrité</li>
						<li>L’esprit de responsabilité</li>
						<li>La culture du volontariat</li>
						<li>L’humilité</li>
					</ul>
					<p className="my-4 text-xl font-semibold">
						Les sessions de formation <span className="italic">Itorero</span>
					</p>
					<p className="my-4">
						L'activité principale de l’<span className="italic">Itorero</span> consiste en une série de sessions de formation qui comprennent des leçons sur l'histoire et la culture du Rwanda ainsi que des activités physiques. Ces sessions visent à éduquer les Rwandais sur les valeurs culturelles positives, perdues pendant la colonisation. Au cours des sessions <span className="italic">Itorero</span>, les formations sont adaptées aux besoins spécifiques des participants. Par exemple, les enseignants et les professionnel de la santé se focalisent sur des activités liées à leur profession tandis que les dirigeants locaux sont formés à la prestation de services publics et à la bonne gouvernance.
					</p>
					<p className="my-4">
						Au cours des sessions de formation <span className="italic">Itorero</span>, les présentations et les discussions ont les objectifs suivants :
					</p>
					<ul className="ml-8 hgs-list">
						<li>
							<span className="font-semibold">Doter</span> les participants de la capacité à analyser leurs propres problèmes et à trouver des solutions.
						</li>
						<li>
							<span className="font-semibold">Promouvoir</span> le kinyarwanda ainsi que l'esprit rwandais et les valeurs culturelles rwandaises. L’objectif sous-jacent étant de favoriser de meilleures relations sociales et de mener une vie sociale satisfaisante.
						</li>
						<li>
							<span className="font-semibold">Encadrer</span> les participants afin qu'ils développent un esprit de coopération et d’ouverture à l’innovation ainsi qu’à une culture de la performance.
						</li>
						<li>
							<span className="font-semibold">Informer</span> les participants sur les politiques et les programmes les plus importants du pays.
						</li>
						<li>
							<span className="font-semibold">Sensibiliser</span> les participants à l'importance d'un certain nombre de pratiques utiles telles que l’exercice physique, la propreté à la maison, la protection de l'environnement, la culture du débat, les mécanismes de résolution des conflits familiaux, le respect de la loi et la nécessité de lutter contre la corruption et les comportements agressifs.
						</li>
						<li>
							<span className="font-semibold">Sensibiliser</span> les participants à l'importance de promouvoir une culture de la paix fondée sur le respect mutuel, la confiance, l'humilité, les valeurs démocratiques, les droits de l'homme, la lutte contre les comportements discriminatoires et l'idéologie du génocide.
						</li>
						<li>
							<span className="font-semibold">Sensibiliser</span> les participants à l'importance de l'efficacité dans la prestation de services et à la capacité de fournir un travail de qualité et efficace.
						</li>
					</ul>
					<p className="my-4 text-xl font-semibold">Participants</p>
					<p className="my-4">
						L’<span className="italic">Itorero</span> est conçu pour tous les Rwandais. Différents programmes d'études ont été élaborés pour répondre aux besoins des participants.
					</p>
					<p className="my-4">
						Les enfants de sept ans et plus participent à l’<span className="italic">Itorero</span> dans leurs Imidugudu (villages) pour les aider à grandir et à devenir des citoyens responsables. D'autres personnes désireuses de participer se voient offrir la possibilité de le faire en fonction de leur parcours professionnel.
					</p>
					<p className="my-4">
						Les jeunes citoyens rwandais de la diaspora de passage au Rwanda peuvent rejoindre l'Urugerero. Un certain nombre de jeunes rwandais ont organisé l'<span className="italic">Itorero</span> dans des villes comme Londres et Bruxelles.
					</p>
					<p className="my-4">
						Les non-nationaux qui souhaitent participer et fournir des services au pays peuvent également le faire. Les diplômés universitaires et les retraités qui ont participé au programme Urugerero par le passé et qui souhaitent le refaire ont également la possibilité de rejoindre le programme.
					</p>
					<p className="my-4 text-xl font-semibold">
						Service national (<span className="italic">Urugerero</span>)
					</p>
					<p className="my-4">
						Le Service national (<span className="italic">Urugerero</span>) est destiné aux jeunes de 18 à 30 ans ayant terminé leurs études secondaires dans le but de développer chez les jeunes rwandais le sens de la fraternité, de l'identité nationale, ce qui permet ainsi de renforcer la participation aux programmes nationaux. Bien que le contenu du programme de l'<span className="italic">Itorero</span> ry'Igihugu soit spécifique au Rwanda (car il s'appuie sur les principes et les valeurs de la culture rwandaise), l'Urugerero (Service national) partage de nombreux traits communs avec des systèmes de volontariat d'autres pays.
					</p>
					<p className="my-4">
						A travers <span className="italic">Urugerero</span>, des milliers de jeunes ont construit des logements pour les personnes vulnérables et créé des potagers pour les ménages pauvres. Ils ont aussi réparé des routes et des ponts, en particulier dans les zones rurales. Les districts ont commencé à intégrer l’Urugerero dans leur développement général et dans les services éco-systémiques.
					</p>
					<p className="my-4">
						Le programme <span className="italic">Urugerero</span> noue des relations avec des partenaires potentiels, notamment des institutions gouvernementales, des entreprises et des organisations non gouvernementales, ceci afin d'élargir la portée des activités offertes aux jeunes.
					</p>
					<p className="my-4 text-xl font-semibold">
						Les principales réalisations d'<span className="italic">Itorero</span>
					</p>
					<p className="my-4">
						De 2007 à 2017, plus de 1 700 000 personnes issues d'institutions publiques et privées ont été formées dans le cadre de l'<span className="italic">Itorero</span>, et ce dans tous les domaines, comme le montre la Figure ci-dessous.
					</p>
					<div>
						<div className="border-b-4 border-secondary">
							<img src={trained} alt="" style={{ width: "100%" }} />
						</div>
					</div>
					<p className="my-4">
						L’<span className="italic">Itorero</span> s’insère en complémentarité avec d'autres initiatives gouvernementales. Il contribue à renforcer les éléments intangibles qui font fonctionner ces programmes. Par exemple : le sentiment d'appartenance, le sens de la responsabilité civique et l'obligation de rendre des comptes.
					</p>
				</div>
				<Link
					to="/hgs/ingando/overview"
					className="flex flex-row items-center justify-end mt-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					Ingando &nbsp; <HiOutlineArrowNarrowRight />
				</Link>
				<Link
					to="/hgs/itorero/overview"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; Aperçu
				</Link>
			</div>
		);
	}
}

export default ItoreroHowItWorks;
