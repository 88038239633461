import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
	HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import infographics002 from "../assets/images/hgs-infographics/hgs-infographics002.png";
import Infographic_16 from "../assets/images/hgs-infographics/Infographic_16.jpg";
import infographics004 from "../assets/images/hgs-infographics/hgs-infographics004.jpg";

export class AbunziPerformance extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold text-left">
					Evaluation de l'impact des <span className="italic">Abunzi</span>
				</p>
				<div className="my-4">
					<p className="my-4">
						En 2018, l’Agence de gouvernance du Rwanda (RGB) a mené un sondage sur les perceptions du public à l'égard des <span className="italic">Abunzi</span>. Les répondants ont souligné les avantages suivants du recours aux <span className="italic">Abunzi</span>, en comparaison des tribunaux ordinaires :
					</p>
					<ul className="ml-8 hgs-list">
						<li>Une réduction du temps utilisé pour resoudre les litiges (86,7%)</li>
						<li>Une réduction du coût des procedures (84,2%)</li>
						<li>
							La capacité des <span className="italic">Abunzi</span> à resoudre les conflits entre les parties (80,1%)
						</li>
					</ul>
					<p className="my-4">
						Les chiffres de la figure ci-dessous reflètent le niveau de satisfaction globale des citoyens par rapport aux performances des comités <span className="italic">Abunzi</span>, montrant une augmentation d’environ 19% entre 2012 et 2018.
					</p>
					<p className="my-8 font-semibold text-primary">
						Niveau global de satisfaction des citoyens quant à la performance des <span className="italic">Abunzi</span>
					</p>
					<div className="my-4">
						<div className="w-full border-b-4 border-secondary" style={{}}>
							<img src={infographics002} alt="" className="w-full" />
						</div>
						<p className="font-semibold text-right text-secondary">
							Source: Rapport de l'Agence de gouvernance du Rwanda, 2018
						</p>
					</div>
					<div>
						<p className="my-4">
							Les chiffres de la figure ci-dessous montrent un niveau de satisfaction très élevé quant à l’impact du travail des <span className="italic">Abunzi</span>, en légère augmentation entre 2017 et 2018.
						</p>
					</div>
					<div className="my-4">
						<div className="w-full border-b-4 border-secondary" style={{}}>
							<img src={Infographic_16} alt="" className="w-full" />
						</div>
						<p className="font-semibold text-right text-secondary">
							Source : Perceptions des citoyens sur une série d'impacts des comités <span className="italic">Abunzi</span>
						</p>
					</div>


					<p>Un participant à l’une des discussions de groupe dans le district de Karongi a déclaré que :</p>
					<div className="flex flex-row items-start my-4">
						<div className="-mt-4 text-5xl text-secondary">&ldquo;</div>
						<div>
							<p className="text-center text-gray-700">
								Les litiges examinés par les <span className="italic">Abunzi</span> étaient auparavant portés devant les tribunaux ordinaires. Un litige pouvait durer deux ans ou plus et les gens s'appauvrissaient parce qu'ils se rendaient à Rusizi (le chef-lieu de la province de l’Ouest) en cas d'appel, pour y faire examiner leurs dossiers. Nous dépensions tout le peu d'argent que nous avions. Parfois, nous vendions notre bétail ou des parcelles de terre pour avoir de l'argent à dépenser dans les tribunaux. Nous n'avons pas de mots pour exprimer notre enthousiasme à propos des <span className="italic">Abunzi</span>. C'est une justice plus proche de nous et gratuite.
								<strong className="text-secondary">&rdquo;</strong>.
							</p>
						</div>
					</div>
					<div className="w-full" style={{}}>
						<img src={infographics004} alt="" className="w-full" />
					</div>
					<p className="my-4">
						Le coût par rapport aux tribunaux ordinaires est largement considéré comme un avantage clé du système <span className="italic">Abunzi</span>. Par exemple, le coût moyen de chaque litige réglé par les <span className="italic">Abunzi</span> était d’une moyenne de 20 700 FRW en février 2013. Du côté des tribunaux classiques, le coût par affaire était en moyenne de 280 738 FRW.
					</p>
				</div>
				<Link
					to="/hgs/abunzi/practices"
					className="flex flex-row items-center justify-end pt-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					Pratiques bénéfiques&nbsp; <HiOutlineArrowNarrowRight />
				</Link>
				<Link
					to="/hgs/abunzi/how-it-works"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; <p>Comment fonctionne le système <span className="italic">Abunzi</span>?</p>
				</Link>
			</div>
		);
	}
}

export default AbunziPerformance;
