import React, { Component, Fragment } from 'react'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { Route, Switch } from 'react-router-dom';
import GacacaAchievements from '../components/GacacaAchievements';
//import GacacaChallenges from '../components/GacacaChallenges';
import GacacaHowItWorked from '../components/GacacaHowItWorked';
import GacacaOverview from '../components/GacacaOverview';
import HGSSideNav from '../components/HGSSideNav';
import PageName from '../components/PageName';
import { gacacaSubmenus } from '../utils/menus';

export class Gacaca extends Component {
	render() {
		const { match } = this.props;
		return (
			<Fragment>
				<div className="flex flex-row h-screen w-full relative">
					<div className="hidden md:flex w-4/12">
						<HGSSideNav
							hgsTitle="Gacaca"
							hgsSubTitle="gouvernance et justice"
							hgsIntro={
								<p className="text-justify">
									La Gacaca contemporaine s'inspire du modèle traditionnel, en reproduisant un système de justice communautaire local dans le but de restaurer le tissu social de la société. Les tribunaux sont reconnus pour avoir jeté les bases de la paix, de la réconciliation et de l'unité au Rwanda. Les juridictions Gacaca ont officiellement terminé leurs travaux en juin 2012.
								</p>
							}
							submenus={gacacaSubmenus}
						/>
					</div>
					<div className="flex md:w-8/12 px-4 lg:px-10 xl:px-24 pt-40 overflow-y-scroll bg-white relative">
						<div>
							<PageName
								title="Que sont les HGI"
								color="primary"
								accentColor="secondary"
								subTitle={
									<>
										Itorero &nbsp; <HiOutlineArrowNarrowRight />
									</>
								}
								to="/hgs/itorero/overview"
							/>
						</div>
						<Switch>
							<Route exact path={`${match.path}`} component={GacacaOverview} />
							<Route
								exact
								path={`${match.path}/overview`}
								component={GacacaOverview}
							/>
							<Route
								exact
								path={`${match.path}/how-did-it-work`}
								component={GacacaHowItWorked}
							/>
							{/* <Route
								exact
								path={`${match.path}/challenges`}
								component={GacacaChallenges}
							/> */}
							<Route
								exact
								path={`${match.path}/achievements`}
								component={GacacaAchievements}
							/>
						</Switch>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default Gacaca
