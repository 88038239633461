import React, { Component } from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import image009 from "../assets/images/hgs-content-image009.jpeg";

export class UmushyikiranoOverview extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Aperçu</p>
				<div className="w-full my-4">
					<ReactPlayer
						width="100%"
						height="30rem"
						controls={true}
						loop={false}
						muted={true}
						url="https://youtu.be/ogHUdQdppd0"
					/>
				</div>
				<div className="my-6">
					<p className="my-4">
						Le mot  <span className="italic">Umushyikirano</span>  désigne « réunion au cours de laquelle les participants échangent des idées, partagent leurs expériences et se posent des questions ». Aujourd'hui, <span className="italic">Umushyikirano</span> est connu sous le nom de Conseil national du dialogue et est mandaté par l’intermédiaire de la constitution. <span className="italic">Umushyikirano</span>  est un forum dans lequel les participants débattent de questions relatives à l'état de la nation, de la gouvernance au niveau local et de l'unité nationale.
					</p>
					<p className="my-4">
						Chaque année, au sein de l'Assemblée nationale, le président de la République du Rwanda dirige <span className="italic">Umushyikirano</span> au cours duquel les Rwandais de tous milieux ont la possibilité de poser des questions directement à leurs dirigeants. Des membres du gouvernement et du Parlement, des représentants de la communauté rwandaise à l'étranger, des autorités locales, des médias, la communauté diplomatique assistent à cet événement. Ceux qui ne peuvent pas y participer en personne peuvent le faire par téléphone, par SMS, sur Twitter et Facebook. Ils ont également la possibilité de suivre le débat en direct grâce à la couverture télévisée et radiophonique de l'événement.
					</p>
					<div className="px-8 py-4 bg-hgsBgLight">
						<p className="my-4">
							Le premier Conseil national du dialogue a eu lieu le 28 juin 2003. Depuis lors, cet événement se tient chaque année. <span className="italic">Umushyikirano</span>  vise à renforcer la gouvernance participative et inclusive afin qu’en interagissant directement avec leurs dirigeants, les rwandais puissent se sentir partie prenante des décisions qui affectent leur vie. <span className="italic">Umushyikirano</span>  sert également de forum aux rwandais pour demander des comptes à leurs dirigeants et à leur gouvernement.
						</p>
					</div>
				</div>
				<div>
					<div className="">
						<img src={image009} alt="" />
					</div>
					<p className="my-4">
						Au cours des 15 dernières années, <span className="italic">Umushyikirano</span>  a permis de proposer des idées transformatrices et porteuses, contribuant ainsi à la reconstruction de la nation. Pour citer quelques exemples : Umwalimu SACCO (la coopérative d'épargne et de crédit pour les enseignants), Abunzi (les comités de médiation) et l’initiative Girinka (le programme "Une vache par famille pauvre").
					</p>
					<p>
						Le Bureau du Premier Ministre (PMO) est en charge de la coordination générale d'<span className="italic">Umushyikirano</span> .
					</p>
				</div>
				<Link
					to="/hgs/umushyikirano/how-it-works"
					className="flex flex-row items-center justify-end py-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					Comment fonctionne Umushyikirano? &nbsp; <HiOutlineArrowNarrowRight />
				</Link>
			</div>
		);
	}
}

export default UmushyikiranoOverview;
