import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
	HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import Economy from "../components/Economy";
import Economy2 from "../components/Economy2";
import Economy3 from "../components/Economy3";
import Economy4 from "../components/Economy4";
import Economy5 from "../components/Economy5";
import Economy6 from "../components/Economy6";
import PageName from "../components/PageName";

export class CPEconomy extends Component {
	render() {
		return (
			<div className="text-justify">
				<PageName
					title="Profil du pays"
					color="primary"
					accentColor="fifth"
					subTitle={
						<>
							Développement social &nbsp;
							<HiOutlineArrowNarrowRight />
						</>
					}
					to="/country/strategies"
				/>
				<p className="text-3xl font-semibold">Économie</p>
				<div>
					<Economy />
				</div>
				<div>
					<Economy2 />
				</div>
				<div>
					<Economy3 />
				</div>
				<div>
					<Economy4 />
				</div>
				<div>
					<Economy5 />
				</div>
				<div>
					<Economy6 />
				</div>
				<div>
					<Link
						to="/country/strategies"
						className="pt-12 flex flex-row items-center justify-end md:justify-start font-semibold hover:text-fifth text-primary"
					>
						Développement social &nbsp;
						<HiOutlineArrowNarrowRight />
					</Link>
					<Link
						to="/country/gender"
						className="mt-4 pb-12 flex flex-row items-center justify-end md:justify-start font-semibold text-xs hover:text-fifth text-primary"
					>
						<HiOutlineArrowNarrowLeft /> &nbsp; Égalité des genres et autonomisation des femmes
					</Link>
				</div>
			</div>
		);
	}
}

export default CPEconomy;
