import React, { Component } from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import ReactPlayer from "react-player/lazy";
import { Link } from "react-router-dom";
import image001 from "../assets/images/Iterore11.jpg";
import image005 from "../assets/images/Itorero1.jpg";

export class ItoreroOverview extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Aperçu</p>
				<div className="w-full my-4">
					<ReactPlayer
						width="100%"
						height="30rem"
						controls={true}
						loop={false}
						muted={true}
						url="https://youtu.be/qXdfdUSDYbM"
					/>
				</div>
				<p className="">
					Le programme d'éducation civique, connu sous le nom d'<span className="italic">Itorero</span>, est l'une des initiatives endogènes développées au Rwanda (HGI).
				</p>
				<p className="my-4">
					L’<span className="italic">Itorero</span> traditionnel était une école culturelle et de leadership dans laquelle les rwandais apprenaient la langue, le patriotisme, les relations sociales, le sport, la danse, la musique, les arts martiaux ainsi que les attributs du leadership. L’<span className="italic">Itorero</span> a été réintroduit en 2009. C'est l’un des mécanismes utilisés pour reconstruire le tissu social de la nation, mobiliser les rwandais autour de la promotion des valeurs culturelles structurantes. Il permet de susciter un sentiment de dévouement envers le pays. La culture d'un Intore (c'est-à-dire une personne ayant reçu les enseignements de l'<span className="italic">Itorero</span>) était traditionnellement très appréciée. Le programme <span className="italic">Itorero</span> moderne donne l'occasion aux participants de renforcer les valeurs positives, de développer un sens des responsabilités patriotiques, de promouvoir une culture du volontariat. Par le biais de ce programme et d'autres initiatives similaires, le Gouvernement du Rwanda a voulu réintroduire la culture du service au pays, sans récompense financière, pour soutenir le progrès, la promotion de la cohésion sociale, la cohabitation pacifique et l'intégrité publique.
				</p>
				<p className="my-4">
					<span className="text-semibold"><span className="italic">Itorero</span> ry'Igihugu</span> , la Commission nationale <span className="italic">Itorero</span>, est chargée de superviser la mise en œuvre du programme et de veiller à ce que tous les rwandais aient la possibilité d'y participer. De 2007 à juin 2017, cette commission avait formé plus de 1 700 000 Intore, issus de différents domaines d'activités. Citons par exemple les enseignants, les professionnel de la santé, les secrétaires exécutif, les agriculteurs ou encore les étudiants du Rwanda et de la diaspora rwandaises.
				</p>
				<div className="">
					<p className="my-4 text-2xl font-semibold">L’<span className="italic">Itorero</span> traditionnel</p>
					<p className="">
						L’<span className="italic">Itorero</span> traditionnel cherchait à former des leaders complets et distingués maitrisant les différents aspects de la vie sociale, culturelle, politique et militaire du pays. Les jeunes gens étaient confiés à l’<span className="italic">Itorero</span> depuis le début de l’adolescence jusqu’à l’âge adulte.
					</p>
					<p className="my-4">
						Les formateurs de l'<span className="italic">Itorero</span> planifiaient les activités quotidiennes en fonction des priorités. Chaque nouvel arrivant dans un <span className="italic">Itorero</span> devait suivre une initiation, connue en Kinyarwanda sous le nom de <span className="italic">"gukuramo ubunyamusozi"</span>. La croyance commune était que les gens qui avaient été formés au sein du programme <span className="italic">Itorero</span> se distinguaient du reste de la communauté, surtout en matière d'expression et de comportement. On attendait d'eux qu'ils aient de la répartie, qu’ils soient des experts en relations sociales et des personnes bien informées. Chaque <span className="italic">Itorero</span> comprenait 40 à 100 participants de tranches d'âge différentes et avait son propre nom. Les meilleurs parmis eux recevaient des vaches en recompense ou des terres en guise de récompense.
					</p>
					<p className="my-4">
						Les élèves des <span className="italic">Itorero</span> étaient formés aux tactiques militaires, au combat corps à corps, au saut, à la course, au lancer de javelot, au tir et à l'endurance. On leur enseignait également l’économie, les valeurs éthiques, les valeurs culturelles rwandaises, le leadership, l'éloquence, la chasse et la loyauté envers l'armée.
					</p>
				</div>
				<div className="grid gap-8 my-6 md:grid-cols-2">
					<div
						className="bg-center bg-no-repeat bg-cover"
						style={{
							height: "20rem",
							backgroundImage: `url(${image001})`,
						}}
					></div>
					<div
						className="bg-center bg-no-repeat bg-cover"
						style={{
							height: "20rem",
							backgroundImage: `url(${image005})`,
						}}
					></div>
				</div>
				<div>
					<p className="my-4">
						Traditionnellement, l’<span className="italic">Itorero</span> se retrouvait à deux niveaux de l’administration traditionnelle : à la cour du chef de la région et à la cour du roi. La formation à l’<span className="italic">Itorero</span> était précédée par une formation au sein de la famille. Au niveau de la famille, les filles et les garçons recevaient une éducation sur la manière d'assumer leurs responsabilités telles que définies par les attentes de leurs communautés respectives. Par exemple, l'homme était censé protéger le pays et sa famille tandis que la femme devait assurer un bon foyer et un bon environnement. Les adultes devaient également traiter chaque enfant comme le leur, afin de promouvoir un bon comportement généralisé à tous les enfants.
					</p>
					<p className="">
						Au niveau de la cour des chefs, un adolescent était choisi par son père ou par le chef de la famille élargie pour être présenté au chef principal afin qu'il puisse rejoindre son groupe de l’<span className="italic">Itorero</span>. La sélection était faite sur base du bon comportement du reste de sa famille et de sa communauté.
					</p>
					<p className="my-4">
						À la cour du roi, le plus haut niveau de l'<span className="italic">Itorero</span>, la personne choisie pour l'intégrer pouvait être soit le fils d'un homme déjà formé à l'<span className="italic">Itorero</span> du roi, soit un jeune homme qui s'était distingué dans l'<span className="italic">Itorero</span> du chef. Le roi pouvait également choisir un jeune homme qui rejoindrait son <span className="italic">Itorero</span> sur base de ses observations personnelles.
					</p>
					<p className="my-4">
						Les formations <span className="italic">Itorero</span> chez le chef et à la cour royale s'étalaient sur de longues périodes. Ceux qui obtenaient de bons résultats recevaient des vaches en recompense, autorisés à rentrer chez eux et à se marier, ou bien, ils étaient nommés à diverses fonctions nationales. Les Intore qui se distinguaient étaient appelés <span className="italic">Intore zo ku mukondo</span>, equivalent aux Intore de premier rang.
					</p>
					<p className="my-4">
						Pendant la colonisation, l'<span className="italic">Itorero</span> traditionnel a progressivement disparu car les valeurs qui y étaient enseignées s’opposaient aux structures imposées à la société par les colons. En 1924, l'administration coloniale a interdit l'<span className="italic">Itorero</span> classique. Elle a introduit à sa place des écoles de type occidental. Pendant et après la période coloniale, les <span className="italic">Itorero</span> sont devenus des institutions purement culturelles, se concentrant sur le chant et la danse.
					</p>
				</div>
				<Link
					to="/hgs/itorero/how-it-works"
					className="flex flex-row items-center justify-end py-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<p><span className="italic">Itorero</span> ry'Igihugu - <span className="italic">Itorero</span> contemporain</p> &nbsp;{" "}
					<HiOutlineArrowNarrowRight />
				</Link>
			</div>
		);
	}
}

export default ItoreroOverview;
