import React, { Component, Fragment } from 'react'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { Route, Switch } from 'react-router-dom';
import HGSSideNav from '../components/HGSSideNav';
import PageName from "../components/PageName";
import UmushyikiranoHowItWorks from '../components/UmushyikiranoHowItWorks';
import UmushyikiranoImpact from '../components/UmushyikiranoImpact';
import UmushyikiranoOverview from '../components/UmushyikiranoOverview';
import { umushyikiranoSubmenus } from '../utils/menus';

export class Umushyikirano extends Component {
	render() {
		const { match } = this.props;
		return (
			<Fragment>
				<div className="relative flex flex-row w-full h-screen">
					<div className="hidden w-4/12 md:flex">
						<HGSSideNav
							hgsTitle="Umushyikirano"
							hgsSubTitle="gouvernance et justice"
							hgsIntro={
								<p className="text-justify">
									Le Conseil national du dialogue connu sous le nom d’Umushyikirano est l’une des initiatives endogènes du Rwanda. Le mot Umushyikirano se traduit par une réunion où les participants peuvent échanger des idées, partager des expériences et se remettre en question.
								</p>
							}
							submenus={umushyikiranoSubmenus}
						/>
					</div>
					<div className="relative flex px-4 pt-40 overflow-y-scroll bg-white md:w-8/12 lg:px-10 xl:px-24">
						<div>
							<PageName
								title="Que sont les HGI"
								color="primary"
								accentColor="secondary"
								subTitle={
									<>
										Imihigo &nbsp; <HiOutlineArrowNarrowRight />
									</>
								}
								to="/hgs/imihigo/overview"
							/>
						</div>
						<Switch>
							<Route
								exact
								path={`${match.path}`}
								component={UmushyikiranoOverview}
							/>
							<Route
								exact
								path={`${match.path}/overview`}
								component={UmushyikiranoOverview}
							/>
							<Route
								exact
								path={`${match.path}/how-it-works`}
								component={UmushyikiranoHowItWorks}
							/>
							<Route
								exact
								path={`${match.path}/impact`}
								component={UmushyikiranoImpact}
							/>
						</Switch>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default Umushyikirano
