import React, { Component } from "react";
// import rwPediaLogoBlack from "../assets/images/RWandaPedia Logo_Edit-06.png";
import rwPediaLogoWhite from "../assets/images/Rwandapedia_Logo_white.svg";
import NavigationDots from "../components/NavigationDots";
import NavigationScroll from "../components/NavigationScroll";
import { Link } from "react-router-dom";
// import HomeHGSImage from "../assets/images/image5.jpeg";
import homeHGS from "../assets/images/Contact-Bg.png";

export class HomeHGS extends Component {
	render() {
		return (
			<section className="w-full h-screen text-white home-section bg-primary">
				<div
					className="bg-top bg-no-repeat bg-cover home-background"
					style={{ backgroundImage: `url(${homeHGS})` }}
				></div>
				<div className="absolute z-10 w-full h-full home-hgs-gradient"></div>
				<div className="flex flex-col h-full pb-8 sm:w-full sm:px-4 home-left md:px-8">
					<div className="flex flex-row items-center h-full">
						<div className="pr-20 home-intro-content">
							<div className="flex py-4 home-section-logo md:w-48 ">
								<img src={rwPediaLogoWhite} alt="" className="w-full h-full" />
							</div>
							<div className="font-bold home-title sm:text-2xl md:text-3xl xl:text-4xl">
								<p>Les Initiatives Endogènes Innovantes </p>
							</div>
							<div className="pt-4 pr-8 home-intro-paragraph sm:text-sm xl:text-base">
								<p className="my-2">
									Les Initiatives Endogènes Innovantes sont des initiatives d’ordre
									institutionnel et organisationnel conçues par les citoyens rwandais,
									qui puisent dans la culture et l’histoire du pays pour répondre à
									des dèfis locaux et améliorer la vie des communautés.
								</p>
							</div>
							<div className="flex flex-row home-buttons">
								<Link
									to="/hgs/what-are-hgs"
									className="my-5 font-bold text-white border-2 border-white sm:btn lg:px-16 md:px-3 lg:py-6 md:py-2 hover:bg-secondary hover:border-secondary"
								>
									Apprendre plus
								</Link>
							</div>
						</div>
						<div className="flex flex-col items-center justify-center w-auto h-full home-scroll-controls">
							<NavigationDots active={3} />
						</div>
					</div>
					<NavigationScroll to="home-4" />
				</div>
			</section>
		);
	}
}

export default HomeHGS;
