export const cpMenus = [
	{ id: 1, route: "/country/introduction", name: "Aperçu" },
	{ id: 2, route: "/country/overview", name: "Les faits" },
	{ id: 3, route: "/country/history", name: "Histoire" },
	{ id: 4, route: "/country/today", name: "Le Rwanda après le génocide" },
	{ id: 5, route: "/country/institutionalisation", name: "Institutionnalisation de la bonne gouvernance" },
	{ id: 6, route: "/country/gender", name: "Égalité des genres et autonomisation des femmes" },
	{ id: 7, route: "/country/economy", name: "Économie" },
	{ id: 8, route: "/country/strategies", name: "Développement social" },
];

export const hgsMenus = [
	{ id: 1, route: "/hgs/what-are-hgs", name: "Les initiatives endogènes du Rwanda" },
	{ id: 2, route: "/hgs/how-did-they-emerge", name: "Comment les HGI ont-t-elles vu le jour?" },
	{ id: 3, route: "/hgs/what-makes-them-work", name: "Comment les HGI fonctionnent-elles?" },
];

export const abunziSubmenus = [
	{ id: 1, route: "/hgs/abunzi/overview", name: "Aperçu" },
	{ id: 3, route: "/hgs/abunzi/how-it-works", name: "Comment fonctionne le système Abunzi ?" },
	{ id: 4, route: "/hgs/abunzi/performance", name: "Evaluation de l'impact des Abunzi" },
	//	{ id: 5, route: "/hgs/abunzi/challenges", name: "Défis" },
	{ id: 6, route: "/hgs/abunzi/practices", name: "Pratiques bénéfiques" },
];
export const gacacaSubmenus = [
	{ id: 1, route: "/hgs/gacaca/overview", name: "Aperçu" },
	{ id: 2, route: "/hgs/gacaca/how-did-it-work", name: "Comment fonctionnaient les tribunaux Gacaca ?" },
	//	{ id: 3, route: "/hgs/gacaca/jurisdiction", name: "The jurisdictions of Gacaca" },
	//	{ id: 4, route: "/hgs/gacaca/functioning", name: "Functioning of Gacaca" },
	//{ id: 5, route: "/hgs/gacaca/challenges", name: "Défis" },
	{ id: 6, route: "/hgs/gacaca/achievements", name: "Réalisations" },
];
export const itoreroSubmenus = [
	{ id: 1, route: "/hgs/itorero/overview", name: "Aperçu" },
	{ id: 2, route: "/hgs/itorero/how-it-works", name: "Itorero ry'Igihugu - Itorero contemporain" },
	// { id: 3, route: "/hgs/itorero/outcomes", name: "Outcomes" },
	// { id: 4, route: "/hgs/itorero/participants", name: "Participants" },
]
export const ingandoSubmenus = [
	{ id: 1, route: "/hgs/ingando/overview", name: "Aperçu" },
	//	{ id: 2, route: "/hgs/ingando/objectives", name: "Objectives" },
	{ id: 3, route: "/hgs/ingando/impact", name: "L'impact d'Ingando " },
];
export const umushyikiranoSubmenus = [
	{ id: 1, route: "/hgs/umushyikirano/overview", name: "Aperçu" },
	{ id: 2, route: "/hgs/umushyikirano/how-it-works", name: "Comment fonctionne Umushyikirano?" },
	{ id: 3, route: "/hgs/umushyikirano/impact", name: "L'impact d'Umushyikirano" },
	//	{ id: 4, route: "/hgs/umushyikirano/national-umushyikirano-council", name: "National Umushyikirano Council Implementation Report" },
];
export const umwihereroSubmenus = [
	{ id: 1, route: "/hgs/umwiherero/overview", name: "Aperçu" },
	{ id: 2, route: "/hgs/umwiherero/improvement", name: "Amélioration au fil du temps" },
	//	{ id: 3, route: "/hgs/umwiherero/resolutions", name: "Resolutions implementation process" },
];
export const umugandaSubmenus = [
	{ id: 1, route: "/hgs/umuganda/overview", name: "Aperçu" },
	{ id: 2, route: "/hgs/umuganda/how-it-works", name: "Comment Umuganda est-il organisé?" },
	//	{ id: 3, route: "/hgs/umuganda/challenges", name: "Défis rencontrés lors de la mise en œuvre de l'Umuganda" },
	{ id: 4, route: "/hgs/umuganda/impact", name: "L'impact d'Umuganda" },
];
export const ubudeheSubmenus = [
	{ id: 1, route: "/hgs/ubudehe/overview", name: "Aperçu" },
	{ id: 2, route: "/hgs/ubudehe/how-it-works", name: "Comment fonctionne Ubudehe?" },
	{ id: 3, route: "/hgs/ubudehe/poverty-level-categories", name: "Niveaux de pauvreté Ubudehe" },
	{ id: 4, route: "/hgs/ubudehe/organization", name: "Organisation et coordination" },
	//{ id: 5, route: "/hgs/ubudehe/challenges", name: "Défis" },
	{ id: 6, route: "/hgs/ubudehe/impact", name: "L'impact d'Ubudehe" },
];
export const imihigoSubmenus = [
	{ id: 1, route: "/hgs/imihigo/overview", name: "Aperçu" },
	{ id: 2, route: "/hgs/imihigo/how-it-works", name: "Comment fonctionnent les contrats de performance Imihigo?" },
	//{ id: 3, route: "/hgs/imihigo/challenges", name: "Défis" },
];
export const girinkaSubmenus = [
	{ id: 1, route: "/hgs/girinka/overview", name: "Aperçu" },
	{ id: 2, route: "/hgs/girinka/how-it-works", name: "Comment fonctionne Girinka?" },
	{ id: 3, route: "/hgs/girinka/impact", name: "L'impact de Girinka" },
	//{ id: 4, route: "/hgs/girinka/challenges", name: "Défis" },
];
