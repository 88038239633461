import React, { Component } from "react";
import info1 from "../assets/images/cp-infographics/cp-infographics013.png";

export class Economy4 extends Component {
	render() {
		return (
			<div className="flex flex-col my-4">
				<p className="text-2xl font-semibold">Tourisme et Exploitation minière</p>
				<p className="my-4">
					Aujourd'hui, le tourisme et l'exploitation minière sont les principales sources de devises étrangères pour le Rwanda.
				</p>
				<p>
					L'effort initial s'est concentré sur le développement d'un secteur touristique de niche autour du parc national des volcans et de ses célèbres gorilles des montagnes. Un marketing international agressif a été déployé, notamment par l’intermédiaire de la cérémonie innovante de Kwita Izina, au cours de laquelle un nom est donné aux bébés gorilles nés au cours des douze mois précédents. Des célébrités telles que Bill Gates, Natalie Portman, Ted Turner, Ne-Yo, Naomi Campbell ou encore Tony Adams y ont participé.
				</p>
				<p className="my-4">
					En 2007, le pays a commencé à élargir l'industrie du tourisme en développant le secteur du tourisme de réunion et de conférence (MICE, sigle en anglais). À cette fin, le gouvernement a investi dans des infrastructures stratégiques, par exemple en rétablissant la compagnie aérienne nationale RwandAir en 2009 et en achevant en 2016 un ambitieux complexe de salles de conférence connu sous le nom de Kigali Convention Centre. Le gouvernement a également commencé la construction de l'aéroport international de Bugesera. Le développement des infrastructures dans le secteur du tourisme et du MICE a attiré des investissements supplémentaires dans des hôtels quatre et cinq étoiles gérés par de grandes entreprises telles que Radisson Blue et Marriott. Kigali a accueilli le Forum économique mondial en mai 2016 et a accueilli le sommet de l'Union Africaine deux mois plus tard. Le Rwanda était classé 13ème en Afrique dans le secteur MICE en 2014, mais est passé et resté au 3ème rang de 2016 à 2018. Il était au 2ème rang en 2019.
				</p>
				<p>
					Les recettes du tourisme sont passées de 27 millions USD en 2000 à 498 millions USD en 2019, devenant ainsi la principale source de devises étrangères du pays.
				</p>
				<p className="my-4">
					Le secteur minier a fait l'objet d'efforts de modernisation similaires. En 2003, les exportations de minerais s'élevaient à 11 millions USD. En 2017, elles atteignaient 373,4 millions USD et constituent aujourd'hui la deuxième source de recettes d'exportation du Rwanda.
				</p>
				<div
					style={{
						maxWidth: "30rem",
					}}
				>
					<img src={info1} alt="" className="w-full" />
				</div>
			</div>
		);
	}
}

export default Economy4;
