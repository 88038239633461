import React, { Component, Fragment } from 'react'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { Route, Switch } from 'react-router-dom';
import HGSSideNav from '../components/HGSSideNav';
import PageName from '../components/PageName';
//import UmugandaChallenges from '../components/UmugandaChallenges';
import UmugandaHowItWorks from '../components/UmugandaHowItWorks';
import UmugandaImpact from '../components/UmugandaImpact';
import UmugandaOverview from '../components/UmugandaOverview';
import { umugandaSubmenus } from '../utils/menus';

export class Umuganda extends Component {
	render() {
		const { match } = this.props;
		return (
			<Fragment>
				<div className="flex flex-row h-screen w-full relative">
					<div className="hidden md:flex w-4/12">
						<HGSSideNav
							hgsTitle="Umuganda"
							hgsSubTitle="économie"
							hgsIntro={
								<p className="text-justify">
									Le mot Umuganda peut être traduit par «se rassembler dans un but commun pour parvenir à un résultat». Dans la culture traditionnelle rwandaise, les membres de la communauté faisaient appel à leur famille, amis et voisins pour les aider à mener à bien une tâche difficile.
								</p>
							}
							submenus={umugandaSubmenus}
						/>
					</div>
					<div className="flex md:w-8/12 px-4 lg:px-10 xl:px-24 pt-40 overflow-y-scroll bg-white relative">
						<div>
							<PageName
								title="Que sont les HGI"
								color="primary"
								accentColor="secondary"
								subTitle={
									<>
										Umwiherero &nbsp; <HiOutlineArrowNarrowRight />
									</>
								}
								to="/hgs/umwiherero/overview"
							/>
						</div>
						<Switch>
							<Route
								exact
								path={`${match.path}`}
								component={UmugandaOverview}
							/>
							<Route
								exact
								path={`${match.path}/overview`}
								component={UmugandaOverview}
							/>
							<Route
								exact
								path={`${match.path}/how-it-works`}
								component={UmugandaHowItWorks}
							/>
							{/* <Route
								exact
								path={`${match.path}/challenges`}
								component={UmugandaChallenges}
							/> */}
							<Route
								exact
								path={`${match.path}/impact`}
								component={UmugandaImpact}
							/>
						</Switch>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default Umuganda
