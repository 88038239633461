import React, { Component } from "react";
import { Link } from "react-router-dom";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import image032 from "../assets/images/hgs-content-image032.jpeg";

export class IngandoImpact extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Impact of <span className="italic">Ingando</span></p>
				<div className="">
					<div className="my-6">
						<div className="my-4 ">
							<img src={image032} alt="" />
						</div>
						<p className="my-4">
							L’<span className="italic">Ingando</span> a contribué de manière significative à l'unité nationale et à la réconciliation au Rwanda. Cela a été particulièrement vrai pour les sessions menées au cours des premières années du programme (de 1996 à 1999). En effet, la plupart des participants étaient des combattants de retour au pays ou des rwandais ayant un sentiment d’insécurité vis-à-vis du nouveau gouvernement. Une attention particulière a été accordée à la justice sociale et à l'aide à la compréhension des stratégies gouvernementales visant à améliorer le bien-être social. Cette approche était essentielle pour assurer la pérennisation des progrès réalisés en matière de réconciliation.
						</p>
						<p>
							Lorsque le programme a été initié, il a dû faire face à d'importants défis. Parmi ceux-ci, on peut noter : le manque de confiance entre les participants et les animateurs ainsi que la présence d’installations de mauvaise qualité. Avec une certaine lenteur, les problèmes ont tout de même été surmontés au fur et à mesure que des ressources supplémentaires étaient consacrées au programme. Avec le temps, les objectifs du programme ont été mieux compris et cela a joué un rôle essentiel dans le processus d’intégration sociale post-génocide.
						</p>
					</div>
				</div>
				<Link
					to="/hgs/ingando/objectives"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; Aperçu
				</Link>
			</div>
		);
	}
}

export default IngandoImpact;
