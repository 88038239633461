import React, { Component } from "react";
import HGSAnimatedContainer from "./HGSAnimatedContainer";
import PageName from "./PageName";
import image006 from "../assets/images/hgs-content-image026.jpeg";
import image038 from "../assets/images/hgs-content-image038.jpeg";

export class WhatAre3 extends Component {
	render() {
		return (
			<div className="relative px-4 md:p-40">
				<div className="hidden md:flex">
					<PageName
						title="Que sont les HGI"
						color="primary"
						accentColor="secondary"
					/>
				</div>
				<div className="flex flex-col justify-center md:flex-row lg:gap-20 md:gap-8">
					<div className="lg:my-20 md:my-12">
						<HGSAnimatedContainer
							to="/hgs/itorero/overview"
							image={image038}
							title="Itorero/Ingando"
							subtitle="Gouvernance et justice"
							text={
								<p>
									Le programme d'éducation civique, également connu sous le nom d'Itorero, est l'une des
									Initiatives endogènes du Rwanda. Itorero a été réintroduit dans
									2009 comme moyen de reconstruire le tissu social de la nation et
									mobiliser les Rwandais pour défendre d'importantes valeurs culturelles.
								</p>
							}
						/>
					</div>
					<div>
						<HGSAnimatedContainer
							to="/hgs/girinka/overview"
							image={image006}
							title="Girinka"
							subtitle="PROTECTION SOCIALE"
							text={
								<p>
									Girinka (traduction literale: "Je te souhaite d'avoir une vache") décrit une pratique culturelle selon laquelle une vache était donnée par une personne à une autre, en signe de respect et de gratitude ou comme dot de mariage. Girinka a contribué à une augmentation des produits laitiers qui ont permis de réduire la malnutrition et d'augmenter les revenus.
								</p>
							}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default WhatAre3;
