import React, { Component } from 'react'
import { BsChevronUp } from 'react-icons/bs';

export class GoUp extends Component {
    render() {
        return (
					<a href={`#home-1`}>
						<div className="home-scroll font-semibold flex flex-col hover:text-secondary absolute bottom-0 right-0 mx-10 my-40 sm:left-0 sm:mx-6 sm:my-6">
							<BsChevronUp className="text-2xl -my-3 text-primary" />
							<BsChevronUp className="text-2xl -my-1 text-gray-700" />
							<div className="w-full">Remonter</div>
						</div>
					</a>
				);
    }
}

export default GoUp
