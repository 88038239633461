import React, { Component } from "react";
import info1 from "../assets/images/cp-infographics/cp-infographics014.jpg";
import info2 from "../assets/images/cp-infographics/cp-infographics015.png";
import info8 from "../assets/images/cp-infographics/cp-infographics016.png";
import info3 from "../assets/images/cp-infographics/cp-infographics007.png";
import info5 from "../assets/images/cp-infographics/cp-infographics018.png";
import info6 from "../assets/images/cp-infographics/cp-infographics019.png";

export class Economy6 extends Component {
	render() {
		return (
			<div className="flex flex-col">
				<div className="flex flex-col">
					<p className="text-2xl font-semibold">TIC</p>
					<p className="my-4">
						L'un des piliers de la Vision 2020 était l'utilisation des technologies de l'information et de la communication (TIC) comme accélérateur de développement. En 2005, dans le cadre du Plan National d'infrastructure d'information et de communication 2, un reseau de câbles à fibre optique a été installé, reliant les câbles Internet sous-marins au large des côtes du Kenya et de la Tanzanie. Le réseau de câbles relie trente capitales de district. Un centre national de données a été construit, destiné à faciliter l'exploitation commerciale des TIC.
					</p>
					<p>
						Le développement de l'infrastructure des TIC a considérablement amélioré la productivité dans le pays. L'initiative en cours du gouvernement visant à numériser toutes les interactions G2C (Government to Citizens) et G2B (Government to Business) par le biais d'une plate-forme à guichet unique, Irembo, a déjà considérablement amélioré la prestation de services publics. Le Rwanda est classé 1er dans la Communauté de l'Afrique de l'Est et 5ème en Afrique dans le classement Network Readiness Index. Le pays dispose d'un réseau 4G LTE avec une couverture nationale de 95%. Le Rwanda dispose également de 7 000 kilomètres de câble à fibre optique.
					</p>
					<p className="my-4 mb-4 text-2xl font-semibold">Les moteurs de la croissance</p>
					<p className="my-4">
						Les principaux moteurs de la croissance rwandaise ont été la robustesse de sa gouvernance, le développement significatif des infrastructures et l'amélioration soutenue du climat des affaires. Le développement des infrastructures du pays est guidé à la fois par la planification du gouvernement et par la demande de la population locale. Le Rwanda est classé 2ème  en Afrique sur l'indice de facilité à faire des affaires. Pour soutenir le monde des affaires, il est intéressant de noter que 73% des routes nationales sont en bon état.
					</p>
				</div>
				<div className="flex flex-row flex-wrap gap-4">
					<div className="" style={{ height: "6rem" }}>
						<img className="w-auto h-full" src={info1} alt="" />
					</div>
					<div className="" style={{ height: "6rem" }}>
						<img className="w-auto h-full" src={info2} alt="" />
					</div>
					<div className="" style={{ height: "6rem" }}>
						<img className="w-auto h-full" src={info8} alt="" />
					</div>
					<div className="" style={{ height: "6rem" }}>
						<img className="w-auto h-full" src={info3} alt="" />
					</div>
					<div className="" style={{ height: "6rem" }}>
						<img className="w-auto h-full" src={info5} alt="" />
					</div>
					<div className="" style={{ height: "6rem" }}>
						<img className="w-auto h-full" src={info6} alt="" />
					</div>
				</div>
			</div>
		);
	}
}

export default Economy6;
