import React, { Component } from 'react'
import { Fragment } from 'react';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { Route, Switch } from 'react-router-dom';
//import GirinkaChallenges from '../components/GirinkaChallenges';
import GirinkaHowItWorks from '../components/GirinkaHowItWorks';
import GirinkaImpact from '../components/GirinkaImpact';
import GirinkaOverview from '../components/GirinkaOverview';
import HGSSideNav from '../components/HGSSideNav';
import PageName from '../components/PageName';
import { girinkaSubmenus } from '../utils/menus';

export class Girinka extends Component {
	render() {
		const { match } = this.props;
		return (
			<Fragment>
				<div className="relative flex flex-row w-full h-screen">
					<div className="hidden w-4/12 md:flex">
						<HGSSideNav
							hgsTitle="Girinka"
							hgsSubTitle="bien-être social"
							hgsIntro={
								<p className="text-justify">
									Dans le cadre des efforts visant à reconstruire le Rwanda et à promouvoir une identité nationale, le Gouvernement rwandais s’est appuyé sur les pratiques traditionnelles de la culture rwandaise pour adapter ses programmes de développement aux besoins et au contexte du pays.
								</p>
							}
							submenus={girinkaSubmenus}
						/>
					</div>
					<div className="relative flex px-4 pt-40 overflow-y-scroll bg-white md:w-8/12 lg:px-10 xl:px-24">
						<div>
							<PageName
								title="Que sont les HGI"
								color="primary"
								accentColor="secondary"
								subTitle={
									<>
										Abunzi &nbsp; <HiOutlineArrowNarrowRight />
									</>
								}
								to="/hgs/abunzi/overview"
							/>
						</div>
						<Switch>
							<Route exact path={`${match.path}`} component={GirinkaOverview} />
							<Route
								exact
								path={`${match.path}/overview`}
								component={GirinkaOverview}
							/>
							<Route
								exact
								path={`${match.path}/how-it-works`}
								component={GirinkaHowItWorks}
							/>
							<Route
								exact
								path={`${match.path}/impact`}
								component={GirinkaImpact}
							/>
							{/* <Route
								exact
								path={`${match.path}/challenges`}
								component={GirinkaChallenges}
							/> */}
						</Switch>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default Girinka
