import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
	HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import Girinka2 from "../assets/images/Girinka2.jpg"

export class GirinkaHowItWorks extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Comment fonctionne <span className="italic">Girinka</span></p>
				<div className="">
					<img src={Girinka2} alt="" className="w-full" />
				</div>
				<div className="my-6">
					<p className="my-4">Le programme est structuré en deux phases :</p>
					<ol className="m-4 ml-12 hgs-ordered-list">
						<li>
							Un ménage de la communauté dans le besoin et qui serait susceptible de faire bon usage d'une vache est identifié. Il se voit alors attribuer une vache laitière en gestation qui devrait lui fournir du lait et du fumier.
						</li>
						<li>
							Ensuite, le bénéficiaire est dans l'obligation de donner la première génisse à un autre bénéficiaire de sa communauté dans le besoin, sous &#60;&#60; le principe de transmission &#62;&#62;.
						</li>
					</ol>
					<p className="my-4">
						<span className="italic">Girinka</span> a été une forme de filet de sécurité sociale inspirée par la culture, notamment en raison de la façon dont elle introduit un actif productif (une vache laitière) qui peut procurer des avantages à long terme au bénéficiaire. Le Gouvernement rwandais était initialement le seul bailleur de fonds du programme <span className="italic">Girinka</span>, mais les partenaires de développement et même des particuliers se sont depuis impliqués.
					</p>
					<p className="my-4 font-semibold">
						Organisation institutionnelle de l’initiative <span className="italic">Girinka</span>
					</p>
					<p className="my-4">
						L’initiative <span className="italic">Girinka</span> adopte une démarche décentralisée: chacun des 30 districts du pays est responsable de la mise en œuvre de l’essentiel du programme. Cependant, le RAB, l’Agence Rwandaise de l’Agriculture est responsable de la coordination du programme <span className="italic">Girinka</span> au niveau national.
					</p>
					<p className="my-4">
						Chaque district dispose d'un point focal du RAB. Celui-ci assure la liaison avec les agents vétérinaires des secteurs et des districts, les agents de sélection, l'administration locale et le coordinateur du programme Girinka. Ces différents points focaux sont également impliqués dans la mise en œuvre au niveau local, notamment lors de la distribution des vaches, la formation des bénéficiaires et la dissemination de l'information aux éleveurs sur les avantages de travailler avec les centres de collecte de lait et les usines de transformation laitière. Le suivi et l'évaluation sont effectués en partenariat avec les entités de l’administration locale telles que Umurenge (secteur), Akagali (cellule) et Umudugudu (village).
					</p>
					<p className="my-4">
						Avant et après qu'un bénéficiaire ait reçu une vache, soit de manière directe soit par le biais du principe de transmission et les agents vétérinaires des districts et des secteurs lui fournissent un soutien en matière de soins primaires aux animaux par le biais d’une formation, de services vétérinaires. Ces services comprennent par exemple l'insémination artificielle, les semences fourragères et les matières végétales pour l'alimentation de la vache ainsi que la vaccination. L'objectif de ce volet du programme est de doter les ménages des compétences requises en matière d'élevage pour qu'elles puissent s'occuper elles-mêmes de leur vache de façon adéquate.
					</p>
					<p className="my-4 font-semibold">
						Modes de réception d'une vache
					</p>
					<p className="my-4">
						Les deux modes de réception dont un bénéficiaire peut profiter pour recevoir une vache sont les suivantes :
					</p>
					<p className="my-4">
						<span className="italic">Girinka y'ingabirano (don)</span>: une famille pauvre peut recevoir un don d'une vache. Lorsque la vache met bas, la première génisse est donnée à un membre de la communauté. Une fois que la génisse grandit et finit par mettre bas, sa première génisse est alors donnée au bénéficiaire suivant et ainsi de suite. Si un veau est né, le bénéficiaire peut le vendre et utiliser les fonds pour acheter une génisse à transmettre.
					</p>
					<p className="my-4">
						<span className="italic">Girinka y'inguzanyo (prêt)</span>: un prêt bancaire est accordé à une famille pour l'achat d'une vache. Le bénéficiaire doit répondre à certains critères : être en mesure de s'occuper de l'animal, être en capacité de construire une étable et posséder suffisamment de terres pour subvenir aux besoins de la vache.
					</p>
					<p className="my-4 font-semibold">Financement</p>
					<p className="my-4">
						Le financement peut être assuré par le Gouvernement rwandais, par des bailleurs, par des organisations non-gouvernementales ou par des particuliers. Par exemple, dans le cadre du projet d'appui au développement du bétail laitier (PADEBL), le Fonds Africain de Développement a contribué à l’initiative Girinka en lui fournissant 8 200 bovins de race locale et 3 000 bovins croisés.
					</p>
					<p className="my-4">
						Une fois que le budget du programme a été alloué dans le budget national et que des dons ont été reçus, le RAB coordonne le processus d'achat des génisses. Des efforts ont été faits pour décentraliser le programme et allouer la majeure partie du budget du programme aux entités administratives locales.
					</p>
					<p className="my-4">
						Le processus de dons privés est défini par le RAB et est régi par les directives ministérielles de décentralisation. Dans ce cas, les bailleurs/organisations peuvent fixer leurs propres critères pour acquérir les vaches. Cependant, le RAB doit certifier que les vaches sont en bonne santé avant de les donner aux bénéficiaires.
					</p>
					<p className="my-4 font-semibold">Sélection des vaches</p>
					<p className="my-4">
						Les vaches sont sélectionnées et achetées dans le cadre d'un appel d'offres géré par le RAB au niveau national et par le gouvernement local au niveau du district. Avant qu'une vache puisse être achetée à un fournisseur ou acceptée comme don, le RAB procède d'abord à une série d'examens médicaux vétérinaires pour s'assurer de ce que la vache est :
					</p>
					<ul className="ml-8 hgs-ordered-list">
						<li>Physiquement saine.</li>
						<li>
							Exempte de péripneumonie et de brucellose bovines contagieuses.
						</li>
						<li>Agée d'entre 18 et 24 mois et pesant au moins 250 kg.</li>
						<li>Gravide ou en âge d'insémination.</li>
						<li>Au moins 50% du patrimoine génétique de la vache doit être issu d'une race non-locale telle que la Frisonne ou la Jersey.</li>
					</ul>
					<p className="my-4">
						La priorité est accordée à l'achat de vaches auprès de bénéficiaires de l’initiative <span className="italic">Girinka</span> dont les vaches ont vêlé plus d'une fois et qui ont donc procedé au principe de transmission.
					</p>
					<p className="my-4 font-semibold">Sélection des bénéficiaires</p>
					<p className="my-4">
						Conformément aux principes du programme Ubudehe, la communauté au niveau du village se réunit pour décider quels membres devraient bénéficier de l’initiative Girinka (ainsi que d'autres programmes de sécurité sociale).
					</p>
					<p className="my-4">
						Pour pouvoir bénéficier du programme <span className="italic">Girinka</span>, les personnes ou les ménages doivent répondre aux critères suivants:
					</p>
					<ul className="ml-8 hgs-ordered-list">
						<li>Le bénéficiaire ne doit pas être déjà en possession d’une vache.</li>
						<li>Le bénéficiaire doit avoir construit une étable.</li>
						<li>Le bénéficiaire doit disposer de 0,25 à 0,75 hectares de terre, dont une partie doit être plantée de fourrage.</li>
						<li>Le bénéficiaire doit être un Inyangamugayo (personne intègre) et reconnu en tant que tel par la communauté.</li>
						<li>Le bénéficiaire doit être considéré comme pauvre par la communauté et ne doit avoir aucune autre source de revenus.</li>
					</ul>
					<p className="my-4">
						Les bénéficiaires qui n'ont pas assez de terres sur un plan individuel peuvent s'associer à d'autres membres de la communauté pour construire une étable commune (ibikumba) pour leurs vaches.
					</p>
					<p className="my-4 font-semibold">
						Préparation et formation des bénéficiaires
					</p>
					<p className="my-4">
						Avant que les bénéficiaires ne reçoivent une génisse, ils suivent un programme de formation géré par le RAB. La formation comprend un atelier couvrant les cinq piliers de la participation au programme. Les bénéficiaires reçoivent des provisions (médicaments, pompes à pulvérisation et blocs de sel) pour les aider à s'occuper de leur vache en toute sérénité. Les agents de sélection, les agents vétérinaires des secteurs et des districts et les points focaux au niveau local sont également impliqués dans le programme de formation. La formation et le soutien réguliers aux bénéficiaires se poursuivent jusqu'au vêlage de leur vache.
					</p>
					<p className="my-4">
						La formation dispensée aux bénéficiaires repose sur les cinq piliers suivants :
					</p>
					<ul className="ml-8 hgs-ordered-list">
						<li>Élevage</li>
						<li>Nutrition</li>
						<li>Logement</li>
						<li>Santé</li>
						<li>Gestion et conservation des données</li>
					</ul>
					<p className="my-4 font-semibold">
						Distribution des vaches aux bénéficiaires
					</p>
					<p className="my-4">
						Les responsables au niveau du secteur sont chargés d'évaluer le nombre de vaches pouvant être distribuées en tenant compte du budget disponible et du nombre de vaches susceptibles de vêler dans cette communauté. Une fois qu'ils ont déterminé les bénéficiaires pour l'année et que les bénéficiaires ont terminé la formation et construit des étables, la distribution est organisée par le RAB.
					</p>
				</div>
				<Link
					to="/hgs/girinka/impact"
					className="flex flex-row items-center justify-end mt-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					L'impact de Girinka &nbsp; <HiOutlineArrowNarrowRight />
				</Link>
				<Link
					to="/hgs/girinka/overview"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; Aperçu
				</Link>
			</div>
		);
	}
}

export default GirinkaHowItWorks;
