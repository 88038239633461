import React, { Component } from "react";
import africaBg from "../assets/images/cp-infographics/Infographic_9.jpg";
// import image003 from "../assets/images/cp-content-image003.jpeg";
// import image011 from "../assets/images/cp-content-image011.jpeg";

export class Overview3 extends Component {
	render() {
		return (
			<div className="">
				{/* <div className="grid gap-8 my-4 md:grid-cols-2">
					<div
						className="w-full bg-center bg-no-repeat bg-cover"
						style={{
							backgroundImage: `url(${image003})`,
							height: "20rem",
						}}
					></div>
					<div
						className="w-full bg-center bg-no-repeat bg-cover"
						style={{
							backgroundImage: `url(${image011})`,
							height: "20rem",
						}}
					></div>
				</div> */}
				<div
					className="flex flex-row h-screen"
				>
					<div className="w-4/5 bg-center bg-no-repeat bg-contain" style={{ backgroundImage: 'url(' + africaBg + ')' }}>
						<div className="flex flex-col px-4 lg:px-10 xl:px-24">
							<p className="text-2xl font-semibold">Emplacement:</p>
							<p className="my-4">
								Le Rwanda est situé aux frontières de l' 
								<span className="font-semibold">Afrique orientale et centrale. </span>
								Il fait partie de:
							</p>
							<ul className="ml-8 overview-list">
								<li>La Communauté de l'Afrique de l'Est</li>
								<li>La Communauté Économique des États de l'Afrique Centrale</li>
								<li>C'est un pays enclavé entouré par l'Ouganda, la Tanzanie, la RDC et le Burundi</li>
								<li>Il partage le lac Kivu avec la RDC</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Overview3;
