import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
	HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import image012 from "../assets/images/Image-Ubudehe1.JPG";

export class UbudeheHowItWorks extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Comment fonctionne <span className="italic">Ubudehe</span> ?</p>
				<div className="">
					<p className="my-4 text-xl font-semibold">Les objectifs d'<span className="italic">Ubudehe</span> :</p>

					<div>
						<ul className="ml-8 hgs-ordered-list">
							<li>
								Aider les membres de la communauté à classifier le niveau de pauvreté qui existe dans leur communauté et parvenir à une compréhension commune de cette classification
							</li>
							<li>Aider les communautés à définir leurs priorités de développement</li>
							<li>
								Réunir les communautés pour discuter et décider des moyens les plus efficaces et les plus rentables de réduire la pauvreté et de réaliser leurs priorités de développement.
							</li>
							<li>
								Aider les communautés à mettre en place des moyens de financement de leurs plans de développement, tant au niveau individuel que collectif
							</li>
						</ul>
					</div>

					<p className="my-4">
						Pour atteindre ces objectifs, sur l’ensemble du territoire rwandais, les villages concernés se réunissent pendant une période de quatre à sept jours. Ces réunions se tiennent à des moments opportuns tels qu’après les activités agricoles afin d’achever le processus <span className="italic">Ubudehe</span>. Ce processus se déroule au début de l'exercice financier, c’est-à-dire en juillet une fois tous les deux ans.
					</p>
					<p className="my-4">
						Les réunions sont dirigées par le président du comité local de <span className="italic">Ubudehe</span> et le chef de village. Elles durent généralement trois heures par jour. <span className="italic">Ubudehe</span> se déroule à la fois au niveau de l'Umudugudu (village) et au niveau des ménages selon des processus similaires.
					</p>
					<div className="my-6">
						<div className="">
							<img src={image012} alt="" />
						</div>
					</div>
					<div>
						<p className="my-4 text-xl font-semibold">
							Les étapes suivantes sont réalisées tous les deux ans :
						</p>
						<ul className="ml-8 hgs-ordered-list">
							<li>
								Déterminer le profil d'un ménage en situation de pauvreté tel que perçu par les citoyens de la localité en question.
							</li>
							<li>Déterminer les causes et les conséquences de la pauvreté.</li>
							<li>
								Établir la carte sociale de la cellule en indiquant les noms des chefs de famille, leur catégorie sociale (les différentes catégories sont à nouveau décidées par les habitants) et l'infrastructure de développement existante.
							</li>
						</ul>
					</div>
					<div>
						<p className="my-4 text-xl font-semibold">
							Les étapes suivantes sont réalisées chaque année :
						</p>
						<ul className="ml-8 hgs-ordered-list">
							<li>
								Identifier et analyser les problèmes auxquels la communauté est confrontée. Déterminer un problème prioritaire à traiter.
							</li>
							<li>
								Planifier les activités et les ressources nécessaires pour traiter le problème prioritaire au moyen d'un plan d'action collectif.
							</li>
							<li>
								Mettre en place un système pour gérer l'action collective identifiée.
							</li>
							<li>
								Soumettre le plan d'action à un test d'applicabilité pour toutes les parties prenantes afin de voir si les stratégies proposées dans le plan sont les plus judicieuses pour résoudre le problème qui a été identifié.
							</li>
							<li>Vérifier si les principes d'<span className="italic">Ubudehe</span> sont respectés.</li>
							<li>
								Le comité de gestion de <span className="italic">Ubudehe</span> du village (élu par la communauté), les techniciens locaux, les autorités locales et les autres parties prenantes approuvent le plan d'exécution de l'action collective et s'engagent à sauvegarder et à respecter les principes de l'action collective.
							</li>
							<li>
								Après ce processus, des fonds sont mis à disposition du comité de gestion de <span className="italic">Ubudehe</span> du village pour soutenir l'action collective identifiée.
							</li>
						</ul>
					</div>
					<div>
						<p className="my-4 text-xl font-semibold">
							Le ménage  modèle à aider
						</p>
						<p className="my-4">
							Un ménage est choisi pour poursuivre le processus <span className="italic">Ubudehe</span> afin de l'aider à surmonter la pauvreté. L'objectif de la sélection d'un ménage est de fournir à la communauté un modèle qui peut être reproduit. Les stratégies d'adaptation du ménage sont analysées avant que le processus suivant ne soit entrepris avec l'aide de facilitateurs de <span className="italic">Ubudehe</span> qui sont formés pour cela :
						</p>
						<ol className="ml-6 hgs-ordered-list">
							<li>
								Déterminer les stratégies d'adaptation du ménage tout au long de l'année.
							</li>
							<li>
								Analyser ces stratégies afin d'élaborer un plan pour améliorer les conditions de vie du ménage.
							</li>
							<li>
								Planifier les activités et budgétiser les moyens nécessaires à l'exécution de la meilleure stratégie.
							</li>
							<li>
								Effectuer un test de compatibilité mené par des personnes intègres de la communauté (inyangamugayo) afin de s'assurer que la stratégie choisie est appropriée pour le ménage.
							</li>
							<li>
								Les membres du ménage acceptent et signent un reçu pour les fonds qui leur sont accordés. Ils conviennent du fait que les fonds soutenant l'exécution de leur stratégie et qui leur sont attribués ne leur seront pas renouvelés. Ces fonds seront attribués à un autre bénéficiaire selon un principe de rotation
							</li>
						</ol>
					</div>
					<p className="my-4">
						Un élément clé de <span className="italic">Ubudehe</span> est représenté par le fait que ce sont les membres de la communauté qui définissent les niveaux de vie ou de pauvreté des ménages de leur village. Ce processus a lieu tous les deux ans et les informations qui en découlent sont utilisées pour décider des priorités de développement ainsi que des citoyens qui devraient bénéficier d'autres programmes de sécurité sociale et de mécanismes endogènes tels que les programmes <span className="italic">Umuganda</span> et <span className="italic">Girinka</span>.
					</p>
				</div>
				<Link
					to="/hgs/ubudehe/poverty-level-categories"
					className="flex flex-row items-center justify-end mt-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<p>Niveaux de pauvreté <span className="italic">Ubudehe</span></p> &nbsp; <HiOutlineArrowNarrowRight />
				</Link>
				<Link
					to="/hgs/ubudehe/overview"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; Aperçu
				</Link>
			</div>
		);
	}
}

export default UbudeheHowItWorks;
