import React, { Component } from "react";
import { BsChevronDown } from "react-icons/bs";

export class NavigationScroll extends Component {
	render() {
		const { to } = this.props;
		return (
			<a href={`#${to}`}>
				<div className="home-scroll font-semibold flex flex-col hover:text-secondary absolute bottom-0 right-0 mx-10 my-40 sm:left-0 sm:mx-6 sm:my-6">
					<div className="w-full">Vers le bas</div>
					<BsChevronDown className="text-2xl -mt-1 text-primary" />
					<BsChevronDown className="text-2xl -mt-4 text-gray-700" />
				</div>
			</a>
		);
	}
}

export default NavigationScroll;
