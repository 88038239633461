import React, { Component } from "react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { Link } from "react-router-dom";
import PageName from "../components/PageName";
import Strategies from "../components/Strategies";
import Strategies2 from "../components/Strategies2";

export class CPStrategies extends Component {
	render() {
		return (
			<div className="text-justify">
				<PageName
					title="Profil du pays"
					color="primary"
					accentColor="fifth"
					subTitle={
						<>
							<HiOutlineArrowNarrowLeft />
							&nbsp; Accuel
						</>
					}
					to="/"
				/>
				<p className="text-3xl font-semibold">Développement social</p>
				<div>
					<Strategies />
				</div>
				<div>
					<Strategies2 />
				</div>
				<div>
					<Link
						to="/country/economy"
						className="py-12 flex flex-row items-center justify-end md:justify-start font-semibold hover:text-fifth text-primary"
					>
						<HiOutlineArrowNarrowLeft /> &nbsp; Économie
					</Link>
				</div>
			</div>
		);
	}
}

export default CPStrategies;
