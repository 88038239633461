import React, { Component } from "react";
import PageName from "./PageName";
import image016 from "../assets/images/What3.jpg";

export class WhatMakes3 extends Component {
	render() {
		return (
			<div className="relative flex flex-col w-full p-4 text-justify md:h-screen lg:p-40 md:p-16">
				<div className="hidden md:flex">
					<PageName
						title="Que sont les HGI"
						color="primary"
						accentColor="secondary"
					/>
				</div>
				<div className="grid gap-4 md:grid-cols-2">
					<div className="relative h-full md:text-sm">
						<p className="font-semibold lg:text-2xl md:text-2xl">
							La redevabilité
						</p>
						<p className="my-4">
							Des mécanismes de redevabilité ont également renforcé l'efficacité des HGI. Alors que les mesures prises par les collectivités locales sont soumises à la surveillance d'institutions de contrôle classiques (telles que l'Office de l'Ombudsman), d'autres mécanismes de redevabilité ont été mis en place, notamment <span className="italic">Imihigo</span> et <span className="italic">Umushyikirano</span>, qui permettent une participation plus active des citoyens, conformément à la philosophie des HGI.
						</p>
						<p className="my-4">
							<span className="italic">Umushyikirano</span>, le Conseil du dialogue national, réunit chaque année le président de la République et les représentants des citoyens pour discuter des questions relatives à l'état de la nation et de son unité. Le Dialogue national est diffusé en direct à la télévision, à la radio et sur les réseaux sociaux. Les citoyens disposent de lignes téléphoniques gratuites pour appeler et exprimer leurs griefs. Ils peuvent aussi bien utiliser les plateformes des réseaux sociaux. Ces demandes citoyennes sont traitées rapidement par les institutions compétentes de l'État. <span className="italic">Umushyikirano</span> est un mécanisme très efficace qui incite les fonctionnaires des administrations locales et centrales à bien travailler et à éviter d'être exposés publiquement.
						</p>
					</div>
					<div className="flex items-center h-full xl:pl-20 md:pl-4">
						<div className="">
							<img src={image016} alt="" />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default WhatMakes3;
