import React, { Component } from "react";
import { Link } from "react-router-dom";

export class HomeTopMenu extends Component {
	render() {
		return (
			<div className="w-full pr-24 font-semibold sm:none pt4">

				<div className="flex flex-row justify-end">
					<a href={"https://rwandapedia.rw"+ window.location.pathname} className="cursor-pointer" >ENG</a>
					<p>/</p>
					<a href={window.location.pathname} className="cursor-pointer text-secondary">FR</a>
				</div>
				<ul className="flex flex-row justify-between text-center list-none md:gap-4">
					<li className="text-sm hover:text-secondary">
						<Link to="/country/introduction">Profil du pays</Link>
					</li>
					<li className="text-sm hover:text-secondary">
						<Link to="/hgs/what-are-hgs">Les Initiatives Endogènes Innovantes</Link>
					</li>
					<li className="text-sm hover:text-secondary">
						<a href="#home-4">Apprentissage en ligne</a>
					</li>
					<li className="text-sm hover:text-secondary">
						<a href="#home-5">Contactez-nous</a>
					</li>
				</ul>
			</div>
		);
	}
}

export default HomeTopMenu;
