import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
	HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import PageName from "../components/PageName";
import infographics038 from "../assets/images/cp-infographics/cp-infographics038.jpg";
import Good1 from "../assets/images/Good-Governance1.jpg";

export class CPInstitutionalisation extends Component {
	render() {
		return (
			<div className="text-justify">
				<PageName
					title="Profil du pays"
					color="primary"
					accentColor="fifth"
					subTitle={
						<>
							Égalité des sexes et autonomisation des femmes &nbsp;{" "}
							<HiOutlineArrowNarrowRight />
						</>
					}
					to="/country/gender"
				/>
				<p className="text-3xl font-semibold">
					Institutionnalisation de la bonne gouvernance
				</p>
				<div className="">
					<img src={Good1} alt="" className="w-full" />
				</div>
				<div>
					<div>
						<p className="my-4">
							Juste après le génocide et la guerre civile, la nouveaux dirigeants se sont tourné vers les institutions administratives héritées du régime précédent, qui ont ensuite fonctionné pendant plusieurs années encore. La création des nouvelles institutions, qui a commencé en 1998, visait à mettre fin au passé. Au sein du pouvoir exécutif, les services de taxation et de perception des droits étaient trop dépendants du Ministère des Finances. Les décideurs ont donc cherché à introduire plus de contrôle et d'équilibre au sein du pouvoir exécutif en rendant ses institutions de gouvernance économique plus fortes et plus autonomes.
						</p>
						<p className="my-4">
							La première institution de gouvernance économique à avoir été créée fut le <span className="font-bold">National Tender Board (Commission nationale d'appels d'offres)</span> en novembre 1997 pour contrer les nombreux abus dans l'attribution des marchés publics. Après avoir fonctionné pendant plusieurs années, la centralisation du processus d'attribution d'importants marchés publics au sein de cette institution unique créa un volume des requettes intenable et causa de nombreux retards dans les opérations de l'administration. En février 2008, la<span className="font-bold"> Rwanda Public Procurement Authority - RPPA (Office Rwandais des Marchés Publics)</span> a remplacé le National Tender Board. La RPPA a décentralisé le processus d'attribution des marchés publics et a créé une entité indépendante pour faire appel de ses décisions. La RPPA répond aux exigences de la Déclaration de Paris sur l'efficacité de l'aide et au Programme d'Action d'Accra. Cela a permis aux bailleurs de fonds valider le système rwandais d'attribution des marchés publics, ce qui a facilité l'octroi d'une aide sous forme d'appui budgétaire direct.
						</p>
						<p className="my-4">
							L'<span className="font-bold">Office rwandais des recettes </span>a été créé en novembre 1997 pour renforcer la perception d' impôts et des taxes ; il dispose également d'une unité de lutte contre la corruption. En juin 1998, le  <span className="font-bold"> Bureau de l'Auditeur Général </span> a été créé pour contrôler les ministères et les entreprises appartenant à l'État. En avril 2011, la <span className="font-bold">Commission parlementaire des comptes publics</span> a été créée en partie pour remédier à l'absence de suivi des rapports de l'Auditeur Général au parlement. Cette commission organise aujourd’hui des auditions publiques des responsables politiques et administratifs des institutions gouvernementales et exerce des pressions sur les pouvoirs exécutif et judiciaire pour qu'ils prennent des mesures énergiques dans les cas de mauvaise gestion des fonds publics.
						</p>
						<p className="my-4">
							En 2004, l' <span className="font-bold"> Office de l'Ombudsman </span>a été créé pour lutter contre la corruption et l'injustice. Ce bureau recueille les déclarations annuelles du patrimoine des hauts fonctionnaires et contrôle l'application de la loi couvrant le code de conduite des responsables d'institutions publiques. Il sert de recours aux citoyens contre les actes de l'administration considérés comme injustes. L'Office de l'Ombudsman dispose également d'une unité spécialisée dans la lutte contre la corruption.
						</p>
						<p className="my-4">
							En mai 2001, le <span className="font-bold"> Conseil national des examens </span> a été créé pour gérer le système d'examens dans les écoles primaires et secondaires publiques et privées qui suivent le programme national. L'équité accrue dans la conduite des examens a eu des répercussions sociales importantes en raison de l'arbitraire qui existait dans ce domaine sous le régime Habyarimana.
						</p>
						<p className="my-4">
							En janvier 2009, un <span className="font-bold">Observatoire du genre</span> a été créé et a été chargé de promouvoir le traitement de données ventilées par genre.
						</p>
						<p className="my-4">
							Ces institutions ont été créées pour garantir l'équité dans l’octroi de services publics et se sont révélées efficaces. Toutefois, elles ne sont pas complètement exemptes de trafic d'influence.
						</p>
						<p className="my-4">
							Outre ces institutions, une loi importante, la
							<span className="font-bold"> loi organique sur les finances et les biens de l'État </span>a été adoptée en septembre 2006. Elle détermine les pouvoirs, les rôles et les responsabilités des institutions participantes au système de gestion des finances et des dépenses publiques en séparant clairement les prérogatives du pouvoir exécutif de celles du pouvoir législatif. En 2007, le gouvernement a entamé une évaluation des dépenses et des finances publiques. Cet exercice a examiné le processus d'autorisation et de justification des dépenses, de collecte des recettes, d'attribution des contrats et de gestion des biens de l'État. À la suite de cet examen, le gouvernement a lancé une réforme de son système de gestion des finances publiques et a apporté de nombreux ajustements aux principales institutions responsables de la gestion des affaires publiques. L'une des améliorations les plus importantes a été la mise en place d'un <span className="font-bold">Système Intégré de Gestion des Finances Publiques (Integrated Financial Management Information System - IFMS) </span>  qui a permis d’automatiser les processus de planification, de budgétisation, de comptabilité et de rapports financiers.
						</p>
						<p className="my-4 text-xl font-semibold">
							Efficacité des institutions de gouvernance
						</p>
						<p className="my-4">
							Les institutions de gouvernance qui ont été créées se sont révélées efficaces. L'indice de contrôle de la corruption, qui contient les données les plus anciennes sur la corruption au Rwanda, montre les progrès du pays dans ce domaine (Voir le tableau ci-dessous). En 1996, le Rwanda se situait au plus bas niveau de l'échelle en Afrique subsaharienne, avec le Burundi, la République Démocratique du Congo, la Gambie et le Sierra Leone. Le Rwanda est ensuite passé d’un classement percentile de 26,88 cette même année à un rang percentile de 70,67 en 2018. L'indice de perception de la corruption de Transparency International, dont les données sur le Rwanda ont commencé en 2006, confirme cette évolution. Sous cet index, le Rwanda est passé du 121e rang mondial en 2006 au 49e rang en 2020, ce qui représente la quatrième meilleure performance en Afrique cette année-là.
						</p>
					</div>

					<div>
						<p className="font-semibold">
							Indice de contrôle de la corruption pour le Rwanda (classement percentile 0-100)
						</p>
						<div className="mt-4 border-b-4 border-fifth">
							<img src={infographics038} alt="" style={{ height: "42rem" }} />
						</div>
						<p className="font-semibold text-right text-fifth">
							Source : Banque mondiale, Indicateurs de gouvernance mondiale (2020)
						</p>
					</div>
				</div>
				<div>
					<Link
						to="/country/gender"
						className="flex flex-row items-center justify-end pt-12 font-semibold md:justify-start hover:text-fifth text-primary"
					>
						Égalité des genres et autonomisation des femmes &nbsp;{" "}
						<HiOutlineArrowNarrowRight />
					</Link>
					<Link
						to="/country/today"
						className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-fifth text-primary"
					>
						<HiOutlineArrowNarrowLeft /> &nbsp; Le Rwanda après le génocide
					</Link>
				</div>
			</div>
		);
	}
}

export default CPInstitutionalisation;
