import React, { Component } from "react";
import image002 from "../assets/images/Social-Development1.jpg";

export class Strategies extends Component {
	render() {
		return (
			<div className="flex flex-col ">
				<div
					className="w-full my-4 bg-center bg-no-repeat bg-cover"
					style={{
						backgroundImage: `url(${image002})`,
						height: "30rem",
					}}
				></div>
				<div className="">
					<p>
						Dans sa vision de transformation, le Rwanda place le citoyen au centre des activités de développement. Le Rwanda a mis l'accent sur la prestation de services aux citoyens, notamment dans les domaines de la santé, de l'hygiène publique et de l'éducation. Le pays a également mis en place une protection sociale qui est maintenant inscrite dans la constitution. Le secteur de la protection sociale du Rwanda comprend quatre piliers :
					</p>
					<div className="my-4">
						<ul className="ml-8 overview-list">
							<li>
								Pilier 1 : Régimes de sécurité sociale visant à assurer un de niveau de vie minimum tout au long du cycle de vie.
							</li>
							<li>
								Pilier 2 : Aide sociale à court terme sous forme d'aide temporaire ou accessoire en espèces ou en nature pour faire face aux risques ou privations à court terme.
							</li>
							<li>
								Pilier 3 : Services d'aide sociale offrant une protection, un soutien psycho-social, une orientation vers des services complémentaires et une promotion de l'inclusion sociale pour les enfants, les jeunes, les femmes, les personnes âgées et les personnes handicapées.
							</li>
							<li>
								Pilier 4 : Soutien aux moyens de subsistance et à l'emploi pour permettre aux bénéficiaires pauvres et vulnérables de sortir durablement de l'extrême pauvreté.
							</li>
						</ul>
					</div>
				</div>
			</div>
		);
	}
}

export default Strategies;
