import React, { Component } from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import ReactPlayer from "react-player/lazy";
import { Link } from "react-router-dom";
import gacacaOverviewImage from "../assets/images/Gacaca1.jpeg";

export class GacacaOverview extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Aperçu</p>
				<div className="flex flex-row items-start my-4">
					<div className="-mt-4 text-5xl text-secondary">&ldquo;</div>
					<div>
						<p className="text-xl italic font-semibold text-center">
							Un jour, lors d'un service commémoratif, un survivant m'a approché. Il était très émotif. Il m'a demandé : "Pourquoi nous demandez-vous de pardonner ? N'avons-nous pas assez souffert ? Nous n'étions pas la cause de ce problème. Pourquoi devons-nous apporter la solution ?" C'était des questions très difficiles. J'ai donc fait une longue pause. Puis je lui ai dit : "Je suis vraiment désolé. Vous avez raison. Je vous en demande trop. Mais je ne sais pas quoi demander aux auteurs. Le mot "désolé" ne ramènera aucune vie. Seul le pardon peut guérir cette nation. Le fardeau repose sur les survivants parce qu'ils sont les seuls à avoir quelque chose à offrir.
							<strong className="text-secondary">&rdquo;</strong>
						</p>
					</div>
				</div>
				<p className="">- Le président Paul Kagame dans "Humains de New York”</p>
				<div className="w-full my-4">
					<ReactPlayer
						width="100%"
						height="30rem"
						controls={true}
						loop={false}
						muted={true}
						url="https://youtu.be/RzM87JwEj9M"
					/>
				</div>
				<div className="">
					<p className="my-4">
						Après le génocide de 1994 perpétré contre les Tutsis, rendre la justice pour les crimes commis constituait un énorme défi. Depuis 1959, les responsables des massacres et de l'oppression des Tutsis ont bénéficié non seulement de mesures d'amnisties générales, mais aussi récompensés. En 1994, les architectes du génocide ont amené à une participation massive aux tueries pour brouiller les lignes de la responsabilité individuelle. En l'an 2000, plus de 130 000 personnes soupçonnées de génocide étaient détenues dans des conditions très précaires. Entre 1996 et 1999, seuls 300 à 350 cas ont été jugés par les tribunaux classiques. On estime qu'il aurait fallu 100 ans pour juger toutes les affaires de génocide en utilisant ce mode de fonctionnement.
					</p>
					<p className="">
						La <span className="italic">Gacaca</span> contemporaine s'inspire du modèle traditionnel. Elle reproduit un système de justice communautaire de proximité ayant pour finalité la restauration du lien social. Les tribunaux <span className="italic">Gacaca</span> ont le mérite d'avoir jeté les bases de la paix, de la réconciliation et de l'unité au Rwanda. Les tribunaux <span className="italic">Gacaca</span> ont officiellement corturé leur travail en juin 2012.
					</p>
					<p className="my-4">
						Le mot <span className="italic">Gacaca</span> réfère à la petite clairière où une communauté se réunissait traditionnellement pour discuter des questions qui la préoccupaient. Les personnes intègres du village (les plus agés et chefs de familles), nommées inyangamugayo, animaient une discussion à laquelle tout membre de la communauté avait la possibilité de participer. Une fois que tout le monde s'était exprimé, les inyangamugayo prenaient une décision sur la manière de résoudre le problème. De cette façon, les <span className="italic">Gacaca</span> agissaient comme un tribunal. Si la décision était acceptée par tous les membres de la communauté, la réunion se terminait par le partage de boissons, en guise de réconciliation.
					</p>
				</div>
				<div className="my-4">
					<img src={gacacaOverviewImage} alt="" />
				</div>
				<div className="">
					<p className="my-4">
						L'idée de confier les procès liés au génocide à des tribunaux communautaires inspirés de <span className="italic">Gacaca</span>, un mécanisme traditionnel de résolution des conflits, a suscité de vives réactions. Certains ont estimé que ces crimes ne pouvaient pas être jugés par des citoyens ordinaires. D'autres y ont vu une manœuvre visant à rejeter la responsabilité sur l'ensemble de la population hutu. D’autres encore ont craint que cela n'entraîne une impunité généralisée pour les auteurs du génocide, comme par le passé. En dehors du Rwanda, les juristes s'inquiétaient de l'absence de magistrats et d'avocats de la défense formés en tant que tels.
					</p>
					<p className="">
						La procédure <span className="italic">Gacaca</span> contemporaine a été officiellement lancée le 18 juin 2002 par le président Paul Kagame. L'objectif principal était de réconcilier les Rwandais par un processus de justice participative. Les membres de chaque communauté devraient assumer tous les rôles clés, en tant que procureurs, juges et témoins. Les tribunaux <span className="italic">Gacaca</span> se sont appuyés sur la catégorisation des délits et la réduction des peines pour ceux qui avouaient volontairement et demandaient pardon. Au total, 1 958 634 affaires liées au génocide ont été jugées par les <span className="italic">Gacaca</span>.
					</p>
					<p className="my-4">
						Les juridictions <span className="italic">Gacaca</span> ont officiellement corturé leur travail dix ans plus tard, le 18 juin 2012. Elles sont créditées d'avoir posé les bases de la paix, de la réconciliation et de l'unité au Rwanda.
					</p>
				</div>
				<Link
					to="/hgs/gacaca/how-did-it-work"
					className="flex flex-row items-center justify-end py-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<p>Comment fonctionnaient les tribunaux Gacaca?</p> &nbsp; <HiOutlineArrowNarrowRight />
				</Link>
			</div>
		);
	}
}

export default GacacaOverview;
