import React, { Component } from "react";
import info1 from "../assets/images/cp-infographics/cp-infographics012.png";
import image008 from "../assets/images/cp-content-image008.jpeg";

export class Economy3 extends Component {
	render() {
		return (
			<div className="flex flex-col">
				<div className="flex flex-col">
					<p className="text-2xl font-semibold">Secteurs traditionnels</p>
					<p className="my-4">
						Dans le sillage de l'adoption de la stratégie de développement Vision 2020 au cours de l’année 2000 et pour relancer rapidement l'activité économique, une stratégie de promotion de la croissance en trois volets a été adoptée et a été centrée sur l’amélioration du climat des affaires, le développement d'infrastructures ainsi que sur la réforme et l’investissement dans les secteurs traditionnels qui présentaient un fort potentiel de croissance. En 2001, le gouvernement a mis en place le Programme National Rwandais pour l'Innovation et la Compétitivité afin de promouvoir la croissance dans les secteurs du café, du thé et du tourisme en établissant des stratégies de croissance globales pour chacun de ces secteurs. Un autre objectif était d'accroître la valeur ajoutée. Le gouvernement a alors encouragé la production de café de haute qualité entièrement lavé en créant des incitations pour les entrepreneurs privés à investir et à créer des stations de lavage de café.
					</p>
					<p className="my-4">
						Le Rwanda vend maintenant son café à de grands acheteurs internationaux tels que Starbucks, Green Mountain, Sainsbury's, CostCo et Walmart. Les revenus liés aux exportations de café sont passés de moins de 20 millions USD en 2003 à environ 68 millions USD en 2018. Le secteur du thé a par ailleurs suivi un chemin similaire.
					</p>
				</div>
				<div className="flex flex-col">
					<div>
						<img src={image008} alt="" />
					</div>
					<div className="my-4" style={{ maxWidth: "25rem" }}>
						<img src={info1} alt="" className="w-full" />
					</div>
				</div>
			</div>
		);
	}
}

export default Economy3;
