import React, { Component } from "react";
import PageName from "./PageName";
import image053 from "../assets/images/What2.jpg";

export class WhatMakes2 extends Component {
	render() {
		return (
			<div className="relative flex flex-col w-full p-4 text-justify md:h-screen lg:p-40 md:p-16">
				<div className="hidden md:flex">
					<PageName
						title="Que sont les HGI"
						color="primary"
						accentColor="secondary"
					/>
				</div>

				<div className="grid gap-4 md:grid-cols-2">
					<div className="relative h-full md:text-sm">
						<p className="font-semibold lg:text-2xl md:text-2xl">
							Gouvernement local
						</p>
						<p className="my-4">
							Le gouvernement local est l'un des vecteurs qui permettent aux HGI d'exprimer pleinement leur potentiel. En 2006, les collectivités locales ont fait l'objet d'une profonde réforme visant à améliorer l'efficacité de la prestation de services publics, principalement en rendant ces services plus accessibles. Le lieu de prestation de ces services a été déplacé des districts vers les secteurs, qui se situent plus en aval dans la hiérarchie administrative et donc plus proches des citoyens, élargissant la taille des districts. Un nouveau niveau administratif, plus proche encore de la population a été ajouté : le village ou Umudugudu. La capacité des secteurs et des districts a été augmentée grâce à l'amélioration du profil professionnel et la rémunération de leurs gestionnaires. Les dirigeants locaux sont devenus un lien et des intermédiaires essentiels et indispensables entre l'administration et la population, utilisant ainsi plus largement les HGI pour stimuler le développement local.
						</p>
					</div>
					<div className="flex items-center h-full xl:pl-20 md:pl-4">
						<div className="">
							<img src={image053} alt="" />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default WhatMakes2;
