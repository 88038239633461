import React, { Component } from "react";
import { HiMenu, HiX, HiOutlineChevronRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import logo from "../assets/images/RWandaPedia Logo_Edit-06.png";
import logoM from "../assets/images/rwandapedia_logo_mobilee.png";
import WelcomeImage from "../assets/images/image59.jpeg";
import CPImage from "../assets/images/cp-content-image009.jpeg";
import HGSImage from "../assets/images/image5.jpeg";
import { cpMenus, hgsMenus } from "../utils/menus";

export class Menu extends Component {
	state = {
		showHGS: false,
		showCP: false,
		showRWPedia: false,
		closeMenu: true
	};
	openMenu = (e) => {
		e.preventDefault();
		document.querySelector("#open-menu").style.display = "none";
		document.querySelector("#close-menu").style.display = "block";
		document.querySelector("#full-menu").style.width = "100vw";
		document.querySelector("#menus").style.display = "flex";
		document.querySelector("#sub-menus").style.display = "flex";
		console.log(window.location.pathname); 
	};
	closeMenu = (e) => {
		// e.preventDefault();
		document.querySelector("#open-menu").style.display = "block";
		document.querySelector("#close-menu").style.display = "none";
		document.querySelector("#full-menu").style.width = "0";
		document.querySelector("#menus").style.display = "none";
		document.querySelector("#sub-menus").style.display = "none";
		this.setState(() => ({
			showRWPedia: false,
			showCP: false,
			showHGS: false,
			closeMenu: true
		}));
	};

	showRWPedia = () => {
		this.setState({
			showRWPedia: true,
			showCP: false,
			showHGS: false,
			closeMenu: true,
		}, );
	};

	showCP = () => {
		this.setState({
			showRWPedia: false,
			showCP: true,
			showHGS: false,
			closeMenu: true,
		});
	};

	showHGS = () => {
		this.setState({
			showRWPedia: false,
			showCP: false,
			showHGS: true,
			closeMenu: true,
		});
	};
	hideSubmenus = () => {
		this.setState(() => ({
			showRWPedia: false,
			showCP: false,
			showHGS: false,
		}));
	};
	render() {
		return (
			<div className="">
				<div className="fixed top-0 left-0 z-30 p-6 sm:m-nav sm:bg-black sm:w-full open">
					<img src={logoM} alt="" className="m-logo" />
					<HiMenu
						id="open-menu"
						className="text-4xl text-white cursor-pointer sm:mr-2 hover:text-secondary"
						onClick={this.openMenu}
					/>
				</div>
				<div
					id="full-menu"
					className="fixed top-0 left-0 z-20 flex flex-row w-full h-screen bg-white closed menu-content"
				>
					<div
						id="menus"
						className="flex flex-col justify-center w-6/12 sm:w-full sm:justify-start sm:mt-16"
					>
						<div className="fixed top-0 left-0 p-6 sm:right-0 sm:left-auto">
							<HiX
								id="close-menu"
								className="hidden text-4xl cursor-pointer hover:text-secondary sm:text-black"
								onClick={this.closeMenu}
							/>
						</div>
						<ul className="inline-block list-none" onClick={this.closeMenu}>
							<Link to="/">
								<li
									id="open-rw"
									className="py-6 pl-24 pr-8 text-2xl font-bold border-l-8 border-white menu-item sm:text-xl sm:pl-6 hover:bg-hgsBgLight hover:border-secondary"
									onMouseOver={this.showRWPedia}
								>
									Bienvenue sur Rwandapedia
								</li>
							</Link>
							<Link to="/country/introduction" className="sm:none">
								<div
									className="flex flex-row justify-between py-6 pl-24 pr-8 text-2xl font-bold border-l-8 border-white menu-cp sm:text-xl sm:pl-6 menu-item hover:bg-hgsBgLight hover:border-secondary"
									onMouseOver={this.showCP}
								>
									<li className="">Profil du pays</li>
									<HiOutlineChevronRight className="text-3xl font-bold sm:text-2xl" />
								</div>
							</Link>
							<Link to="/hgs/what-are-hgs" className="sm:none">
								<div
									className="flex flex-row justify-between py-6 pl-24 pr-8 text-2xl font-bold border-l-8 border-white menu-hgs menu-item sm:text-xl sm:pl-6 hover:bg-hgsBgLight hover:border-secondary"
									onMouseOver={this.showHGS}
								>
									<li className="">Les Initiatives Endogènes Innovantes </li>
									<HiOutlineChevronRight className="text-3xl font-bold sm:text-2xl" />
								</div>
							</Link>
							<Link
								to="/country/introduction"
								className="sm:block nav-dropdown"
								onClick={() => this.showCP(false)}
							>
								<div className="flex flex-row justify-between py-6 pl-24 pr-8 text-2xl font-bold border-l-8 border-white menu-cp sm:text-xl sm:pl-6 menu-item hover:bg-hgsBgLight hover:border-secondary">
									<li className="">Profil du pays</li>
									<HiOutlineChevronRight className="text-3xl font-bold sm:text-2xl" />
								</div>
							</Link>
							<Link
								to="/hgs/what-are-hgs"
								className="sm:block nav-dropdown"
								onClick={() => this.showHGS(false)}
							>
								<div className="flex flex-row justify-between py-6 pl-24 pr-8 text-2xl font-bold border-l-8 border-white menu-hgs menu-item sm:text-xl sm:pl-6 hover:bg-hgsBgLight hover:border-secondary">
									<li className="">Les initiatives locales du Rwanda </li>
									<HiOutlineChevronRight className="text-3xl font-bold sm:text-2xl" />
								</div>
							</Link>
							<a
								href="https://elearning.rwandapedia.rw/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<li className="py-6 pl-24 pr-8 text-2xl font-bold border-l-8 border-white menu-item sm:text-xl sm:pl-6 hover:bg-hgsBgLight hover:border-secondary">
									Apprentissage en ligne
								</li>
							</a>
							<Link to="/#home-5">
								<li className="py-6 pl-24 pr-8 text-2xl font-bold border-l-8 border-white menu-item sm:text-xl sm:pl-6 hover:bg-hgsBgLight hover:border-secondary">
									Entrons en contact
								</li>
							</Link>
						</ul>
						<div className="absolute bottom-0 right-0 flex w-48 m-10 sm:left-0 sm:m-4 sm:block">
							<img src={logo} alt="" className="w-full h-full" />
						</div>
					</div>
					<div
						id="sub-menus"
						className="relative flex flex-col w-6/12 sub-menus sm:d-none sm:w-0"
					>
						{this.state.showRWPedia ? (
							<div className="absolute top-0 w-full menu-rw-sub">
								<div
									style={{
										backgroundImage: `url(${WelcomeImage})`,
									}}
									className="relative w-full bg-center bg-no-repeat bg-cover menu-cp-sub"
								>
									<div className="relative flex flex-col justify-center w-full h-screen p-8 text-white bg-opacity-75 bg-primaryLight">
										<div className="absolute bottom-0 right-0 flex w-48 m-10">
											<img src={logo} alt="" className="w-full h-full" />
										</div>
									</div>
								</div>
							</div>
						) : (
							""
						)}
						{this.state.showCP ? (
							<div className="absolute top-0 w-full menu-cp-sub">
								<div
									style={{
										backgroundImage: `url(${CPImage})`,
									}}
									className="relative w-full bg-center bg-no-repeat bg-cover menu-cp-sub"
								>
									<div className="relative flex flex-col justify-center w-full h-screen py-8 bg-white bg-opacity-65">
										<ul
											className="inline-block list-none"
											onClick={this.closeMenu}
										>
											{cpMenus !== undefined || cpMenus !== null
												? cpMenus.map(({ id, name, route }) => (
														<Link key={id} to={route}>
															<li className="p-6 text-xl font-bold text-black menu-item hover:bg-hgsBgLight">
																{name}
															</li>
														</Link>
												  ))
												: ""}
										</ul>
										<div className="absolute bottom-0 right-0 flex w-48 m-10">
											<img src={logo} alt="" className="w-full h-full" />
										</div>
									</div>
								</div>
							</div>
						) : (
							""
						)}

						{this.state.showHGS ? (
							<div className="absolute top-0 w-full menu-hgs-sub">
								<div
									style={{
										backgroundImage: `url(${HGSImage})`,
									}}
									className="relative z-30 w-full bg-center bg-no-repeat bg-cover"
								>
									<div className="relative flex flex-col justify-center w-full h-screen py-8 text-white bg-white bg-opacity-65">
										<ul
											className="inline-block list-none"
											onClick={this.closeMenu}
										>
											{hgsMenus !== undefined || hgsMenus !== null
												? hgsMenus.map(({ id, name, route }) => (
														<Link key={id} to={route}>
															<li className="p-6 text-xl font-bold text-black menu-item hover:bg-hgsBgLight">
																{name}
															</li>
														</Link>
												  ))
												: ""}
										</ul>
										<div className="absolute bottom-0 right-0 flex w-48 m-10">
											<img src={logo} alt="" className="w-full h-full" />
										</div>
									</div>
								</div>
							</div>
						) : (
							""
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default Menu;
