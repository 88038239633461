import React, { Component, Fragment } from 'react'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { Route, Switch } from 'react-router-dom';
import HGSSideNav from '../components/HGSSideNav';
import ItoreroHowItWorks from '../components/ItoreroHowItWorks';
import ItoreroOverview from '../components/ItoreroOverview';
import PageName from '../components/PageName';
import { itoreroSubmenus } from '../utils/menus';

export class Itorero extends Component {
	render() {
		const { match } = this.props;
		return (
			<Fragment>
				<div className="relative flex flex-row w-full h-screen">
					<div className="hidden w-4/12 md:flex">
						<HGSSideNav
							hgsTitle="Itorero"
							hgsSubTitle="gouvernance et justice"
							hgsIntro={
								<p className="text-justify">
									Le programme d'éducation civique, également connu sous le nom d'Itorero, est l'une des initiatives endogènes du Rwanda (HGI). Itorero a été réintroduit en 2009 comme un moyen de reconstruire le tissu social de la nation et de mobiliser les Rwandais pour qu'ils défendent d'importantes valeurs culturelles et suscitent un sentiment de dévouement à leur pays.
								</p>
							}
							submenus={itoreroSubmenus}
						/>
					</div>
					<div className="relative flex px-4 pt-40 overflow-y-scroll bg-white md:w-8/12 lg:px-10 xl:px-24">
						<div>
							<PageName
								title="Que sont les HGI"
								color="primary"
								accentColor="secondary"
								subTitle={
									<>
										Ingando &nbsp; <HiOutlineArrowNarrowRight />
									</>
								}
								to="/hgs/ingando/overview"
							/>
						</div>
						<Switch>
							<Route exact path={`${match.path}`} component={ItoreroOverview} />
							<Route
								exact
								path={`${match.path}/overview`}
								component={ItoreroOverview}
							/>
							<Route
								exact
								path={`${match.path}/how-it-works`}
								component={ItoreroHowItWorks}
							/>
							{/* <Route
								exact
								path={`${match.path}/outcomes`}
								component={ItoreroOutcomes}
							/>
							<Route
								exact
								path={`${match.path}/participants`}
								component={ItoreroParticipants}
							/> */}
						</Switch>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default Itorero
