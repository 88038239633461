import React, { Component } from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import ReactPlayer from "react-player/lazy";
import { Link } from "react-router-dom";

export class ImihigoOverview extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Aperçu</p>
				{/* <div className="w-full my-4">
					<ReactPlayer
						width="100%"
						height="30rem"
						controls={true}
						loop={false}
						muted={true}
						url="https://www.youtube.com/watch?v=n6J6iiNDtws&ab_channel=TheGovernmentofRwanda"
					/>
				</div> */}
				<div className="my-6">
					<p className="my-4">
						<span className="italic">Imihigo</span> est le pluriel du mot en kinyarwanda Umuhigo. Ce mot dérive de la racine Higa et du verbe Guhiga, qui signifie s’engager à accomplir une action. Il vient aussi du verbe Guhiganwa, qui signifie rivaliser. L'Imihigo était une pratique culturelle précoloniale au Rwanda dans laquelle certaines personnes se fixaient des cibles ou des objectifs à atteindre dans un délai précis. C’étaient le plus souvent des chefs et des leaders. Une personne devait alors atteindre ces/ses objectifs en suivant des principes directeurs et était censée être déterminée à être inébranlable dans sa promesse de surmonter tous les défis possibles qui se présentaient.  Si les autorités n'atteignaient pas les objectifs fixés, elles perdaient la face envers leur communauté et leurs rivaux. Cependant, les opinions divergent sur la nature des Imihigo traditionnels. Certains situent cette pratique exclusivement dans le domaine guerrier lorsque durant les veillées d’armes des guerriers proclamaient publiquement devant le roi ou les commandants les exploits qu'ils allaient accomplir au combat.
					</p>
					<p className="my-4">
						En 2000, un programme de décentralisation a assigné des responsabilités nouvelles aux entités administratives (jusqu’au plus bas niveau), nécessitant alors la mise en œuvre de nouveaux mécanismes de suivi et d'évaluation. Les administrations locales étaient désormais responsables de la mise en œuvre de vastes programmes de développement, ce qui signifiait que le pays se trouvait dans l’obligation de concevoir un système de performance et de redevabilité. En 2006, le système (Imihigo) de contrats de performance a été établi par le Gouvernement pour répondre aux préoccupations concernant la rapidité et la qualité de l'exécution des programmes et des priorités du Gouvernement.
					</p>
					<p className="my-4">
						Aujourd'hui, <span className="italic">Imihigo</span> est utilisé dans toutes les institutions du Gouvernement comme système de contrats de performance visant à responsabiliser les fonctionnaires. Tous les niveaux du Gouvernement, de l’administration locale aux ministères et aux ambassades, sont tenus de développer leur Imihigo et de le faire évaluer. Les fonctionnaires signent également des Imihigo individuels avec leurs directeurs ou chefs d'institution.
					</p>
					<p className="my-4">
						Si le processus de l'<span className="italic">imihigo</span> est aujourd'hui largement utilisé dans l'ensemble du Gouvernement, il a d'abord été introduit au niveau des districts. Sur le plan administratif, chaque district du Rwanda est divisé en secteurs, chaque secteur est divisé en cellules et chaque cellule est divisée en villages. Lors de l'élaboration de l’Imihigo du district, chaque entité administrative locale (le village, la cellule et le secteur) est supervisée par le district et détermine ses objectifs spécifiques avec des indicateurs mesurables et raisonnables. Les entités administratives locales, dans l’élaboration de leur Imihigo doivent aussi tenir compte des priorités nationales définies dans les documents de politique générale. Elle doit aussi tenir compte des stratégie nationales et internationales telles que les Objectifs de Développement Durable (ODD), la Vision 2050, la Stratégie Nationale de Transformation 1 (NST1), les Stratégies de Développement du District (en anglais District development strategies - DDS) et les plans stratégiques sectoriels (SSP).
					</p>
					<p className="my-4">
						Par souci de responsabilité et de transparence, les  <span className="italic">Imihigo</span> sont présentés au public lors de la phase de planification ainsi que durant la phase de présentation des résultats obtenus. Chaque année, les maires de districts et les gouverneurs de province signent solennellement avec le Président de la République les <span className="italic">Imihigo</span> de leurs circonscriptions. Cette signature les engage à atteindre certains objectifs convenus collectivement.
					</p>
					<p className="my-4">
						Depuis son introduction, l'<span className="italic">Imihigo</span> a permis d’atteindre les résultats suivants :
					</p>
					<ul className="ml-8 hgs-ordered-list">
						<li>Améliorer la redevabilité.</li>
						<li>
							Accélérer le rythme d’exécution des activités et des programmes de développement centrés sur le citoyen.
						</li>
						<li>
							Assurer la pleine participation et l’appropriation par les citoyens des activités de développement local du fait que les priorités sont élaborées avec la participation de la population à la base de la société civile.
						</li>
					</ul>
				</div>
				<Link
					to="/hgs/imihigo/how-it-works"
					className="flex flex-row items-center justify-end py-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					Comment fonctionnent les contrats de performance Imihigo &nbsp;
					<HiOutlineArrowNarrowRight />
				</Link>
			</div>
		);
	}
}

export default ImihigoOverview;
