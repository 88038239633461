import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
	HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import image031 from "../assets/images/Abanzi2.jpg";
import hgsInfographics001 from "../assets/images/hgs-infographics/hgs-infographics001.jpg";

export class AbunziHowItWorks extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Comment fonctionne le système <span className="italic">Abunzi</span>?</p>
				<p className="my-4 text-xl font-semibold">Structure organisationnelle</p>
				<div className="">
					<p className="">
						Les <span className="italic">Abunzi</span> opèrent au niveau de la cellule en première instance et au niveau du secteur en cas de recours. Le comité des <span className="italic">Abunzi</span> est composé de sept personnes, réputées pour leur intégrité. Elles résident dans la cellule et le secteur concernés et sont reconnues pour leur capacité à concilier les différends. Ces <span className="italic">Abunzi</span> sont élus par le conseil de cellule et le conseil de secteur, pour un mandat renouvelable de cinq ans.
					</p>
					<p className="my-4">
						Le Comité des <span className="italic">Abunzi</span>, au niveau des cellules et des secteurs, est dirigé par un bureau composé d'un président et d'un vice-président élus par leurs pairs. Les litiges confiés aux <span className="italic">Abunzi</span> sont reçus par le secrétaire exécutif de la cellule, qui les transmet à son tour au Comité des <span className="italic">Abunzi</span>.
					</p>
					<div
						className="my-8"
					>
						<img src={image031} alt="" />
					</div>
					<p className="my-4 text-xl font-semibold">
						Fonctionnement des <span className="italic">Abunzi</span>
					</p>
					<p className="my-4">
						Pour engager un litige, l'une des parties doit d'abord soumettre une plainte verbale ou écrite au secrétaire exécutif de la cellule. Celle-ci est alors enregistré par le président ou le vice-président du comité de médiation. L’auteur de la plainte doit fournir un bref aperçu du litige comme base de la procédure, après quoi le président ou le vice-président du comité de médiation peut convoquer les parties et décider du lieu ainsi que de la date et l'heure de l'audition du litige.
					</p>
					<p className="my-4">
						Les parties s'accordent sur trois <span className="italic">Abunzi</span> auprès desquels les litiges sont soumis. Lorsque les parties ne s'entendent pas sur les <span className="italic">Abunzi</span>, chaque partie en choisit un, et les deux <span className="italic">Abunzi</span> choisis désignent le troisième. Les <span className="italic">Abunzi</span> doivent régler le litige dans un délai d'un mois, à compter du jour où ce dernier est enregistré.
					</p>
					<p className="my-4">
						Dans la plupart des cas, l'audience de médiation est publique, sauf décision contraire des <span className="italic">Abunzi</span>. Les autres membres du comité de médiation qui n'ont pas été choisis pour régler le litige peuvent participer à la séance de médiation, mais n'ont pas le pouvoir de décision. Lors de l’audition, les <span className="italic">Abunzi</span> entendent chacune des parties en conflit ainsi que tous les témoins disponibles. Au cours de ces audiences, les avocats sont autorisés à assister les parties, mais ils ne peuvent pas représenter ou plaider pour leurs clients.
					</p>
					<p className="my-4">
						Pour chaque litige traité, les <span className="italic">Abunzi</span> sont en premier lieu tenus de réconcilier les deux parties. Si cela s'avère impossible, ils rendent une décision conformément aux lois et aux pratiques coutumières. Si les <span className="italic">Abunzi</span> parviennent à réconcilier les parties, la conciliation s’arrête là.
					</p>
					<p className="my-4">
						Après avoir examiné le litige, les <span className="italic">Abunzi</span> se retirent pour décider. La décision des est prise par consensus ou à la majorité absolue des voix, au cas où un consensus ne peut être atteint. Lorsque la procédure de médiation est terminée, un procès-verbal de la decision de règlement est signé par les <span className="italic">Abunzi</span> et par les parties concernées. Dans tous les cas, la décision est écrite, signée sur chaque page et disponible dans les dix jours suivant le jour de la décision.
					</p>
					<div className="grid gap-8 my-4 lg:grid-cols-2">
						<div className="">
							<p className="text-2xl font-semibold">
								Faire appel des décisions des <span className="italic">Abunzi</span>
							</p>
							<p className="">
								Chaque partie peut faire appel de la décision des <span className="italic">Abunzi</span> au niveau du secteur dans un délai des trente jours à compter du jour où la décision écrite a été rendue disponible. Une fois reçue par le secrétaire exécutif du secteur, les <span className="italic">Abunzi</span> n'examineront que les aspects du litige jugés contestables par la partie qui fait appel. Il n'y a pas de frais de dossier liés à la procédure d'appel.
							</p>
						</div>
						<div className="">
							<p className="text-2xl font-semibold list-outside">
								Compétence des <span className="italic">Abunzi</span>
							</p>
							<p className="my-4">
								La compétence du système <span className="italic">Abunzi</span> en matière civile est la suivante :
							</p>
							<ol className="ml-4 text-left list-decimal hgs-ordered-list">
								<li>
									Litiges portant sur des terrains et autres biens immobiliers dont la valeur ne dépasse pas 3 000 000 FRW.
								</li>
								<li>
									Litiges concernant le bétail et autres biens mobiliers dont la valeur ne dépasse pas 1 000 000 FRW.
								</li>
								<li>
									Litiges relatifs à des violations présumées de contrats dont la valeur ne dépasse pas 1 000 000 FRW, à l'exception des obligations contractuelles du gouvernement central, des assurances et du commerce.
								</li>
								<li>
									Litiges lié à l’emploi entre particuliers, dont la valeur est inférieure à 100 000 FRW.
								</li>
								<li>
									Litiges familiaux autres que ceux liés à l'état civil.
								</li>
								<li>
									Litiges liées à la succession dont la valeur ne dépasse pas 3 000 000 FRW.
								</li>
							</ol>
						</div>
					</div>
					<p className="my-4">
						La figure ci-dessous montre que les questions foncières (qui constituent 47% des litiges soumis aux <span className="italic">Abunzi</span>) représentent la part la plus importante des plaintes, tandis que les litiges relatifs au déplacement des limites des terres représentent 39% des cas. Cela souligne l'ampleur de ces types de conflits au sein des communautés et confirme la nécessité d'un système qui permette de les régler au plus près des populations concernées, et en dehors du système judiciaire officiel lorsque cela est possible.
					</p>
					<p className="text-xl font-semibold">
						Catégories de demandes présentées aux <span className="italic">Abunzi</span>
					</p>
					<div>
						<div className="border-b-4 border-secondary">
							<img src={hgsInfographics001} alt="" className="" />
						</div>
						<p className="font-semibold text-right text-secondary">
							Source : Enquête sur la performance des médiateurs communautaires
						</p>
					</div>
				</div>
				<Link
					to="/hgs/abunzi/performance"
					className="flex flex-row items-center justify-end pt-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<p>Evaluation de l'impact des <span className="italic">Abunzi</span></p> &nbsp; <HiOutlineArrowNarrowRight />
				</Link>
				<Link
					to="/hgs/abunzi/conflict"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; Aperçu
				</Link>
			</div>
		);
	}
}

export default AbunziHowItWorks;
