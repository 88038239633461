import React, { Component } from 'react'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';

export class GirinkaOverview extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Aperçu</p>
				<div className="w-full my-4">
					<ReactPlayer
						width="100%"
						height="30rem"
						controls={true}
						loop={false}
						muted={true}
						url="https://youtu.be/0yaUEcgzzmg"
					/>
				</div>
				<div className="my-6">
					<p className="my-4">
						Dans le processus de reconstruction du pays et de promotion de l’identité nationale commune, le Gouvernement s'est appuyé sur des pratiques culturelles traditionnelles pour adapter ses programmes de développement aux besoins et au contexte du pays. En a résulté la constitution d’un ensemble d’Initiatives Endogènes. Ce sont des pratiques culturelles anciennes remises à jour et adaptées aux programmes de développement actuels. L'une d'entre elles est « Girinka » (une vache par famille pauvre).
					</p>
					<p className="my-4">
						Le rapport de l'Enquête Santé et Démographie de 2005 avait montré que 45% des enfants de moins de cinq ans souffraient de malnutrition chronique modérée et 19% de malnutrition sévère. Presque aucun progrès n'avait été réalisé depuis l'enquête précédente en 2000. En outre, l'enquête sur les conditions de vie des ménages de 2005-2006 avait révélé une augmentation de la pauvreté depuis 2001. Ces résultats ont provoqué un choc, compte tenu du fait que le pays s’était engagé dans une dynamique continue de développement socio-économique, impulsée en 2000 par le programme Vision 2020 et la stratégie de réduction de la pauvreté du Rwanda de 2002 (Poverty Reduction Strategy Paper - PRSP), marquant ainsi le début effectif du processus de reconstruction socio-économique post-génocide.
					</p>
					<p className="my-4">
						Lors de l'édition de 2006 d’Umushyikirano (Conseil national de dialogue), les dirigeants ont discuté de cette inquiétante situation socio-économique. Ils ont décidé d'adopter une politique de distribution de vaches laitières aux ménages pauvres, ceci afin de lutter contre la malnutrition des enfants. Le programme a ensuite été développé par l'Agence Rwandaise de l'Agriculture (Rwanda Agriculture Board - RAB) et baptisé Girinka, traduisible par « Que vous ayez des vaches ». En kinyarwanda, le nom du programme est également un mode de salutation par lequel une personne souhaite la prospérité à une autre. L'idée de distribution de vaches aux ménages pauvres visait à répondre au problème de la malnutrition infantile. Cette initiative s'est inspirée de la pratique traditionnelle rwandaise qui consiste à offrir une vache en signe d'amitié, de reconnaissance ou comme dot de mariage. Naturellement, l’initiative Girinka a rejoint d'autres HGI existantes.
					</p>
					<p className="my-4">
						En juin 2020, plus de 380 000 familles, soit plus de 1,2 million de Rwandais, ont reçu une vache dans le cadre du programme Girinka. Ce dernier a contribué à l'augmentation de la production de produits laitiers, ce qui a par la suite permis de réduire la malnutrition et d'augmenter les revenus des bénéficiaires.
					</p>
				</div>
				<Link
					to="/hgs/girinka/how-it-works"
					className="flex flex-row items-center justify-end py-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					Comment fonctionne Girinka? &nbsp;
					<HiOutlineArrowNarrowRight />
				</Link>
			</div>
		);
	}
}

export default GirinkaOverview
