import React, { Component } from "react";

export class Today extends Component {
	render() {
		return (
			<div className="flex flex-col">
				<div className="">
					<div className="">
						<div className="">
							<p>
								Aujourd'hui, l'ordre constitutionnel du Rwanda consacre les principes d'une démocratie basée sur un système de partage du pouvoir et de recherche du consensus. Elle a les caractéristiques administratives et institutionnelles suivantes :
							</p>
							<div className="p-8 my-4 bg-yellow-100 border">
								<ul className="mx-8 overview-list">
									<li>
										Une formation politique majoritaire à la Chambre des députés ne doit pas occuper plus de 50 % des postes au sein du cabinet.
									</li>
									<li>
										Le Président de la République et le Président de la Chambre des Députés doivent être issus d'organisations politiques différentes.
									</li>
									<li>
										Au moins 30% des sièges de la Chambre et du Sénat doivent être réservés aux femmes et le Sénat doit refléter la diversité nationale.
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Today;
