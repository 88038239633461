import React, { Component } from "react";
// import rwPediaLogoBlack from "../assets/images/RWandaPedia Logo_Edit-06.png";
import rwPediaLogoWhite from "../assets/images/Rwandapedia_Logo_white.svg";
import NavigationDots from "../components/NavigationDots";
import MobileFooter from "../components/MobileFooter";
// import HomeContactImage from "../assets/images/image76.jpeg";
import homeContact from "../assets/images/homeContact.jpg";
import GoUp from "./GoUp";

export class HomeContactUs extends Component {
	render() {
		return (
			<div className="">
				<section className="home-section h-screen w-full bg-primary text-white sm:hv-8">
					<div
						className="home-background bg-cover bg-no-repeat bg-center"
						style={{
							backgroundImage: `url(${homeContact})`,
						}}
					></div>
					<div className="home-contact-gradient absolute w-full h-full z-10"></div>
					<div className="sm:w-full sm:px-4 home-left flex flex-col md:px-8 py-8 h-full">
						<div className="flex flex-row h-full items-center">
							<div className="home-intro-content pr-20">
								<div className="home-section-logo flex md:w-48 py-4 ">
									<img src={rwPediaLogoWhite} alt="" className="w-full h-full" />
								</div>
								<div className="contact-form flex flex-col text-md">
									<div className="home-title sm:text-2xl md:text-3xl xl:text-4xl font-bold">
										<p className="">
											Entrons en contact
										</p>
									</div>
									<form className="max-w-md flex flex-col">
										<input
											type="text"
											className="px-4 py-1 my-1 border font-semibold border-white bg-white bg-opacity-10"
											placeholder="Nom"
										/>
										<input
											type="text"
											className="px-4 py-1 my-1 border font-semibold border-white bg-white bg-opacity-10"
											placeholder="Adresse email"
										/>
										<textarea
											className="px-4 py-1 my-1 border font-semibold border-white bg-white bg-opacity-10"
											placeholder="Message"
										/>
										<div className="">
											<button className="sm:btn lg:px-8 md:px-3 lg:py-4 md:py-2 my-4 text-black hover:text-white bg-primary border-none hover:bg-secondary font-bold border-2 w-full">
												Envoyer le message
											</button>
										</div>
									</form>
								</div>
							</div>
							<div className="home-scroll-controls flex flex-col h-full w-auto justify-center items-center">
								<NavigationDots active={5} />
							</div>
						</div>
						<GoUp to="home-5" />
					</div>
				</section>
				<MobileFooter />
			</div>
		);
	}
}

export default HomeContactUs;
