import React, { Component } from "react";
import info1 from "../assets/images/cp-infographics/cp-infographics009.jpg";
import infographics036 from "../assets/images/cp-infographics/cp-infographics036.png";

export class Economy2 extends Component {
	render() {
		return (
			<div className="flex flex-col">
				<div className="my-4">
					<p>
						Depuis 2003, le PNB par habitant du Rwanda a quadruplé, rompant ainsi avec l'extrême pauvreté que le pays a connu historiquement, comme le montrent les données ci-dessous.
					</p>
					<p className="my-4"> PNB par habitant (constant 2010 USD)</p>
					<div className="my-4">
						<img src={info1} alt="" className="w-full" />
					</div>
					<p>Source: Indicateurs de la Banque Mondiale</p>
				</div>
				<div className="my-4">
					<p className="my-4 font-semibold">Développement sectoriel</p>
					<p>
						Au cours des deux dernières décennies, l'économie rwandaise s'est diversifiée, réduisant ainsi considérablement la part de l'agriculture et augmentant la part du secteur des services. Selon la Banque Mondiale, la croissance économique s'est élevée en moyenne à 7,5 % au cours de la décennie et ce jusqu'en 2018. Cela a été le deuxième taux de croissance le plus rapide en Afrique pendant cette période. Dans le même temps, le produit intérieur brut a augmenté de 5 % par an.
					</p>
					<p>PIB du Rwanda par secteur, 2019</p>
					<div style={{ height: "" }} className="flex justify-center">
						<img src={infographics036} alt="" className="" style={{ height: "32rem" }} />
					</div>
				</div>
			</div>
		);
	}
}

export default Economy2;
