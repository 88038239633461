import React, { Component } from "react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { Link } from "react-router-dom";
import contribution from "../assets/images/hgs-infographics/INFOGRAPHIC_fr-17.jpg";
import level_of_participant from "../assets/images/hgs-infographics/INFOGRAPHICS-01.jpg";
import image045 from "../assets/images/Umuganda3.jpg";
import INFO_fr2 from "../assets/images/INFO_fr2-01.jpg";

export class UmugandaImpact extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">L'impact d'<span className="italic">Umuganda</span></p>
				<div className="">
					<p className="my-4">
						L'<span className="italic">Umuganda</span> contribue de façon essentielle au développement du Rwanda, en particulier dans les domaines du développement des infrastructures, de la protection de l'environnement et d’une bonne gouvernance.
					</p>
					<p>
						Les projets d'infrastructure courants comprennent des routes (en particulier celles qui relient les secteurs), des ponts, des centres de santé, la construction de salles de classe (pour soutenir les programmes d'éducation de base des 9 et 12 ans), la construction de logements pour les rwandais pauvres et vulnérables (souvent pour remplacer les huttes en paille), la construction de bureaux pour l’administration locale et des locaux pour les coopératives d'épargne et de crédit.
					</p>
					<div className="">
						<div className="border-b-4 border-secondary">
							<img src={contribution} alt="" style={{ width: "100%" }} />
						</div>
						<p className="font-semibold text-right text-secondary">
							RGB, the Assessment of the impact of Home Grown initiatives,
							Kigali, 2014
						</p>
					</div>
					<p className="my-4">
						Les projets de protection de l'environnement entrepris dans le cadre de l'<span className="italic">Umuganda</span> comprennent la plantation d'arbres et la construction de terrasses pour lutter contre l'érosion, la réhabilitation des zones humides, la construction de petites unités d'énergie renouvelable et la plantation de cultures.
					</p>
					<p className="my-4">
						L'impact de l'<span className="italic">Umuganda</span> sur la gouvernance est varié et comprend, selon une enquête menée en 2014 par l’Agence de Gouvernance du Rwanda, le renforcement de la cohésion sociale entre personnes de différents milieux et l'augmentation d'opportunités pour les membres de la communauté d'exprimer leurs besoins et leurs opinions sur diverses questions. L’<span className="italic">Umuganda</span> contribue au renforcement de l'unité et à l’affirmation de la réconciliation en offrant une plateforme pour l'arbitrage des conflits entre les membres de la communauté.  En participant à l’<span className="italic">Umuganda</span>, les membres du voisinage apprennent à se connaître et à nouer des amitiés. L’<span className="italic">Umuganda</span> occasionne des activités d'assistance mutuelle dans la communauté et renforce le dialogue entre les autorités locales aussi bien que nationales et la population.
					</p>
					<p className="my-4">
						Le Président de la République du Rwanda, les membres du Parlement et les ministres participent chaque mois à l’<span className="italic">Umuganda</span> en travaillant côte à côte avec les membres de la communauté à laquelle ils sont venus se joindre.
					</p>
					<p className="my-4">
						Le tableau ci-dessous montre que la participation à l'<span className="italic">Umuganda</span> est passée de 78% en 2010/2011 à 91% en 2014/2015 et est restée supérieure à 90% jusqu'en 2019. Le Tableau 3 indique que la valeur monétaire des activités de l'<span className="italic">Umuganda</span> a débuté à 7,3 milliards de FRW en 2010/2011 et a augmenté chaque année, pour atteindre 22,8 milliards de FRW en 2018/2019.
					</p>
					<div className="my-4 font-semibold border-b-4 border-secondary text-primary">
						<div className="">
							<p className="my-2 font-bold text-black">Niveau de participation au programme Umuganda</p>
							<img src={level_of_participant} alt="" style={{ width: "100%" }} />
						</div>
						<div className="">
							<p className="my-2 font-bold text-black">Valeur monétaire du programme Umuganda</p>
							<img src={INFO_fr2} alt="" style={{ width: "100%" }} />
						</div>
						{/* <div className="border-b-4 border-secondary">
							<img src={level_of_participant} alt="" style={{ width: "100%" }} />
						</div> */}

					</div>
					<div className="my-4">
						<img src={image045} alt="" />
					</div>
				</div>
				<Link
					to="/hgs/umuganda/how-it-works"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; <p>Comment <span className="italic">Umuganda</span> est-il organisé?</p>
				</Link>
			</div>
		);
	}
}

export default UmugandaImpact;
