import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import vision from "../assets/images/hgs-infographics/imihigo_vision.jpg";
import district_scoring from "../assets/images/hgs-infographics/Imihigo_district_scoring.jpg";
import nationa_scoring from "../assets/images/hgs-infographics/Designs_Fr-09.jpg"
import Imihigo from "../assets/images/Imihigo1.jpg"

export class ImihigoHowItWorks extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">
					Comment fonctionnent les contrats de performance <span className="italic">Imihigo</span>
				</p>
				<div className="">
					<img src={Imihigo} alt="" className="w-full" />
				</div>
				<div className="">
					<p className="my-4 font-semibold">
						Principes et objectifs
					</p>
					<p className="my-4 font-semibold">Principes</p>
					<p className="my-4">
						L'<span className="italic">Imihigo</span> est régi par les principes suivants :
					</p>
					<ul className="ml-8 hgs-ordered-list">
						<li>
							<span className="font-semibold">L’ancrage </span> local : chaque district décide de la teneur de son <span className="italic">Imihigo</span> tout en veillant à son alignement avec les priorités nationales.
						</li>
						<li>
							<span className="font-semibold">L'ambition </span> : les activités incluses dans les <span className="italic">Imihigo</span> doivent apporter quelque chose de nouveau et de concret en dehors de l’entretien des activités existantes.
						</li>
						<li>
							<span className="font-semibold">L'excellence </span>: <span className="italic">Imihigo</span> vise à réaliser des performances exceptionnelles qui méritent d'être saluées.
						</li>
					</ul>
					<p className="my-4 font-semibold">Objectifs de l'<span className="italic">Imihigo</span></p>
					<p className="my-4"> L'<span className="italic">Imihigo</span> a les objectifs suivants :</p>
					<ul className="ml-8 hgs-ordered-list">
						<li>
							<span className="font-semibold">Accélérer </span>la mise en œuvre de l'agenda de développement local et national.
						</li>
						<li><span className="font-semibold">Assurer </span> l'appropriation du programme de développement par les parties prenantes.</li>
						<li><span className="font-semibold">Promouvoir </span> la redevabilité et la transparence</li>
						<li><span className="font-semibold">Promouvoir </span> des performances axées sur les résultats.</li>
						<li><span className="font-semibold">Encourager </span> l'innovation et la compétitivité.</li>
						<li><span className="font-semibold">Engager </span> les parties prenantes (citoyens, société civile, donateurs, secteur privé, etc.) dans la formulation et l'évaluation des politiques.</li>
						<li><span className="font-semibold">Promouvoir  </span> le zèle et la détermination à atteindre les objectifs fixés.</li>
						<li><span className="font-semibold">Instaurer  </span> une culture d'évaluation régulière des performances.</li>
					</ul>
					<p className="my-4 font-semibold">Le processus de préparation de L'<span className="italic">Imihigo</span></p>
					<p className="my-4">
						Le Gouvernement du Rwanda utilise à la fois le processus de <span className="italic">Imihigo</span> et les plans d'action pour définir les objectifs à atteindre. Bien que différents dans leur but, les deux outils sont liés. Les plans d'action sont un ensemble d'activités à réaliser dans un délai déterminé, généralement une année. Les <span className="italic">Imihigo</span> sont des sous-ensembles de plans d'action, leurs feuilles de route sont chiffrées, indiquant les activités prioritaires à utiliser comme mesure de performance.
					</p>
					<p className="my-4">
						Un plan d'action peut contenir un nombre certain d'activités courantes et routinières, comme par exemple le paiement des salaires. Sur un autre plan, les <span className="italic">Imihigo</span> définissent des objectifs qui ont un impact significatif sur le développement économique, la réduction de la pauvreté, la bonne gouvernance et le bien-être social.
					</p>
					<p className="my-4">
						Lors de l'élaboration des <span className="italic">Imihigo</span>, il est conseillé aux responsables des entités locales de répondre à un certain nombre de questions clés avant d'établir une liste d’activités. Les activités sont classées par ordre de priorité, selon les critères suivants :
					</p>
					<ul className="ml-8 hgs-ordered-list">
						<li>
							L'activité aura-t-elle un effet positif sur le bien-être de la population locale (par exemple en améliorant l'accès à l'eau, aux transports, à l'énergie, aux écoles, etc.) ?
						</li>
						<li>L'activité créera-t-elle des emplois au sein de la population locale ?</li>
						<li>
							L'activité créera-t-elle des occasions de générer des revenus pour la population/le gouvernement local ?
						</li>
						<li>L'activité aura-t-elle un impact sur la réduction de la pauvreté ?</li>
						<li>
							L'activité sera-t-elle une priorité pour les habitants de la région ?
						</li>
						<li>
							L'activité aura-t-elle une synergie avec le développement d'autres zones (une activité peut avoir un impact potentiel sur le développement des zones voisines) ?
						</li>
						<li>
							Les résultats escomptés seront-ils durables ?
						</li>
						<li>
							La population locale aura-t-elle l’occasion de s’approprier l'activité ?
						</li>
						<li>
							La population contribuera-t-elle à la réalisation des objectifs nationaux et sera-t-elle liée aux priorités, programmes ou politiques nationaux et internationaux (ODD, NST1, Vision 2050) ?
						</li>
						<li>
							L'activité peut-elle produire des résultats ou des réalisations de qualité avec un minimum de ressources ?
						</li>
						<li>
							L'activité peut-elle améliorer la manière dont les services sont fournis ou peut-elle réduire les coûts ?
						</li>
						<li>
							L'activité va-t-elle promouvoir la cohésion sociale (unité et réconciliation) ?
						</li>
						<li>
							L'activité va-t-elle réduire les troubles ou les désordres sociaux (par exemple, l'insécurité, la toxicomanie, la prostitution, la dégradation de l'environnement, les conflits, la corruption, etc.) ?
						</li>
						<li>
							L'activité portera-t-elle sur des questions transversales clés (genre, VIH/sida, environnement, inclusion sociale et jeunesse) ?
						</li>
						<li>La source des fonds pour la mise en œuvre a-t-elle été déterminée ?</li>
						<li>L'activité est-elle réaliste et réalisable ?</li>
					</ul>
					<p className="my-4 font-semibold">
						Étape 1 : identification des priorités nationales par le Gouvernement central
					</p>
					<p className="my-4">
						Dans la première étape du processus de préparation de l'<span className="italic">Imihigo</span>, chaque ministère identifie les priorités nationales à mettre en œuvre au niveau local, pour lesquelles il affecte des ressources à transférer aux entités locales. Le processus de consultation porte sur les politiques et programmes suivants :
					</p>
					<ul className="ml-8 hgs-list">
						<li>Vision 2020/2050.</li>
						<li>NST1</li>
						<li>
							Résolutions de la retraite des dirigeants au niveau national et du dialogue national.
						</li>
						<li>Résolutions du conseil des ministres.</li>
						<li>Cadre de Dépenses à Moyen Terme sur trois ans (CDMT).</li>
						<li>Stratégies de Développement du District (DDS).</li>
						<li>Objectifs de Développement Durable (ODD).</li>
					</ul>
					<p className="my-4">
						Lorsque les activités à inclure dans les <span className="italic">Imihigo</span> n’ont pas de ressources pré-affectées, les ministères compétents déterminent comment les ressources, financières ou non financières, peuvent être mobilisées (aux niveaux national et local).
					</p>
					<p className="my-4">
						Le Gouvernement central consolide les priorités incluses dans les  <span className="italic">Imihigo</span>, en accordant une attention particulière aux domaines présentant un potentiel de gains rapides et de synergie, tout en évitant les doubles emplois.
					</p>
					<p className="my-4 font-semibold">
						Étape 2 : communication des priorités nationales aux autorités locales
					</p>
					<p className="my-4">
						Dans un deuxième temps, la liste des priorités du Gouvernement central est communiquée et discutée avec les dirigeants des entités administratives locales lors d'un forum des dirigeants des gouvernements central et locaux.
					</p>
					<p className="my-4 font-semibold">
						Étape 3 : identification des priorités locales
					</p>
					<p className="my-4">
						Lors de la troisième étape, les chefs de district consultent leur DDS. Des réunions consultatives sont organisées avec les différentes parties prenantes au niveau de la province, ainsi qu'au niveau du district, du secteur, de la cellule et du village afin de discuter et consolider les nouvelles priorités.
					</p>
					<p className="my-4 font-semibold">Étape 4 : préparation et approbation</p>
					<p className="my-4">
						La quatrième étape concerne la préparation et l'approbation des contrats <span className="italic">Imihigo</span> et se déroule comme suit :
					</p>
					<ul className="ml-8 hgs-list">
						<li>
							Les districts consultent leur DDS respectifs et leurs priorités nationales telles que communiquées lors du forum ou de la réunion entre le gouvernement central et local.
						</li>
						<li>
							Les priorités locales et nationales sont consolidées au niveau du district.
						</li>
						<li>
							Pour les districts et les provinces, le projet est discuté avec une équipe technique d'assurance qualité du Ministère de l'Administration Locale (MINALOC) et du Ministère des Finances et de la Planification Économique (MINECOFIN).
						</li>
						<li>Les priorités sont présentées aux parties prenantes.</li>
						<li>Les priorités sont ensuite approuvées.</li>
					</ul>
					<p className="my-4">
						L'équipe technique d'assurance qualité a été mise en place pour aider les districts et les provinces, et la ville de Kigali, à préparer des <span className="italic">Imihigo</span> techniquement solides et qui répondent aux objectifs nationaux. L'équipe technique d'assurance qualité est composée de membres de l'équipe d'évaluation de l'Imihigo du Bureau du Premier ministre (PMO), du Ministère de l'Administration Locale (MINALOC) et du Ministère des Finances et de la Planification Économique (MINECOFIN) ainsi que de tous les ministères sectoriels qui font partie de la décentralisation.
					</p>
					<p className="my-4">
						Cette équipe donne régulièrement des commentaires et des informations en retour aux planificateurs du district pendant le processus de préparation de l'<span className="italic">Imihigo</span>. Les responsables de district sont invités à préparer des plans réalistes qui tiennent compte du coût de la prestation des services et qui s'alignent sur les ressources disponibles. Pour s'assurer qu'un suivi et une évaluation appropriés peuvent être effectués, les objectifs, leurs indicateurs et les résultats escomptés doivent être clairement identifiés pendant le processus de planification.
					</p>
					<p className="my-4">
						Lors de l'élaboration de  l'<span className="italic">Imihigo</span>, il est demandé aux dirigeants de prendre en compte les questions transversales telles que le genre, le VIH/sida, l'environnement, l'inclusion sociale et la jeunesse. Les décideurs sont encouragés à discuter de ces questions et à impliquer les parties prenantes concernées pour s'assurer que leur <span className="italic">Imihigo</span> intègre ces aspects du développement.
					</p>
					<p className="my-4 font-semibold">
						Lien entre les plans des districts et la planification et la budgétisation nationales
					</p>
					<p className="my-4">
						Les principaux instruments de planification actuellement utilisés au Rwanda sont la stratégie Vision 2050 et la NST1. Elles définissent les objectifs nationaux et les stratégies de transformation socio-économique du Gouvernement. La NST1 s'appuie sur les précédentes stratégies de développement à moyen terme en tenant compte des leçons tirées et des succès obtenus jusqu'à présent, ainsi que des défis rencontrés précédemment. Il s'agit de l'instrument de planification chargé de permettre le processus de transformation vers la réalisation des aspirations de la Vision 2050.
					</p>
					<p className="my-4">
						La rédaction de la NST1 a été guidée par les considérations suivantes :
					</p>
					<ul className="ml-8 hgs-list">
						<li>
							<span className="font-semibold">Déterminer</span> les bases de la mise en œuvre de la Vision 2050 et supprimer les obstacles à la réalisation des objectifs à long terme de la Vision 2050.
						</li>
						<li>
							<span className="font-semibold">Adopter</span> et augmenter l’échelle des initiatives endogènes (Home Grown Initiatives – HGI) en s’inspirant de la culture et des valeurs rwandaises et en se basant sur le contexte de développement unique du pays.
						</li>
						<li>
							<span className="font-semibold">Poursuivre</span> le développement du secteur privé en tant que moteur de la croissance économique.
						</li>
						<li><span className="font-semibold">Garantir</span> la durabilité pour des résultats à long terme.</li>
						<li>
							<span className="font-semibold">Garantir</span> l'inclusion de tous et en bénéficier sans laisser personne derrière.
						</li>
						<li>
							<span className="font-semibold">Atteindre</span> les objectifs inachevés des politiques et stratégies précédentes : l’EDPRS 2 et la Vision 2020
						</li>
					</ul>
					<p className="my-4">
						Le cadre stratégique de la mise en œuvre et de la coordination des <span className="italic">Imihigo</span> est présenté dans la figure ci-dessus
					</p>
					<div>
						<img src={vision} alt="" style={{ width: "100%" }} />
					</div>
					<p className="my-4">
						Le rôle du gouvernement central est de veiller à ce que les priorités nationales soient reflétées dans les budgets des collectivités locales par l'allocation et le transfert de subventions pré-affectées et par la préparation de lignes directrices à l'intention des collectivités locales en ce qui concerne la fourniture de services. En outre, les ministères centraux ont un rôle important à jouer dans le suivi et le soutien des entités locales dans la mise en œuvre des programmes et des services.
					</p>
					<p className="my-4 font-semibold">
						Processus de suivi et d'évaluation
					</p>
					<p className="my-4">
						Depuis 2006, l'<span className="italic">Imihigo</span> est utilisé par les autorités locales au niveau du district pour la planification et la mise en œuvre de programmes nationaux basés sur les priorités du district.
					</p>
					<p className="my-4">
						Après le succès du programme <span className="italic">Imihigo</span> au niveau des districts, le Rwanda a en 2009 étendu le programme <span className="italic">Imihigo</span> en amont et en aval de l’échelle administrative afin de couvrir la quasi-totalité du secteur public. Au-dessous du niveau du district, les administrateurs des cellules et des villages ont été inclus dans le programme Imihigo. Sur le plan administratif, chaque district du Rwanda est divisé en secteurs, chaque secteur est divisé en cellules et chaque cellule est divisée en villages. Au-dessus du niveau du district, 18 ministères, 3 conseils, le Conseil de développement du Rwanda (RDB), le Conseil de Gouvernance du Rwanda (RGB), le Conseil des Mines, du Pétrole et du Gaz du Rwanda (RMPGB) et la ville de Kigali ont été inclus au programme Imihigo.
					</p>
					<p className="my-4">
						Entre 2006 et 2018, le processus de suivi et d'évaluation consistait pour les entites locales à documenter toutes les activités et dépenses liées à leur <span className="italic">Imihigo</span> respectif afin que le Gouvernement central puisse suivre les progrès réalisés. De plus, les maires devaient soumettre des rapports à mi-parcours expliquant en détail les progrès réalisés au milieu de l'année.
					</p>
					<p className="my-4">
						À la fin de chaque exercice financier, le Gouvernement organisait deux équipes d'évaluation représentant les institutions suivantes :
					</p>
					<ul className="ml-8 hgs-list">
						<li>le Ministère de l'Administration locale ; </li>
						<li>la Primature ;</li>
						<li>le Ministère des Finances et de la Planification Économique ;</li>
						<li>l'Association Rwandaise des Autorités Locales ;</li>
						<li>le secrétariat de mise en œuvre ;</li>
						<li>les organisations de la société civile.</li>
					</ul>
					<p className="my-4">
						Ces deux équipes se déplaçaient dans tout le pays et visitaient ainsi les 30 districts pour y effectuer des évaluations s’étalant sur deux jours et au cours desquelles étaient rassemblés les rapports d'activité du gouvernement des districts et les documents justificatifs des audits. En cas de divergences ou de retards de mise en œuvre, les équipes d'évaluation demandaient aux maires d'en justifier les raisons.
					</p>
					<p className="my-4">
						Enfin, les deux équipes sélectionnaient certains objectifs des <span className="italic">Imihigo</span> du district et les vérifiaient sur le terrain. Par exemple, si un objectif des Imihigo du district prévoyait de construire une nouvelle école de 50 salles de classe, l'équipe d'évaluation visitait l'école, comptait les salles de classe et vérifiait si elles répondaient aux autres spécifications définies dans les objectif des Imihigo.
					</p>
					<p className="my-4 font-semibold">
						L'Institut National de la Statistique du Rwanda (INSR) en charge de l'évaluation (2018)
					</p>
					<p className="my-4">
						Entre 2014 et 2018, l'Institut d'Analyse et de Recherche Politique (IPAR- un groupe de réflexion rwandais) a dirigé une évaluation de l'initiative Imihigo. La méthodologie d'évaluation révisée par la suite a introduit différentes pondérations attribuées aux indicateurs de développement économique, de développement social et de gouvernance responsable. A par ailleurs été pris en compte la participation et la satisfaction des citoyens dans les notes globales.
					</p>
					<p className="my-4">
						Depuis 2018, pour approfondir les liens entre l’Imihigo et la transformation des moyens de subsistance des rwandais, le Bureau la Primature a chargé l’Institut National de la Statistique (INSR) de prendre en charge l’évaluation des Imihigo. Cela a permis d'affiner la méthodologie d'évaluation pour tenir compte des aspects nouvellement introduits.
					</p>
					<p className="my-4">
						L'INSR devait alors adopter une approche d’évaluation plus rigoureuse et fondée sur des données probantes et judicieusement calibrées.
					</p>
					<p className="my-4">
						Le regroupement d'activités administratives de routine et des actions plus impactantes à travers Imihigo avait créé un problème. Les processus administratifs tels que la rédaction de lettres et de rapports officiels, qui était prise en compte dans l'évaluation finale, se deroulaient parfois au détriment d'activités ayant un impact plus important comme l'achèvement de la construction de salles de classe. L'évaluation de l'utilisation du temps et de la qualité de l'achèvement des activités était également un exercice problématique. Enfin, le rattachement des <span className="italic">Imihigo</span> à des entités individuelles a eu pour effet de réduire l'attention que les institutions accordaient aux résultats dont elles étaient responsables dans le cadre de ces contrats de performance. Cela a créé un décalage dans l'attention et la responsabilité vis-à-vis des résultats stratégiques clés au niveau national qui ne pouvaient alors pas être assignés à une seule institution mais qui avaient un plus grand impact sur l'agenda transformationnel du Rwanda. Pour répondre à ces défis et sous l’impulsion de l'INSR, la planification des <span className="italic">Imihigo</span> se concentre dorénavant sur les programmes et les projets transformateurs en usant d’indicateurs SMART (Spécifiques, Mesurables, Atteignables, Réalistes et Temporellement définis).
					</p>
					<p className="my-4">
						<span className="font-semibold">Objectifs SMART:</span> Selon l'approche d'évaluation de l'INSR, les Imihigo doivent se concentrer sur les programmes et projets nationaux clés qui sont de véritables transformateurs et respectueux de l’idéologie SMART. Les autres projets et programmes de routine sont considérés et évalués dans le cadre des plans d'action. De surcroît, les activités figurant à la fois dans les <span className="italic">Imihigo</span> et les plans d'action seront évaluées et leur part respective doit être reflétée dans le score global. En ce qui concerne la mise en œuvre, l'accent sera mis 1) sur la rapidité d'exécution et 2) sur la qualité des projets mis en œuvre.
					</p>
					<p className="my-4">
						<span className="font-semibold">
							<span className="italic">Imihigo</span> conjoints et individuels :{" "}
						</span>
						Les <span className="italic">Imihigo</span> ont été divisés en deux catégories : les <span className="italic">Imihigo</span> conjoints et les <span className="italic">Imihigo</span> individuels. Les <span className="italic">Imihigo</span> conjoints sont spécifiques à un secteur et sont établis et mis en œuvre dans le cadre d'un partenariat entre les ministères et les districts pour réaliser certains projets. Dans certains cas, ce partenariat peut impliquer le secteur privé. Les projets mis en œuvre à travers d'Imihigo conjoints concernent principalement les secteurs de l'agriculture, de l'urbanisation et des établissments humains, de la protection sociale, de la prestation de services, de l'énergie, de la création d'emplois et de l'exportation. Quant aux <span className="italic">Imihigo</span> individuels, ce sont ceux qui sont établis et mis en œuvre par une seule entité gouvernementale.
					</p>
					<p className="my-4">
						<span className="font-semibold"><span className="italic">Imihigo</span> de production et des résultats: </span>
						Lors de la retraite nationale des dirigeants de 2014, il a été décidé de renforcer la spécialisation des <span className="italic">Imihigo</span> individuels et institutionnels sur les résultats plutôt que sur les produits. Les <span className="italic">Imihigo</span> de « production » se concentrent sur les produits physiques ou les projets qui ne peuvent produire des résultats ou un impact qu'une fois achevés. La plupart des produits peuvent être vérifiés et comptés physiquement, et ne peuvent être utilisés que si les <span className="italic">Imihigo</span> ont été complétés. Les salles de classe, les centres de santé et les marchés en sont des exemples. Si ces installations sont achevées, elles obtiennent 100 points de pourcentage. Si elles ne sont pas terminées à temps, elles n'obtiennent aucun point de pourcentage lors des exercices d'évaluation.
					</p>
					<p className="my-4">
						D'autre part, les résultats <span className="italic">Imihigo</span> se rapportent aussi aux activités dont les résultats peuvent apporter une valeur ajoutée durant leur processus de mise en œuvre. Citons, par exemple, l'accès à l'électricité et à l'assurance maladie pour lesquels même si l’ensemble de la population visée n’est pas encore atteint, celles qui le sont bénéficient de l’activité en question. Lors des exercices d'évaluation, ces activités sont notées sur base des résultats obtenus jusqu'alors.
					</p>
					<div className="my-4 font-semibold text-primary">
						<img src={nationa_scoring} alt="" style={{ width: "100%" }} />
					</div>
					<div className="my-4 font-semibold text-primary">
						<img src={district_scoring} alt="" style={{ width: "100%" }} />
					</div>
					<p className="my-4 font-semibold">L’élargissement de l’évaluation des <span className="italic">Imihigo</span></p>
					<p className="my-4">
						Avant 2009, Les <span className="italic">Imihigo</span> n'étaient appliqués qu'au niveau du district, mais aujourd'hui ils se sont étendus à d'autres niveaux :
					</p>
					<ul className="ml-8 hgs-list">
						<li>
							Les 30 districts du pays, la ville de Kigali, 19 ministères et 3 conseils (institutions parapubliques) sont évalués chaque année par l'INRS
						</li>
						<li>
							Aux niveaux des villages et des cellules, inférieurs au secteur dans l’administration locale, les <span className="italic">Imihigo</span> sont gérés par le district, qui se charge de la planification, de la rédaction des rapports, et des directives d'évaluation, ainsi que d'établir les échéances en concordance avec les échelons supérieurs du programme des Imihigo.
						</li>
					</ul>
					<div className="overflow-x-auto">
						<p className="my-4 font-semibold">
							Entités chargées de la préparation, du suivi et de l'évaluation des <span className="italic">Imihigo</span>
						</p>
						<div className="w-full overflow-x-auto">
							<table className="w-full text-left cp-table">
								<tr>
									<th className="text-center border">Niveau</th>
									<th className="text-center border">Préparation</th>
									<th className="text-center border">Suivi</th>
									<th className="text-center border">Évaluation</th>
								</tr>
								<tr>
									<td>Village</td>
									<td>Comité exécutif du village</td>
									<td>Comité exécutif du village</td>
									<td>
										Conseil exécutif du village et secrétaire exécutif de la cellule
									</td>
								</tr>
								<tr>
									<td>Cellule</td>
									<td>Secrétariat exécutif de la cellule</td>
									<td>Secrétariat exécutif de la cellule</td>
									<td>Conseil de la cellule et Secrétaire exécutif du secteur</td>
								</tr>
								<tr>
									<td>Secteur</td>
									<td>
										Le Comité de développement communautaire et le Secrétaire exécutif du secteur s’accordent avec le Forum d'Actions de Développement Conjointes du secteur {" "}
									</td>
									<td>
										Le Comité de développement communautaire et le Secrétaire exécutif du secteur s’accordent avec le Forum d'Actions de Développement Conjointes du secteur {" "}
									</td>
									<td>Conseil de la cellule et Secrétaire exécutif du secteur</td>
								</tr>
								<tr>
									<td>District</td>
									<td>
										Le Comité de développement communautaire et l'Administrateur exécutif du district s’accordent avec le Forum d'Actions de Développement Conjointes du district
									</td>
									<td>
										<p className="mb-4">Le Comité de développement communautaire et le Secrétaire exécutif du district s’accordent avec le Forum d'Actions de Développement Conjointes du district</p>
										<p>Gouverneur de la province ou maire de la ville de Kigali</p>
									</td>
									<td>Institut National de la Statistique du Rwanda</td>
								</tr>
								<tr>
									<td>Province/ville de Kigali</td>
									<td>
										<p className="mb-4">Secrétariat exécutif de la province</p>
										<p>Conseil municipal de la ville de Kigali</p>
									</td>
									<td>Ministère de l'Administration Locale</td>
									<td>Institut National de la Statistique du Rwanda</td>
								</tr>
								<tr>
									<td>19 ministères</td>
									<td>
										<p>
											Un audit des <span className="italic">Imihigo</span> conjoints (pour les 7 domaines prioritaires). Tous les objectifs des <span className="italic">Imihigo</span> conjoints ont été évalués par rapport à leur performance ciblée
										</p>
										<p className="my-2">
											Un audit de tous les objectifs du ministère (pour chaque résultat). Tous les objectifs ont été évalués.
										</p>
										<p>
											Entretiens avec des informateurs clés, inclus le Secrétaire permanent et le directeur général du ministère en charge de la planification.
										</p>

									</td>
									<td>Institut National de la Statistique du Rwanda  </td>
									<td>Institut National de la Statistique du Rwanda</td>
								</tr>
								<tr>
									<td>3 Institutions parastatales</td>
									<td>
										Office de développement du Rwanda (RDB)/ Agence de gouvernance du Rwanda (RGB)/ Office des mines, du pétrole et du gaz du Rwanda (RMB)
									</td>
									<td>Institut National de la Statistique du Rwanda</td>
									<td>Institut National de la Statistique du Rwanda</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
				<Link
					to="/hgs/imihigo/overview"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; Aperçu
				</Link>
			</div>
		);
	}
}

export default ImihigoHowItWorks;
