import React, { Component } from "react";
import info1 from "../assets/images/cp-infographics/cp-infographics021.jpg";
import info2 from "../assets/images/cp-infographics/cp-infographics022.jpg";
import info3 from "../assets/images/cp-infographics/cp-infographics023.jpg";
import info4 from "../assets/images/cp-infographics/cp-infographics008.jpg";
import infographics035 from "../assets/images/cp-infographics/cp-infographics035.png";

export class Strategies2 extends Component {
	render() {
		return (
			<div className="flex flex-col">
				<div className="flex flex-col my-4">
					<p className="my-4">
						L'accent mis sur les Initiatives Endogènes Innovantes (HGI) qui sont ancrées dans la culture rwandaise a permis de faire bon usage de la participation des citoyens aux activités de développement local. Cela contribue ainsi à améliorer de manière significative l'accès aux services publics ainsi qu’à améliorer les indicateurs de développement humain. Vingt-cinq ans après la publication du premier rapport des Nations-Unies sur le développement humain en 1990, les Rwandais ont vu leur niveau de vie s'améliorer plus rapidement que dans presque tous les autres pays du monde.
					</p>
				</div>
				<div className="flex flex-row flex-wrap gap-8 my-4">
					<div className="" style={{ maxHeight: "8rem" }}>
						<img src={info1} alt="" style={{ maxHeight: "inherit" }} />
					</div>
					<div className="" style={{ maxHeight: "8rem" }}>
						<img src={info2} alt="" style={{ maxHeight: "inherit" }} />
					</div>
					<div className="" style={{ maxHeight: "8rem" }}>
						<img src={info3} alt="" style={{ maxHeight: "inherit" }} />
					</div>
					<div className="" style={{ maxHeight: "8rem" }}>
						<img src={info4} alt="" style={{ maxHeight: "inherit" }} />
					</div>
				</div>
				<div>
					<p>
						Espérance de vie au Rwanda par rapport à l'Afrique subsaharienne, 1960-2018
					</p>
					<img src={infographics035} alt="" />
				</div>
				<div>
					<p className="my-4">Le Rwanda a connu les améliorations suivantes :</p>
					<ul className="ml-8 overview-list">
						<li>
							Une diminution de la pauvreté de 59% en 2001 à 38% en 2017, ce a permis à plus d'un million de personnes de sortir de l'extréme pauvreté.
						</li>
						<li>
							Une diminution du taux de mortalité infantile de l’ordre de 183 décès d'enfants de moins de 5 ans pour 1000 naissances vivantes en 2000 à 35 pour 1000 en 2018.
						</li>
						<li>Une augmentation du taux d'inscription à l'école primaire jusqu’à 98%.</li>
						<li>
							Une baisse de l'indice de Gini, une mesure du niveau de l'inégalité, de 0,52 en 2006 à 0,42 en 2017.
						</li>
					</ul>
				</div>
			</div>
		);
	}
}

export default Strategies2;
