import React, { Component } from "react";
import ImageHistory2 from "../assets/images/History2.png";

export class History2 extends Component {
	render() {
		return (
			<div className="flex flex-col my-4">
				<div className="flex flex-col my-4">
					<div className="font-bold">
						<p>
							<span className="text-4xl text-fifth">2. </span>Période coloniale
						</p>
					</div>
					<div className="">
						<p>
							Imprégnés de l'idéologie de la race, les colonisateurs belges réorganisèrent  l'État et la société coloniale rwandaise selon des lignes identitaires, inculquant cette idéologie aux nouvelles élites instruites du Rwanda. Ces élites commencèrent donc  se à considérer comme divisées en groupes ethniques racialisés.
						</p>
						<p className="my-4">
							Dans les années 1950, lorsque l'élite tutsi rompit  le pacte qui la liait à l'administration coloniale belge en prônant l'indépendance, l'administration coloniale s'allia  à une contre-élite hutu qu'elle avait auparavant discriminée. À la veille de l'indépendance, l'administration coloniale sortante transféra  le pouvoir à ses nouveaux alliés par le biais d'un soulèvement sanglant organisé, la soi-disant révolution hutu de 1959. Celle-ci a vu des milliers de Tutsis tués et déracinés,  et des centaines de milliers regroupés dans des zones de repeuplement hostiles. D'autres sont devenus des réfugiés dans les pays voisins.
						</p>
					</div>
				</div>
				<div className="">
					<img src={ImageHistory2} alt="" className="w-full" />
				</div>
			</div>
		);
	}
}

export default History2;
