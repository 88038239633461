import React, { Component } from "react";
import inhabitants from "../assets/images/cp-infographics/inhabitants.png";
import square from "../assets/images/cp-infographics/square.png";
import temperature from "../assets/images/cp-infographics/temperature.png";
import age from "../assets/images/cp-infographics/average age.png";
import altitude from "../assets/images/cp-infographics/altitude.jpg";
import language from "../assets/images/cp-infographics/language.png";

export class Overview2 extends Component {
	render() {
		return (
			<div className="">
				<div className="flex flex-col px-4 lg:px-10 xl:px-24">
					<div className="flex flex-col h-auto my-8 infographics md:flex-row">
						<div className="flex flex-col md:w-2/5">
							<div className="w-full">
								<img className="w-full h-full" src={square} alt="" />
							</div>
							<div className="w-full">
								<img className="w-full h-full" src={inhabitants} alt="" />
							</div>
							<div className="w-full">
								<img className="w-full h-full" src={temperature} alt="" />
							</div>
							<div className="w-full">
								<img className="w-full h-full" src={language} alt="" />
							</div>
						</div>
						<div className="mb-4 flex-flex-col md:w-2/5">
							<div className="w-full min-w-full">
								<img className="w-full h-full" src={altitude} alt="" />
							</div>
							<div className="w-full">
								<img className="w-full h-full" src={age} alt="" />
							</div>
						</div>
					</div>
					{/* <div className="">
						<p>
							Les paysages du Rwanda lui ont valu le nom de pays de mille collines.
						</p>
						<p className="my-4">
						De taille modeste avec 26 338 kilomètres carrés, le pays est densément peuplé de 12,3 millions d'habitants. Soixante-dix pour cent de la population rwandaise a moins de 30 ans. Bien que le Rwanda soit situé près de l'équateur, le pays bénéficie d'un climat avec des températures modérées comprises entre 24 ° C et 27 ° C, et son altitude varie entre 1000 et 4507 mètres d'altitude.
						</p>
						<p className="my-4">
							Le Rwanda est situé aux frontières de l'Afrique orientale et centrale. Il 
							fait partie à la fois de la Communauté de l'Afrique de l'Est et de la Communauté économique
							des États d’Afrique centrale. C'est un pays enclavé entouré
							par l'Ouganda, la Tanzanie, la République Démocratique du Congo (RDC) et le
							Burundi. Il partage le lac Kivu avec la RDC.
							</p>
						<p className="my-4">
							Presque tous les Rwandais parlent la langue nationale, le kinyarwanda et
							le pays a trois autres langues officielles, l'anglais, le français
							et le swahili. Au cours des deux dernières décennies, le pays
							a connu une stabilité politique et sociale soutenue par une forte
							croissance économique. Le Rwanda est reconnu pour la qualité de sa
							gouvernance et le rôle important joué par les femmes dans la vie de
							la nation.
						</p>
					</div> */}
				</div>
			</div>
		);
	}
}

export default Overview2;
