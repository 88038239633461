import React, { Component } from "react";
import PageName from "./PageName";
import image052 from "../assets/images/hgs-content-image052.jpeg";

export class How2 extends Component {
	render() {
		return (
			<div className="relative flex flex-col w-full p-4 text-justify md:h-screen lg:py-24 xl:px-40 md:p-16">
				<div className="hidden md:flex">
					<PageName
						title="Que sont les HGI"
						color="primary"
						accentColor="secondary"
					/>
				</div>

				<div className="grid gap-4 md:grid-cols-2">
					<div className="relative h-full text-sm lg:text-base">
						<p className="text-2xl font-semibold">Des circonstances difficiles</p>
						<p className="my-4">
							Au sortir du génocide contre les Tutsis de 1994 et malgré la propagande virulente qui l'avait rendu possible, la langue et la culture ont joué un rôle essentiel en tant qu'espace social commun où les différents groupes de Rwandais pouvaient se retrouver. Un épisode en particulier a souligné leur importance, et a déclenché une chaîne d'événements qui conduisi à l'adoption du programme des HGI.
						</p>
						<p >
							Vers la fin de l’année 1996 et après le retour massif des réfugiés de 1994 qui avaient fui pendant la campagne pour mettre fin au génocide, différents groupes ayant des histoires de vie potentiellement antagonistes se sont retrouvés dans la région de Kibungo, à l'est du Rwanda. Des survivants du génocide, des réfugiés de 1959 venus de camps situés juste de l'autre côté des frontières du Burundi et de la Tanzanie (où ils vivaient depuis trente-cinq ans), des personnes qui n'avaient jamais quitté la région et des réfugiés de 1994 récemment rapatriés se sont tous rassemblés à Kibungo. De vives tensions dues aux revendications territoriales ont fait surface, différentes familles cherchant chacune à faire prévaloir leurs droits sur les mêmes propriétés foncières. Dans le contexte d'un genocide récent, ces tensions ont parfois mené à des meurtres. Bien souvent, les forces de sécurité et les autorités locales n’arrivaient pas à trouver les coupables ou même à saisir les éléments déclencheurs des conflits en cours, les groupes rivaux préférant régler leurs comptes en privé.
						</p>
					</div>
					<div className="flex items-center h-full xl:pl-20 md:pl-4">
						<div className="">
							<img src={image052} alt="" />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default How2;
