import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
	HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import gacacaConvictsImage from "../assets/images/Gacaca21.JPG";
import image040 from "../assets/images/Gacaca22.jpg";

export class GacacaHowItWorked extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">
					Comment fonctionnaient les tribunaux <span className="italic">gacaca</span> ?
				</p>
				<p className="my-4">Les objectifs des <span className="italic">gacaca</span> étaient de :</p>
				<div className="">
					<ol className="ml-4 hgs-ordered-list">
						<li>Révéler la vérité sur le génocide contre les Tutsis.</li>
						<li>Accélérer les procès pour génocide.</li>
						<li>Eradiquer l'impunité.</li>
						<li>Renforcer l'unité et la réconciliation entre les rwandais.</li>
						<li>
							S'appuyer sur la capacité des rwandais à résoudre leurs propres problèmes.
						</li>
					</ol>
					<div className="my-4">
						<img src={gacacaConvictsImage} alt="" className="w-full" />
					</div>
					<p className="my-4">
						Les principes suivants ont guidé le processus <span className="italic">gacaca</span> :
					</p>
					<ol className="ml-4 hgs-ordered-list">
						<li>
							Les suspects de génocide ont été classés en catégories, établies en fonction de la gravité des accusations portées contre eux.
						</li>
						<li>
							La possibilité a été donnée aux suspects de génocide d'avouer leurs crimes et de demander pardon. Lorsque leurs aveux étaient acceptés, leur peine était réduite.
						</li>
						<li>
							Des peines spéciales ont été prononcées pour les personnes ayant commis des crimes de génocide alors qu'elles étaient mineures.
						</li>
					</ol>
					<p className="my-4">
						Comme décrit ci-avant, les suspects ont été placés dans l'une des quatre catégories suivantes, conformément à la loi <span className="italic">gacaca</span> de 2001 :
					</p>
					<p className="my-4 text-xl font-semibold">Catégorie 1 :
						<span className="ml-2 text-base font-normal">
							les planificateurs, organisateurs, instigateurs, superviseurs et chefs de file du génocide ou des crimes contre l'humanité ainsi que ceux qui ont commis des viols ou des tortures sexuelles. Ces suspects ont été jugés par des tribunaux ordinaires.
						</span>
					</p>
					<p className="my-4 text-xl font-semibold">Catégorie 2 :
						<span className="ml-2 text-base font-normal">
							les auteurs, co-auteurs ou complices de meurtres ou de violences graves. Ceux qui ont blessé ou commis des actes de violence grave avec intention de tuer, mais qui n'ont pas atteint leur objectif.
						</span>
					</p>
					<p className="my-4 text-xl font-semibold">Catégorie 3 :
						<span className="ml-2 text-base font-normal">
							ceux qui ont commis des actes de violence grave sans intention de tuer.
						</span>
					</p>
					<p className="my-4 text-xl font-semibold">Catégorie 4 :
						<span className="ml-2 text-base font-normal">
							Ceux qui n'ont commis que des infractions contre les biens.
						</span>
					</p>
					<p className="my-4">
						De 2004 à 2007, ces quatre catégories ont été ramenées à trois pour rationaliser le processus. A partir de mars 2007, leur nouvelle configuration est présentée dans le Tableau 1.
					</p>
					<div className="p-8 my-4 bg-hgsBgLight">
						<p className="text-xl font-semibold">
							Catégorie 1
							<span className="text-base"> (jugé par les tribunaux ordinaires)</span>
						</p>
						<ul className="ml-8 hgs-list">
							<li>Une personne qui a occupé des postes de direction</li>
							<li>Une personne ayant commis des actes de viol ou de torture sexuelle</li>
						</ul>
					</div>
					<div className="p-8 my-4 bg-hgsBgLight">
						<p className="text-xl font-semibold">Catégorie 2</p>
						<ul className="ml-8 hgs-list ">
							<li>Un meurtrier connu et zélé</li>
							<li>Une personne qui a commis des actes de torture </li>
							<li>Une personne qui a commis des actes déshumanisants sur les morts</li>
							<li>
								Une personne dont les actes criminels ou sa participation à des actes criminels l'ont catégorisée parmi les tueurs ou les auteurs d'attaques graves, causant la mort
							</li>
							<li>
								Une personne qui a blessé ou commis des actes d'agression grave, avec intention de tuer, mais qui n'a pas atteint son objectif
							</li>
							<li>
								Une personne qui a commis ou participé à des actes criminels, sans aucune intention de tuer
							</li>
						</ul>
					</div>
					<div className="p-8 my-4 bg-hgsBgLight">
						<p className="text-xl font-semibold">Catégorie 3</p>
						<ul className="ml-8 hgs-list ">
							<li>Une personne qui a commis des infractions uniquement contre les biens</li>
						</ul>
					</div>
					<div>
						<p className="text-2xl font-semibold">
							Organisation et fonctionnement des tribunaux <span className="italic">gacaca</span>
						</p>
						<p className="my-4">
							Si les tribunaux <span className="italic">gacaca</span> avaient reçu des compétences de pratique similaires à celles des autres systèmes judiciaires, elles avaient également la compétence spéciale d'enquêter sur la manière dont les crimes ont été commis, une tâche normalement dévolue au procureur.
						</p>
						<p className="my-4">
							Les tribunaux <span className="italic">gacaca</span> ont été créés à tous les échelons de l’administration territoriale, hormis le plus en aval de l’échelle, le Village. Il y avait des tribunaux <span className="italic">gacaca</span> dans chaque cellule, secteur, district "ou municipalité". Il y avait également des tribunaux dans chaque province ainsi que dans la Ville de Kigali. Les juridictions <span className="italic">gacaca</span> étaient chargées de traiter des crimes de Génocide et d’autres crimes contre l'humanité commis au Rwanda entre le 1er octobre 1990 et le 31 décembre 1994, dans les limites de la loi.
						</p>
						<p className="mt-8 text-xl font-semibold text-center">
							1.Organes des juridictions <span className="italic">gacaca</span>
						</p>
						<p className="my-4">
							Chaque tribunal <span className="italic">gacaca</span> était composé d'une assemblée générale, d'un siège et d'un comité de coordination.
						</p>
						<p className="text-xl font-semibold">
							L'Assemblée Générale
						</p>
						<p className="my-4">
							L'Assemblée Générale de chaque tribunal <span className="italic">gacaca</span> tenait une réunion ordinaire une fois par mois et une session extraordinaire chaque fois que cela était nécessaire pour le bon fonctionnement du tribunal <span className="italic">gacaca</span>. Les décisions de l'Assemblée Générale étaient prises par consensus ou, à défaut, à la majorité absolue de ses membres. Cette réunion mensuelle avait pour but d'évaluer les activités de la cellule et du comité de coordination.
						</p>
						<p className="my-4">
							L'Assemblée Générale de la Cellule <span className="italic">gacaca</span> était appelée à donner des informations concernant les auteurs et les victimes du génocide : chaque habitant de la cellule devait indiquer le lieu où il résidait avant et pendant le génocide, l'individu témoignait également sur ce qu'il savait des crimes de génocide commis dans la cellule.
						</p>
						<p className="text-xl font-semibold">
							Le siège
						</p>
						<p className="my-4">
							Les membres du siège des tribunaux <span className="italic">gacaca</span>, appelés juges Inyangamugayo, étaient des rwandais intègres élus par l'Assemblée Générale de la cellule dans laquelle ils résidaient. Chaque siège du tribunal <span className="italic">gacaca</span> était composé de 19 citoyens intègres.
						</p>
						<p className="my-4">
							L'élection des <span className="italic">inyangamugayo </span>a été menée dans tout le pays du 4 au 7 octobre 2001. D'autres élections furent organisées pour remplacer les <span className="italic">inyangamugayo </span>qui n'étaient plus en mesure de servir dans les tribunaux. Trente-quatre pour cent des <span className="italic">inyangamugayo </span>étaient des femmes.
						</p>
						<p className="my-4">
							Le Service national des tribunaux <span className="italic">gacaca</span> organisait des sessions de formation générale pour tous les <span className="italic">inyangamugayo </span>du pays et offrait des sessions de formation spéciales pour les tribunaux qui avaient besoin d'une formation complémentaire.
						</p>
						<p className="my-4">
							Ces <span className="italic">inyangamugayo </span>étaient principalement composés d'enseignants, de fonctionnaires et de personnes du monde des affaires.
						</p>
						<p className="text-xl font-semibold">
							Le Comité de coordination
						</p>
						<p className="my-4">
							Le Comité de coordination était composé d'un président, d'un premier vice-président, d'un deuxième vice-président et de deux secrétaires, qui devaient tous savoir lire et écrire le kinyarwanda.
						</p>
						<p className="my-4">
							Les membres du siège d'un tribunal <span className="italic">gacaca</span> élisaient le Comité de coordination parmi eux, à la majorité simple.
						</p>
						<p className="my-4">
							Le Comité de coordination de chaque tribunal <span className="italic">gacaca</span> remplissait les fonctions suivantes :
						</p>

						<div className="p-8 my-4 bg-hgsBgLight">
							<ul className="ml-8 hgs-list ">
								<li>
									Convoquer, présider les réunions et coordonner les activités du siège du tribunal <span className="italic">gacaca</span> ainsi que son Assemblée Générale,
								</li>
								<li>
									Enregistrer les plaintes, les témoignages et les preuves fournis par la population ;
								</li>
								<li>Recevoir et enregistrer les dossiers des suspects devant le tribunal <span className="italic">gacaca</span>; </li>
								<li>
									Enregistrer les appels interjetés contre les jugements rendus par le tribunal <span className="italic">gacaca</span>;
								</li>
								<li>Transmettre les dossiers des jugements attaqués aux cours d'appel <span className="italic">gacaca</span> ;</li>
								<li>Enregistrer les décisions prises par les organes du tribunal <span className="italic">gacaca</span>;</li>
								<li>Préparer les rapports d'activités des tribunaux <span className="italic">gacaca</span>;</li>
								<li>
									mettre en œuvre les décisions prises par l'Assemblée Générale et celles du siège des tribunaux <span className="italic">gacaca</span> ;
								</li>
								<li>
									transférer immédiatement le rapport d'activités approuvé par l'Assemblée Générale du tribunal <span className="italic">gacaca</span> aux Assemblées générales des tribunaux <span className="italic">gacaca</span> supérieurs.
								</li>
							</ul>
						</div>
						<p className="my-4">
							Le Comité de coordination du tribunal <span className="italic">gacaca</span> se réunissait autant de fois que possible, sur convocation de son Président. Il convoquait alors de sa propre initiative ou à la demande d'au moins deux membres du comité. Pour siéger valablement, le comité de coordination devait compter au moins trois de ses membres présents, dont un secrétaire. Les décisions étaient prises par consensus. S'il n'y avait pas de consensus, la question était soumise au siège du tribunal <span className="italic">gacaca</span>.
						</p>
						<p className="mt-8 text-xl font-semibold text-center">
							2.Les fonctions des tribunaux <span className="italic">gacaca</span>
						</p>
						<p className="my-4">
							La loi prévoyait des fonctions spécifiques pour chaque juridiction <span className="italic">gacaca</span> :
						</p>
						<p className="text-xl font-semibold">
							Le tribunal <span className="italic">gacaca</span> de cellule
						</p>
						<p className="my-4">
							Le tribunal <span className="italic">gacaca</span> de cellule exerçait les fonctions suivantes :
						</p>
						<div className="p-8 my-4 bg-hgsBgLight">
							<ul className="ml-8 hgs-list ">
								<li>établir une liste d'individus qui :
									<ul className="ml-12">
										<li>résidaient dans la cellule avant et après le Génocide,</li>
										<li>suspectés d'avoir participé au génocide, </li>
										<li>victimes et leurs biens endommagés ,</li>
									</ul>
								</li>
								<li>regrouper les dossiers transmis par le Parquet ;</li>
								<li>
									catégoriser les suspects selon les dispositions de la présente loi organique ;
								</li>
								<li>juger les affaires liées aux biens ;</li>
								<li>
									statuer sur la récusation des membres du siège du tribunal <span className="italic">gacaca</span> de la cellule ;
								</li>
								<li>recevoir les aveux des personnes ayant participé au génocide ;</li>
								<li>
									transmettre les dossiers qui n’étaient pas de leur ressort aux tribunaux compétents ;
								</li>
								<li>élire les membres du Comité de coordination.</li>
							</ul>
						</div>
						<p className="my-4">
							Les tribunaux <span className="italic">gacaca</span> du secteur, du district ou de la ville ainsi que ceux de la province ou de la ville de Kigali avait notamment pour mission de :
						</p>
						<div className="p-8 my-4 bg-hgsBgLight">
							<ul className="ml-8 hgs-list ">
								<li>faire des enquêtes (si nécessaire) sur les témoignages reçus ;</li>
								<li>recevoir les aveux des personnes ayant participé au génocide ;</li>
								<li>de statuer sur la récusation des membres de la magistrature ;</li>
								<li>
									juger les affaires relevant de sa compétence, après s'être assuré que les suspects qui lui sont transmis, aient été catégorisés en fonction des infractions réprochées,
								</li>
								<li>
									examiner les recours contre les jugements rendus par les tribunaux <span className="italic">gacaca</span> du niveau inférieur et relevant de sa compétence;
								</li>
								<li>l'élection des membres du Comité de coordination ;</li>
								<li>
									examiner les rapports d'activités des tribunaux <span className="italic">gacaca</span> du niveau inférieur et relevant de sa juridiction.
								</li>
							</ul>
						</div>
					</div>
					<p className="mt-8 text-xl font-semibold text-center">
						3. Les compétences des juridictions de <span className="italic">gacaca</span>
					</p>
					<div className="">
						<div className="my-4">
							<p className="font-semibold">Le tribunal <span className="italic">gacaca</span> au niveau de la cellule</p>
							<p className="my-4">
								Le tribunal <span className="italic">gacaca</span> de la cellule, opérant au deuxième niveau de l’administration locale en partant du bas de l’échelle administrative, a traité des crimes de la catégorie trois après la revision. Elle a également traité les objections déposées contre la sentence prononcée en l'absence de l'accusé. La Cour <span className="italic">gacaca</span> de la cellule était aussi en charge de la catégorisation des auteurs présumés du génocide.
							</p>
						</div>
						<div className="my-4">
							<p className="font-semibold">Le tribunal <span className="italic">gacaca</span> du secteur</p>
							<p className="my-4">
								Le tribunal <span className="italic">gacaca</span> du secteur, qui est le niveau suivant de gouvernement local en amont de la cellule, était chargé des accusés dont les infractions tombaient dans la catégorie deux. Ce tribunal était également chargé de l'opposition faite contre les peines prononcées en l'absence des accusés.
							</p>
						</div>
						<div className="my-4">
							<p className="font-semibold">
								Le tribunal <span className="italic">gacaca</span> du district ou de la ville
							</p>
							<p className="my-4">
								Le tribunal <span className="italic">gacaca</span> du district ou de la ville fonctionnait au niveau du district qui est le niveau de gouvernement local en amont du secteur. Il s'occupait des crimes de la catégorie deux après la revision, des appels interjetés contre les jugements du premier niveau ou des décisions des tribunaux <span className="italic">gacaca</span> de secteur qui ont été rendues en l'absence de l'accusé.
							</p>
						</div>
						<div className="my-4">
							<p className="font-semibold">
								Le tribunal <span className="italic">gacaca</span> de la Province ou de la Ville de Kigali
							</p>
							<p className="my-4">
								Le tribunal <span className="italic">gacaca</span> de la Province ou de la Ville de Kigali opérait au niveau de gouvernement local directement supérieur à celui du district. Il traitait les appels des jugements rendus au premier niveau, ou sur objection des Cours <span className="italic">gacaca</span> du district ou de la Ville.
							</p>
						</div>
						<div className="my-4">
							<img src={image040} alt="" className="w-full"/>
						</div>
						<div className="grid gap-8 my-4 lg:grid-cols-2">
							<div className="">
								<p className="text-2xl font-semibold">Analyse des dossiers</p>
								<p className="my-4">
									Après qu'une information sur un cas ait été présentée aux tribunaux, elle était analysée par les <span className="italic">inyangamugayo </span>de la Cour <span className="italic">gacaca</span> au niveau de la cellule. Ils préparaient ensuite un dossier sur les personnes accusées d'avoir commis des crimes. En fonction de la gravité des crimes allégués, le suspect était placé dans l'une des trois catégories décrites dans le tableau 1.
								</p>
								<p className="my-4 ">
									Après la préparation du dossier et de sa classification dans la catégorie appropriée par les inyangamugayo, ils le soumettaient à la cour compétente pour jugement. Les dossiers de la première et de la deuxième catégorie étaient soumis au tribunal <span className="italic">gacaca</span> du secteur. Ceux de la troisième catégorie incombaient au tribunal <span className="italic">gacaca</span> de la cellule.
								</p>
								<p className="my-4 ">
									Au cours de cette phase d'enquête, le nombre de suspects a considérablement augmenté. Cela a mis à rude épreuve la capacité des tribunaux à rendre justice dans des délais acceptables.
								</p>
							</div>
							<div className="">
								<p className="text-2xl font-semibold list-outside">Audiences</p>
								<p className="my-4 ">
									Les audiences des <span className="italic">gacaca</span> étaient publiques, à l'exception de celles enregistrées à huis clos sur décision du tribunal ou à la demande de l’une des parties. Les audiences à huis clos étaient décidées par un jugement avec pour seul motif valable la protection de l'ordre public. Les délibérations se déroulaient à huis clos. Chaque cour <span className="italic">gacaca</span> tenait une audience au moins une fois par semaine, généralement de 8h30 à 16h.
								</p>
								<p className="my-4 ">
									Toute personne citée à comparaître devant le tribunal, qu'il s'agisse d'un accusé, d'un témoin, d'une victime ou de toute autre personne, devait être informée au moins sept jours avant le procès. Si le défendeur n'avait ni adresse ni résidence connues au Rwanda, la période de convocation était d'un mois.
								</p>
								<p className="my-4 ">
									A chaque audience, le président de la cour faisait d'observer une minute de silence à la mémoire des victimes du génocide. Avant de témoigner, le témoin devait prêter serment et jurer de dire la vérité. Les séances étaient régies par un ensemble de huit règles qui garantissaient le bon déroulement de l'audience. Par exemple : lever la main pour prendre la parole, l'interdiction d'insulter, de menacer ou encore l'obligation pour les intervenants de toujours dire la vérité.
								</p>
							</div>
						</div>
					</div>
				</div>
				<Link
					to="/hgs/gacaca/achievements"
					className="flex flex-row items-center justify-end pt-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					Réalisations &nbsp; <HiOutlineArrowNarrowRight />
				</Link>
				<Link
					to="/hgs/gacaca/overview"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; Aperçu
				</Link>
			</div>
		);
	}
}

export default GacacaHowItWorked;
