import React, { Component } from 'react'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';

export class UmwihereroOverview extends Component {
	render() {
		return (
			<div>
				<p className="text-3xl font-semibold">Aperçu</p>
				<div className="w-full my-8">
					<ReactPlayer
						width="100%"
						height="30rem"
						controls={true}
						loop={false}
						muted={true}
						url="https://youtu.be/iciHDB4Upbc"
					/>
				</div>
				<div className="my-6">
					<p className="my-4">
						<span className="italic">Umwiherero</span> désigne une tradition de la culture rwandaise durant laquelle les dirigeants se retiraient dans un lieu isolé pour réfléchir aux problèmes rencontrés par leurs communautés, afin d'identifier des solutions. Ce terme renvoie aussi à l'action de s’isoler dans un endroit calme pour discuter des problèmes avec un petit groupe de personnes.
					</p>
					<p className="my-4">
						Le gouvernement du Rwanda actuel s'est inspiré de cette tradition pour, chaque année, réfléchir et relever les défis auxquels le pays est confronté. <span className="italic">Umwiherero</span> est organisé par le Bureau du Président (OTP), en collaboration avec le Bureau du Premier ministre (PMO). Le Président dirige <span className="italic">Umwiherero</span>, au cours duquel les présentations et les discussions se concentrent sur les enjeux de développement, tels que l'économie, la politique, la justice, les infrastructures, la santé ou l'éducation. <span className="italic">Umwiherero</span> contemporain était à l'origine réservé aux membres de la haute fonction publique mais le concept a évolué et inclut désormais les maires ainsi que des dirigeants du secteur privé et de la société civile.
					</p>
				</div>
				<Link
					to="/hgs/umwiherero/improvement"
					className="flex flex-row items-center py-12 font-semibold hover:text-secondary text-primary"
				>
					Amélioration au fil du temps &nbsp; <HiOutlineArrowNarrowRight />
				</Link>
			</div>
		);
	}
}

export default UmwihereroOverview
