import React, { Component, Fragment } from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { Route, Switch } from "react-router-dom";
import HGSSideNav from "../components/HGSSideNav";
import IngandoImpact from "../components/IngandoImpact";
import IngandoOverview from "../components/IngandoOverview";
import PageName from "../components/PageName";
import { ingandoSubmenus } from "../utils/menus";

export class Ingando extends Component {
	render() {
		const { match } = this.props;
		return (
			<Fragment>
				<div className="flex flex-row h-screen w-full relative">
					<div className="hidden md:flex w-4/12">
						<HGSSideNav
							hgsTitle="Ingando"
							hgsSubTitle="gouvernance et justice"
							hgsIntro={
								<p className="text-justify">
									Le camp de solidarité, également connu sous le nom d’Ingando, est l’un des HGI du Rwanda. Le mot Ingando vient du verbe «kugandika», qui signifie aller rester dans un endroit loin de chez soi, souvent avec un groupe, pour une raison précise. Traditionnellement, Ingando était une retraite au cours de laquelle des anciens, des dirigeants ou des jeunes quittaient leurs maisons et restaient dans un endroit où ils méditaient et partageaient des idées sur la façon de résoudre les problèmes affectant leurs communautés ou la nation.
								</p>
							}
							submenus={ingandoSubmenus}
						/>
					</div>
					<div className="flex md:w-8/12 px-4 lg:px-10 xl:px-24 pt-40 overflow-y-scroll bg-white relative">
						<div>
							<PageName
								title="Que sont les HGI"
								color="primary"
								accentColor="secondary"
								subTitle={
									<>
										Ubudehe &nbsp; <HiOutlineArrowNarrowRight />
									</>
								}
								to="/hgs/ubudehe/overview"
							/>
						</div>
						<Switch>
							<Route exact path={`${match.path}`} component={IngandoOverview} />
							<Route
								exact
								path={`${match.path}/overview`}
								component={IngandoOverview}
							/>
							<Route
								exact
								path={`${match.path}/impact`}
								component={IngandoImpact}
							/>
						</Switch>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default Ingando;
