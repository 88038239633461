import React, { Component } from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import ReactPlayer from "react-player/lazy";
import { Link } from "react-router-dom";

export class UmugandaOverview extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Aperçu</p>
				<div className="w-full my-4">
					<ReactPlayer
						width="100%"
						height="30rem"
						controls={true}
						loop={false}
						muted={true}
						url="https://youtu.be/G3yEZr6d_N0"
					/>
				</div>
				<div className="my-6">
					<p className="my-4">
						Le terme <span className="italic">Umuganda</span> fait référence à une tradition rwandaise dans laquelle un groupe de personnes travaillent ensemble pour atteindre un résultat commun. Au Rwanda, les membres de la communauté faisaient appel à leur famille, à leurs amis et à leurs voisins pour les aider à accomplir ensemble une tâche difficile.
					</p>
					<p className="my-4">
						L'<span className="italic">Umuganda</span> contemporain consiste en un travail communautaire. Le dernier samedi de chaque mois, les communautés de voisinage se rassemblent pour effectuer divers travaux d'intérêt public, notamment des activités visant à promouvoir le développement des infrastructures et la protection de l'environnement. Les rwandais âgés de 18 à 65 ans sont tenus de participer à ces activités. Les non-rwandais vivant au Rwanda sont encouragés à y prendre part.
					</p>
					<p className="my-4">
						Aujourd'hui, selon le Ministère de l’Administration Locale, près de 90% des Rwandais participent à des travaux communautaires mensuels. Durant l’<span className="italic">Umuganda</span>, les citoyens construisent des écoles, des centres médicaux, drainent des marais ou alors cultivent des champs agricoles hautement productifs. Entre 2014 et 2019, la contribution annuelle moyenne de l'<span className="italic">Umuganda</span> au développement du pays a été estimée à environ 21 millions USD.
					</p>
					<p className="my-4">
						Bien que l'objectif principal de l'<span className="italic">Umuganda</span> soit d'entreprendre des travaux communautaires, une fois ces travaux terminés, les membres de la communauté se réunissent pour discuter de questions locales et nationales importantes. Cette réunion offre aux autorités locales une plate-forme pour communiquer aux citoyens les nouvelles et annonces importantes. Elle sert également de forum dans lequel les membres de la communauté peuvent discuter des problèmes auxquels ils sont confrontés, sur les plans individuel et collectif. Ils ont ainsi l’occasion de trouver des solutions ensemble. Ce temps est utilisé pour évaluer les réalisations de la communauté et pour planifier les activités de l'<span className="italic">Umuganda</span> du mois suivant.
					</p>
					<p className="my-4">
						L’<span className="italic">Umuganda</span> a contribué à près de 62% du coût total de construction des salles de classe lors de la mise en œuvre du programme d'éducation universel de base de neuf et douze ans (9&12YBE).
					</p>
				</div>
				<Link
					to="/hgs/umuganda/how-it-works"
					className="flex flex-row items-center justify-end py-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<p>Comment <span className="italic"> Umuganda </span> est-il organisé?</p> &nbsp; <HiOutlineArrowNarrowRight />
				</Link>
			</div>
		);
	}
}

export default UmugandaOverview;
