import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
	HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import History from "../components/History";
import History2 from "../components/History2";
import History3 from "../components/History3";
import PageName from "../components/PageName";

export class CPHistory extends Component {
	render() {
		return (
			<div className="text-justify">
				<PageName
					title="Profil du pays"
					color="primary"
					accentColor="fifth"
					subTitle={
						<>
							Le Rwanda après le génocide" &nbsp; <HiOutlineArrowNarrowRight />
						</>
					}
					to="/country/today"
				/>
				<p className="text-3xl font-semibold">Histoire</p>
				<div>
					<History />
				</div>
				<div>
					<History2 />
				</div>
				<div>
					<History3 />
				</div>
				<div>
					<Link
						to="/country/today"
						className="pt-12 flex flex-row items-center justify-end md:justify-start font-semibold hover:text-fifth text-primary"
					>
						Le Rwanda après le génocide &nbsp; <HiOutlineArrowNarrowRight />
					</Link>
					<Link
						to="/country/overview"
						className="mt-4 pb-12 flex flex-row items-center justify-end md:justify-start font-semibold text-xs hover:text-fifth text-primary"
					>
						<HiOutlineArrowNarrowLeft /> &nbsp; Les faits
					</Link>
				</div>
			</div>
		);
	}
}

export default CPHistory;
