import React, { Component } from "react";
// import rwPediaLogoBlack from "../assets/images/RWandaPedia Logo_Edit-06.png";
import rwPediaLogoWhite from "../assets/images/Rwandapedia_Logo_white.svg";
import NavigationDots from "../components/NavigationDots";
import NavigationScroll from "../components/NavigationScroll";
// import HomeEleraningImage from "../assets/images/image8.jpeg";
import homeElearning from "../assets/images/homeElearning.jpg";

export class HomeElearning extends Component {
	render() {
		return (
			<section className="w-full h-screen text-white home-section bg-primary">
				<div
					className="bg-top bg-no-repeat bg-cover home-background"
					style={{ backgroundImage: `url(${homeElearning})` }}
				></div>
				<div className="absolute z-10 w-full h-full home-elearning-gradient"></div>
				<div className="flex flex-col h-full pb-8 sm:w-full sm:px-4 home-left md:px-8">
					<div className="flex flex-row items-center h-full">
						<div className="pr-20 home-intro-content">
							<div className="flex py-4 home-section-logo md:w-48 ">
								<img src={rwPediaLogoWhite} alt="" className="w-full h-full" />
							</div>
							<div className="font-bold home-title sm:text-2xl md:text-3xl xl:text-4xl">
								<p>Portail E-learning Premium </p>
							</div>
							<div className="pr-8 home-intro-paragraph sm:text-sm xl:text-base">
								<p className="my-2">
									Nous avons créé cette plateforme pour répondre aux besoins exprimés par
									les nombreux visiteurs, issus des différentes régions d’Afrique, que nous
									recevons chaque année au Rwanda, et qui souhaitent comprendre le processus
									de transformation du pays et s’inspirer de ses bonnes pratiques.
								</p>
								<p className="my-2">
									Cette platforme d'apprentissage en ligne mettra à la
									disposition de ses utilisateurs un large éventail de cours sur les
									initiatives endogènes innovantes du Rwanda dans tous les domaines, ainsi que sur
									les politiques publiques qui ont contribué à la transformation du pays.
								</p>
								<p className="my-2">
									Ces cours seront factuels, concrets, exhaustifs ; ils permettront de
									comprendre l’approche qui a présidé à la mise en place des initiatives
									en matière de politique publique au Rwanda, tout en expliquant les défis
									rencontrés et la manière dont ils ont été surmontés.
								</p>
							</div>
							<div className="flex flex-row home-buttons">
								<a
									href="https://elearning.rwandapedia.rw/"
									target="_blank"
									rel="noopener noreferrer"
									className="my-5 font-bold text-white border-2 border-white sm:btn lg:px-16 md:px-3 lg:py-6 md:py-2 hover:bg-secondary hover:border-secondary"
								>
									Apprendre plus
								</a>
							</div>
						</div>
						<div className="flex flex-col items-center justify-center w-auto h-full home-scroll-controls">
							<NavigationDots active={4} />
						</div>
					</div>
					<NavigationScroll to="home-5" />
				</div>
			</section>
		);
	}
}

export default HomeElearning;
