import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import image024 from "../assets/images/hgs-content-image024.jpeg";

export class UmwihereroImprovement extends Component {
	render() {
		return (
			<div>
				<p className="text-3xl font-semibold">Amélioration au fil du temps</p>
				<div className="">
					<p className="my-4">
					<span className="italic">Umwiherero</span> a débuté en 2004 par de grands débats de réflexion peu structurés. Les quatre premières années d'<span className="italic">Umwiherero</span> ont donné des résultats mitigés.
					</p>
					<p className="my-4">
						Afin de rationaliser et de renforcer la coordination gouvernementale, le gouvernement a créé deux unités spécialisées, l'Unité chargée des Statégies et Politiques au sein d'OTP ainsi que l'Unité de Coordination au sein du PMO. Ces deux unités ont été chargées de travailler ensemble à la mise en œuvre d'<span className="italic">Umwiherero</span>. A la même époque, le Ministère des Affaires du Cabinet gouvernemental a été créé pour améliorer le fonctionnement du Cabinet.
					</p>
					<p className="my-4">
						Suite à <span className="italic">Umwiherero</span> 2009, le ministre des Affaires du Cabinet a mis en place un comité de pilotage pour organiser la retraite. Le comité de pilotage était composé de quatorze membres. En plus de ce dernier, des groupes de travail ont été mis en place pour définir les priorités à inclure dans l'agenda de la retraite <span className="italic">Umwiherero</span>. Ce processus a été supervisé par l'Unité chargée des Statégies et Politiques de la présidence qui a élaboré un document de réflexion avec onze domaines prioritaires à approuver par le Premier ministre et le Président.
					</p>
					<p className="my-4">
						À partir de 2011, tous les maires de district ont rejoint <span className="italic">Umwiherero</span>. Leur participation a amélioré l'intégration du gouvernement local dans le processus d'échange de connaissances et la communication. 
					</p>
					<p>
						A partir de l'édition d’<span className="italic">Umwiherero</span> de 2011, l'organisation, la mise en œuvre et les résultats d'<span className="italic">Umwiherero</span> se sont considérablement améliorés et des réalisations importantes ont été enregistrées. Ceci est surtout dû à un changement d’approche avec la réduction du nombre de priorités à discuter permettant une meilleure focalisation des efforts sur des questions importantes et urgentes. Cette redéfinition des objectifs a favorisé une structuration des discussions et l'efficacité dans la mise en œuvre des décisions. Par exemple, le nombre de priorités nationales examinées durant <span className="italic">Umwiherero</span> est passé de 174 en 2009 à 11 en 2010, puis est retombé à six en 2011. Les retraites <span className="italic">Umwiherero</span> ont également renforcé la coordination et la coopération entre les ministères et les agences gouvernementales.
					</p>
					<div
						className="my-8 bg-center bg-no-repeat bg-cover"
						style={{
							backgroundImage: `url(${image024})`,
							height: "25rem",
						}}
					></div>
				</div>
				<div className="px-4 ">
				<p className="my-6 text-2xl font-semibold">
					Résolutions de la 17e Retraite nationale des dirigeants : Umwiherero 2020
					</p>
					<p className="font-semibold">Gouvernance et justice</p>
					<div className="my-4">
						<ol className="mx-4 hgs-ordered-list">
							<li>
								Mettre en œuvre les recommandations de l'auditeur général pour améliorer la gestion des fonds publics.
							</li>
							<li>
								Enquêter et poursuivre les cas de corruption et mettre en place un mécanisme de recouvrement des fonds publics détournés.
							</li>
							<li>
								Rationaliser les services de la plateforme digitale de prestation des services publics, Irembo, en éliminant progressivement les exigences inutiles requises par les institutions gouvernementales dans la prestation des services au public.
							</li>
							<li>
								Renforcer les services Irembo, notamment en ce qui concerne les service rendus par les entités administratives locales
							</li>
						</ol>
					</div>
					<p className="font-semibold">Santé</p>
					<div className="my-4">
						<ol className="mx-4 hgs-ordered-list">
							<li>
								Accélérer le transfert de responsabilité pour la formation médicale du ministère de l'éducation au ministère de la santé.
							</li>
							<li>
								Révision accélérée de la stratégie des Ressources Humaines pour la Santé (RHS), afin d'inclure l'évaluation de l'apprentissage continu (après la formation et pendant la pratique) et l'application de l'éthique.
							</li>
							<li>
								Automatiser les processus, y compris les dossiers médicaux et la facturation, afin d'améliorer la prestation des services dans les établissements de santé.
							</li>
							<li>
								Réglementer la double pratique (privée et publique) dans les établissements de santé publique afin d'améliorer la prestation des services.
							</li>
							<li>
								Réorganiser le programme de développement de la petite enfance et accorder la priorité à la réduction de la malnutrition et au retard de croissance chez les enfants. 
							</li>
						</ol>
					</div>
					<p className="font-semibold">Éducation</p>
					<div className="my-4">
						<ol className="mx-4 hgs-ordered-list">
							<li>
								Mettre fin à la pratique de promotion automatique des étudiants et renforcer un système fondé sur le mérite, pour donner la priorité à la qualité de l'éducation.
							</li>
							<li>
								Augmenter le nombre d'écoles d’enseignement et de formation techniques et professionnels (EFTP) et élaborer une stratégie pour les rendre plus accessibles et plus abordables.
							</li>
							<li>
								Évaluer la qualité de l'enseignement dans les établissements d'enseignement supérieur existants et revoir les exigences actuelles en matière d'accréditation et d'autorisation pour les nouveaux établissements.
							</li>
						</ol>
					</div>
					<p className="font-semibold">Secteur privé</p>
					<div className="my-4">
						<ol className="mx-4 hgs-ordered-list">
							<li>
								Élaborer un plan concret pour faciliter le renforcement des compétences du secteur privé.
							</li>
							<li>
								Revoir les stratégies du secteur minier pour améliorer la diversification et la valeur ajoutée.
							</li>
							<li>
								Soutenir l'accès aux fonds de capital-investissement comme alternative aux prêts.
							</li>
							<li>
								Encourager les organismes de placement collectif, en particulier dans les industries et les secteurs de services à forte valeur ajoutée.
							</li>
						</ol>
					</div>
				</div>
				<Link
					to="/hgs/umwiherero/overview"
					className="flex flex-row items-center pb-12 mt-4 text-xs font-semibold hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; Aperçu
				</Link>
			</div>
		);
	}
}

export default UmwihereroImprovement;
