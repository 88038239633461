import React, { Component } from "react";
import PageName from "./PageName";
import image050 from "../assets/images/hgs-content-image050.jpeg";

export class How3 extends Component {
	render() {
		return (
			<div className="relative flex flex-col w-full p-4 text-justify md:h-screen lg:py-24 lg:px-40 md:p-16">
				<div className="hidden md:flex">
					<PageName
						title="Que sont les HGI"
						color="primary"
						accentColor="secondary"
					/>
				</div>

				<div className="grid gap-4 md:grid-cols-2">
					<div className="relative text-sm">
						<p className="">
							Les campagnes de sensibilisation n'ayant pas réussi à ramener la paix, il a été décidé d'essayer d'aplanir les divergences des uns et des autres à travers des dialogues communautaires. La solution qui a fini par émerger de ces assises communautaires a été le partage des terres en fonction des besoins des différentes familles. La répartition des terres a ensuite été négociée lors de palabres communautaires similaires aux mécanismes traditionnels de résolution des conflits, les Gacaca.
						</p>
						<p className="my-4">
							Ces délibérations communautaires ont totalement décanté la situation, déclenchant une collaboration inattendue. En raison de cette constatation, les autorités locales ont alors demandé à la population d'aller au-delà des problèmes locaux et de discuter des causes du conflit au Rwanda et de potentielles pistes pour trouver des solutions. Ces consultations de la base de la société civile ont été étendues à l’ensemble du pays. Elles ont permis d'obtenir de riches informations et d'identifier cinq causes principales du conflit au Rwanda, à savoir : des défaillances dans le leadership, la pauvreté, l'ignorance, l'impunité et l'exclusion. Ces discussions communautaires, qui ont eu lieu en 1997 et 1998, ont joué un rôle important en rassemblant la population au niveau local. Le gouvernement a décidé de s'inspirer de cet exercice de consultations en organisant des délibérations approfondies au niveau national dans le cadre de ce que l'on a appelé les discussions du Village Urugwiro.
						</p>
					</div>
					<div className="flex items-center h-full xl:pl-20 md:pl-4">
						<div className="">
							<img src={image050} alt="" />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default How3;
