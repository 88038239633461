import React, { Component } from "react";
import CPLeftImage from "./CPLeftImage";
import PageName from "./PageName";
import image051 from "../assets/images/hgs-content-image051.jpeg";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";

export class How extends Component {
	render() {
		return (
			<div className="relative flex flex-col w-full text-justify md:h-screen">
				<div className="sm:hidden">
					<PageName
						title="Que sont les HGI"
						color="primary"
						accentColor="secondary"
					/>
				</div>

				<div className="grid md:grid-cols-2">
					<div className="relative h-full sm:h-auto">
						<div className="hidden md:flex">
							<CPLeftImage image={image051} />
						</div>
						<div className="flex flex-col mt-20 border-b-8 md:hidden border-secondary">
							<div className="flex flex-row items-center px-4 py-2 text-xs uppercase">
								<div>
									<Link to="/" className="font-semibold text-secondary">
										Home
									</Link>
								</div>
								&nbsp;
								<MdKeyboardArrowRight className="text-xl" />
								&nbsp;
								<div className="font-semibold">Comment les HGI ont-t-elles vu le jour ?</div>
							</div>
							<img src={image051} alt="" />
						</div>
						<div className="top-0 flex flex-col w-full p-4 md:absolute md:h-screen md:bg-primary md:bg-opacity-75 lg:px-24 md:px-8 md:justify-center">
							<p className="my-4 text-2xl font-semibold text-left">
								Comment les HGI ont-t-elles vu le jour ?
							</p>
							<p className="text-sm md:font-bold lg:text-base">
								Les HGI se fondent sur le très fort attachement que les Rwandais portent à leur culture et à la langue qu'ils partagent, le kinyarwanda.
							</p>
						</div>
					</div>
					<div className="flex flex-col h-full px-4 overflow-x-auto text-sm md:pt-20 xl:px-24 md:px-8 lg:pr-24 md:pr-16 lg:text-base">
						<p className="text-2xl font-semibold text-left md:my-4">Culture</p>
						<p>
							Les HGI se fondent sur le très fort attachement que les Rwandais portent à leur culture et à la langue qu'ils partagent, le kinyarwanda. La modernisation coloniale et postcoloniale n'a pas affaibli cet attachement, pas même parmi l'élite. Presque tous les Rwandais utilisent le kinyarwanda comme principale langue de communication dans leur vie privée et cette langue a toujours joué un rôle important dans l'administration publique. En outre, les jeunes, les artistes et les chanteurs continuent d'utiliser le kinyarwanda comme première langue d'expression.
						</p>
						<p className="my-4">
							Hors du Rwanda, pendant leurs trente-cinq années d'exil, les centaines de milliers de réfugiés dispersés dans la région et dans le reste du monde ont maintenu un attachement à leur culture traditionnelle. La mobilisation, pour retourner dans leur pays, de la deuxième génération de réfugiés nés ou élevés hors du Rwanda avait également été motivée par le désir de préserver leur identité culturelle, menacée par un exil prolongé.
						</p>
					</div>
				</div>
			</div>
		);
	}
}

export default How;
