import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
	HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import PageName from "./PageName";
import image045 from "../assets/images/How-Image.jpg";
import { Link } from "react-router-dom";

export class How4 extends Component {
	render() {
		return (
			<div className="relative flex flex-col w-full pt-4 text-justify md:h-screen lg:p-40 md:p-16">
				<div className="hidden md:flex">
					<PageName
						title="Que sont les HGI"
						color="primary"
						accentColor="secondary"
						subTitle={
							<>
								Ce qui les fait fonctionner <HiOutlineArrowNarrowRight />
							</>
						}
						to="/hgs/what-makes-them-work"
					/>
				</div>
				<div className="grid px-4 md:grid-cols-2 md:pt-24 lg:pt-0">
					<div className="h-full text-sm lg:justify-center">
						<p className="">
							La tradition du travail communautaire Umuganda était impopulaire avant 1994 en raison de la façon dont elle était organisée. Toutefois, les avantages considérables que présente le rassemblement des communautés autour de pratiques culturellement ancrées ont conduit à la reprise de cette pratique en 1998. En 2000, Ubudehe, le mécanisme de développement socio-économique participatif communautaire, a également été revitalisé lors de la formulation du premier Document de stratégie de réduction de la pauvreté dans le cadre de l'initiative d'évaluation participative de la pauvreté. Cela a marqué le début d'une volonté très appuyée d'utiliser des mécanismes hybrides qui combinent le traditionnel et le moderne, comme méthodes d'action sociale.
						</p>
					</div>
					<div className="flex items-center h-full xl:pl-20 md:pl-4">
						<div className="">
							<img src={image045} alt="" />
						</div>
					</div>
				</div>
				<div className="flex flex-col md:hidden">
					<Link
						to="/hgs/what-makes-them-work"
						className="flex flex-row items-center justify-end w-full py-2 pt-8 font-semibold hover:text-secondary text-primary"
					>
						Qu'est-ce qui fait que Rwanda HGI fonctionne?? &nbsp; <HiOutlineArrowNarrowRight />
					</Link>
					<Link
						to="/hgs/what-are-hgs"
						className="flex flex-row items-center justify-end w-full text-xs font-semibold hover:text-secondary text-primary"
					>
						<HiOutlineArrowNarrowLeft /> &nbsp; Quels sont Les initiatives locales du Rwanda
					</Link>
				</div>
			</div>
		);
	}
}

export default How4;
