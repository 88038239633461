import React, { Component } from "react";
import { Fragment } from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { Route, Switch } from "react-router-dom";
import HGSSideNav from "../components/HGSSideNav";
//import ImihigoChallenges from "../components/ImihigoChallenges";
import ImihigoHowItWorks from "../components/ImihigoHowItWorks";
import ImihigoOverview from "../components/ImihigoOverview";
import PageName from "../components/PageName";
import { imihigoSubmenus } from "../utils/menus";

export class Imihigo extends Component {
	render() {
		const { match } = this.props;
		return (
			<Fragment>
				<div className="flex flex-row h-screen w-full relative">
					<div className="hidden md:flex w-4/12">
						<HGSSideNav
							hgsTitle="Imihigo"
							hgsSubTitle="économie"
							hgsIntro={
								<p className="text-justify">
									Dans le cadre des efforts visant à reconstruire le Rwanda et à entretenir une identité nationale commune, le Gouvernement rwandais s’est appuyé sur les pratiques traditionnelles de la culture rwandaise pour adapter ses programmes de développement aux besoins et au contexte du pays.
								</p>
							}
							submenus={imihigoSubmenus}
						/>
					</div>
					<div className="flex md:w-8/12 px-4 lg:px-10 xl:px-24 pt-40 overflow-y-scroll bg-white relative">
						<div>
							<PageName
								title="Que sont les HGI"
								color="primary"
								accentColor="secondary"
								subTitle={
									<>
										Girinka &nbsp; <HiOutlineArrowNarrowRight />
									</>
								}
								to="/hgs/girinka/overview"
							/>
						</div>
						<Switch>
							<Route exact path={`${match.path}`} component={ImihigoOverview} />
							<Route
								exact
								path={`${match.path}/overview`}
								component={ImihigoOverview}
							/>
							<Route
								exact
								path={`${match.path}/how-it-works`}
								component={ImihigoHowItWorks}
							/>
							{/* <Route
								exact
								path={`${match.path}/challenges`}
								component={ImihigoChallenges}
							/> */}
						</Switch>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default Imihigo;
