import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
	HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import PageName from "../components/PageName";
import infographics039 from "../assets/images/cp-infographics/cp-infographics039.jpg";
import infographics037 from "../assets/images/cp-infographics/cp-infographics037.jpg";
import Gender1 from "../assets/images/Gender-Equality1.jpg";

export class CPGender extends Component {
	render() {
		return (
			<div className="text-justify">
				<PageName
					title="Profil du pays"
					color="primary"
					accentColor="fifth"
					subTitle={
						<>
							Économie &nbsp; <HiOutlineArrowNarrowRight />
						</>
					}
					to="/country/economy"
				/>
				<p className="text-3xl font-semibold">
					Égalité des genres et autonomisation des femmes
				</p>
				<div className="">
					<img src={Gender1} alt="" className="w-full" />
				</div>
				<div>
					<div>
						<p className="my-4">
							Depuis les premières élections en 1996 de femmes membres de conseils à tous les niveaux du gouvernement local et qui ont par la suite abouti à leur forte représentation à la Chambre des députés avec la constitution de 2003, le Rwanda a mis en place un vaste écosystème de lois, de politiques, d'institutions et de programmes visant à encourager l'égalité des genres et l'autonomisation des femmes.
						</p>
						<p className="my-4">
							Parmi ces institutions, les plus importantes sont les suivantes :
						</p>
						<ul className="ml-8 overview-list">
							<li>
								<span className="font-semibold">
									Ministère du Genre et de la Promotion de la Famille :{" "}
								</span>
								Il s'agit de l'institution du gouvernement central qui a pour mandat d'assurer la coordination stratégique de la mise en œuvre des politiques dans les domaines du genre, de la famille, de l'autonomisation des femmes et des enfants. Il joue un rôle de premier plan dans la mise en œuvre du programme d'égalité des genres.
							</li>
							<li>
								<span className="font-semibold">
									Forum des Femmes Rwandaises Parlementaires :{" "}
								</span>
								Ce forum a été créé en 1996 par les femmes de l'Assemblée Nationale de transition, indépendamment de leurs tendances politiques. Toutes les femmes parlementaires, sénatrices et députées sont membres de ce Forum.
							</li>
							<li>
								<span className="font-semibold">
									Conseil National des Femmes : {" "}
								</span>
								La mission du Conseil National des Femmes est de renforcer les capacités des femmes et d'assurer leur participation au développement national par le biais de la défense des droits et de la mobilisation sociale. Le conseil est composé de toutes les femmes et filles de plus de 18 ans. Ses structures commencent au niveau des villages. Il est doté d’un comité exécutif de 7 personnes à chaque niveau administratif.
							</li>
							<li>
								<span className="font-semibold">
									L'Observatoire du Genre : {" "}
								</span>
								le rôle de cette institution est de faire le suivi des progrès réalisés en matière d'égalité des genres en élaborant des indicateurs de performance spécifiques au genre ainsi qu’un système complet de suivi et d'évaluation.
							</li>
							<li>
								<span className="font-semibold">
									Cluster national pour l'égalité des Genres : {" "}
								</span>
								ce forum réunit le gouvernement du Rwanda, les partenaires de développement, le secteur privé et la société civile pour discuter de la planification, de la coordination et de la hiérarchisation d'interventions en matière d'égalité des genres. Il facilite la collaboration entre les différents acteurs ainsi que la coordination de toutes les interventions par le partage d'expériences, la création de nouvelles idées, l'identification des lacunes, le lobbying et la défense de l'équité et de l'égalité des genres dans tous les secteurs.
							</li>
							<li>
								<span className="font-semibold">Points focaux sur le genre : </span>
								Au niveau opérationnel, la responsabilité d'assurer la mise en œuvre efficace de la politique nationale de l'égalité des genres incombe principalement aux différents services gouvernementaux au niveau national et au niveau des districts. Dans tous les ministères, les directeurs de la planification sont astreints à assumer les fonctions de points focaux pour l'égalité des genres.
							</li>
							<li>
								<span className="font-semibold">Isange One Stop Centre: </span>
								cette institution est la réponse la plus importante du Rwanda au défi des violences basées sur le genre. Elle a été créé en 2008 à Kigali et compte aujourd'hui 44 centres opérationnels dans tout le pays. Le Centre est une initiative multisectorielle et interdisciplinaire qui réunit le Ministère de la Santé, la Police Nationale et le Ministère de la Justice. Ils sont sous la coordination du Ministère du Genre et de la Promotion de la Famille. Le Centre fournit des services psychosociaux, médicaux, policiers et juridiques aux adultes et aux enfants qui ont survécu aux violence basées sur le genre et à la maltraitance des enfants.
							</li>
						</ul>
						<p className="my-4">
							En un quart de siècle, les femmes rwandaises ont considérablement renforcé leur place dans la société tout en réalisant des progrès sociaux tangibles.
						</p>
					</div>
					<div className="my-4">
						<p className="font-semibold">Propriété foncière</p>
						<div
							className="border-b-4 border-fifth"
							style={{ height: "24rem" }}
						>
							<img src={infographics037} alt="" className="h-full" />
						</div>
						<p className="font-semibold text-right text-fifth">
							Source : RNRA, données administratives, 2019
						</p>
					</div>
					<div className="my-4">
						<div className="border-b-4 border-fifth">
							<img src={infographics039} alt="" />
						</div>
						<p className="font-semibold text-right text-fifth">
							Source : McKinsey Global Institute,  Le pouvoir de la parité : Promouvoir l'égalité des femmes en Afrique, 2019.
						</p>
					</div>
				</div>

				<div>
					<Link
						to="/country/economy"
						className="flex flex-row items-center justify-end pt-12 font-semibold md:justify-start hover:text-fifth text-primary"
					>
						Économie &nbsp; <HiOutlineArrowNarrowRight />
					</Link>
					<Link
						to="/country/institutionalisation"
						className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-fifth text-primary"
					>
						<HiOutlineArrowNarrowLeft /> &nbsp; Institutionnalisation de la bonne gouvernance
					</Link>
				</div>
			</div>
		);
	}
}

export default CPGender;
