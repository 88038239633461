import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
	HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import image026 from "../assets/images/Ubudehe4.JPG";

export class UbudeheOrganization extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Organisation et Coordination</p>
				<div className="">
					<p className="my-4">
						Pour rappel, l’administration territoriale rwandaise se subdivise du plus bas au plus élevé de la façon suivante : le village (<span className="italic">Umudugudu</span>), la cellule (<span className="italic">Akagari</span>), le secteur (<span className="italic">Umurenge</span>), le district (<span className="italic">Akarere</span>), la province (<span className="italic">Intara</span>) et le niveau national central.
					</p>
					<p className="my-4">
						<span className="italic">Ubudehe</span> est un programme hautement décentralisé. Il est ancré au niveau administratif le plus en aval, le village. Par conséquent, il est mis en œuvre et contrôlé par les entités administratives de ce niveau.
					</p>
					<p className="my-4">
						Le Ministère du Gouvernement Local (MINALOC) et l'Agence de Développement des entités Administratives Locales (LODA) sont chargés de superviser la mise en œuvre de <span className="italic">Ubudehe</span>. Ils collaborent avec les responsables à tous les niveaux de l’appareil d’état, mais surtout au niveau des villages afin d’assurer le fonctionnement efficace du programme.
					</p>
					<p className="my-4">
						Des comités <span className="italic">Ubudehe</span> existent à tous les niveaux administratifs. Les communautés villageoises du projet prioritaire décident lors d’assemblées générales qu’elles vont entreprendre. Ce choix est ensuite validé au niveau du comité de <span className="italic">Ubudehe</span> du district.
					</p>
					<p className="my-4">
						Les fonds sont ensuite transférés au niveau du secteur. Le secteur verse l'argent sur le compte bancaire de <span className="italic">Ubudehe</span> du village, qui est généralement domicilié dans les organisations coopératives d'épargne et de crédit (SACCO) ou à la Banque Populaire du Rwanda. Le comité de <span className="italic">Ubudehe</span> au niveau du village est responsable de déterminer qui a droit aux fonds et à leur gestion.
					</p>
					<div className="my-6">
						<div className="">
							<img src={image026} alt="" />
						</div>
					</div>
				</div>
				<Link
					to="/hgs/ubudehe/impact"
					className="flex flex-row items-center justify-end mt-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					L'impact d'<span className="italic">Ubudehe</span> &nbsp; <HiOutlineArrowNarrowRight />
				</Link>
				<Link
					to="/hgs/ubudehe/poverty-level-categories"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; <p>Niveaux de pauvreté <span className="italic">Ubudehe</span></p>
				</Link>
			</div>
		);
	}
}

export default UbudeheOrganization;
