import React, { Component } from "react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import PageName from "./PageName";
import image021 from "../assets/images/What4.jpeg";
import { Link } from "react-router-dom";

export class WhatMakes4 extends Component {
	render() {
		return (
			<div className="relative flex flex-col w-full p-4 text-justify md:h-screen lg:p-40 md:p-16">
				<div className="hidden md:flex">
					<PageName
						title="Que sont les HGI"
						color="primary"
						accentColor="secondary"
						subTitle={
							<>
								retour <HiOutlineArrowNarrowLeft />
							</>
						}
						to="/"
					/>
				</div>
				<div className="grid gap-4 md:grid-cols-2">
					<div className="relative h-full md:text-sm">
						<p className="">
							Les visites présidentielles dans les districts, au cours desquelles la population peut s'adresser directement au chef de l'État et exprimer ses préoccupations, ont des effets similaires à ceux d’<span className="italic">Umushyikirano</span>. Ces assemblées sont diffusées en direct à la radio et à la télévision nationales.
						</p>
						<p className="my-4">
							<span className="italic">Imihigo</span> ou les contrats de performance, tirent leurs origines d'une ancienne tradition de déclarations solennelles durant lesquelles, à la veille d’une bataille, les hauts commandants de l’armée s'engageaient envers le roi à accomplir certains hauts faits. À la fin de la guerre, lors du débriefing, les engagements pris étaient minutieusement et publiquement évalués. <span className="italic">Imihigo</span> modernes ont été adoptés en 2006 en tant que mécanisme de contrôle et d'évaluation du travail des institutions publiques et de leurs fonctionnaires. <span className="italic">Imihigo</span> détaillent les objectifs spécifiques que les fonctionnaires doivent atteindre au cours de l'année à venir. Ils sont destinés à combiner les priorités nationales aux objectifs communautaires. Par la suite, Imihigo se sont bureaucratisés et sont devenus un mécanisme de gestion des performances et de gestion des finances publiques, ceci afin d'éviter une tendance, par certaines autorités locales, à faire de fausses déclarations de réalisations accomplies.
						</p>
					</div>
					<div className="flex items-center h-full xl:pl-20 md:pl-4">
						<div className="">
							<img src={image021} alt="" />
						</div>
					</div>
				</div>
				<div className="flex flex-col md:hidden">
					<Link
						to="/hgs/how-did-they-emerge"
						className="flex flex-row items-center justify-end w-full py-2 pt-8 font-semibold hover:text-secondary text-primary"
					>
						<HiOutlineArrowNarrowLeft /> &nbsp; comment sont-ils apparus?
					</Link>
				</div>
			</div>
		);
	}
}

export default WhatMakes4;
