import React, { Component, Fragment } from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { Route, Switch } from "react-router-dom";
//import AbunziChallenges from "../components/AbunziChallenges";
import AbunziHowItWorks from "../components/AbunziHowItWorks";
import AbunziOverview from "../components/AbunziOverview";
import AbunziPerformance from "../components/AbunziPerformance";
import AbunziPractices from "../components/AbunziPractices";
import HGSSideNav from "../components/HGSSideNav";
import PageName from "../components/PageName";
import { abunziSubmenus } from "../utils/menus";

export class Abunzi extends Component {
	render() {
		const { match } = this.props;
		return (
			<Fragment>
				<div className="relative flex flex-row w-full h-screen">
					<div className="hidden w-4/12 md:flex">
						<HGSSideNav
							hgsTitle="Abunzi"
							hgsSubTitle="gouvernance et justice"
							hgsIntro={
								<p className="text-justify">
									Le système des médiateurs communautaires, également connu sous le nom d'Abunzi,
									combine les méthodes traditionnelles et modernes de résolution des conflits. 
									C'est aussi l'une des initiatives endogènes du Rwanda
									(HGI), bien que des mécanismes similaires existent ailleurs.
								</p>
							}
							submenus={abunziSubmenus}
						/>
					</div>
					<div className="relative flex px-4 pt-40 overflow-y-scroll bg-white md:w-8/12 lg:px-10 xl:px-24">
						<div>
							<PageName
								title="Que sont les HGI"
								color="primary"
								accentColor="secondary"
								subTitle={
									<>
										Gacaca &nbsp; <HiOutlineArrowNarrowRight />
									</>
								}
								to="/hgs/gacaca/overview"
							/>
						</div>
						<Switch>
							<Route exact path={`${match.path}`} component={AbunziOverview} />
							<Route
								exact
								path={`${match.path}/overview`}
								component={AbunziOverview}
							/>
							<Route
								exact
								path={`${match.path}/how-it-works`}
								component={AbunziHowItWorks}
							/>
							<Route
								exact
								path={`${match.path}/performance`}
								component={AbunziPerformance}
							/>
							{/* <Route
								exact
								path={`${match.path}/challenges`}
								component={AbunziChallenges}
							/> */}
							<Route
								exact
								path={`${match.path}/practices`}
								component={AbunziPractices}
							/>
						</Switch>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default Abunzi;
