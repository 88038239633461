import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
	HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import image046 from "../assets/images/Umuganda2.jpg";
import image037 from "../assets/images/hgs-content-image037.jpeg";
import image007 from "../assets/images/hgs-content-image007.jpeg";

export class UmugandaHowItWorks extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Comment <span className="italic">Umuganda</span> est-il organisé ?</p>
				<div className="">
					<p className="my-4 text-xl font-semibold">
						Organisation de l'<span className="italic">Umuganda</span>
					</p>
					<p className="my-4">
						Le Ministère de l’Administration Locale (MINALOC) est l'autorité gouvernementale centrale responsable des activités de l'<span className="italic">Umuganda</span>. D’autres ministères et autres institutions apportent par ailleurs leur contribution.
					</p>
					<p className="my-4 text-xl font-semibold">
						Coordination et intervenants concernés
					</p>
					<p className="my-4">
						Les intervenants concernés par l'<span className="italic">Umuganda</span> sont : les comités de supervision de l'<span className="italic">Umuganda</span>, les dirigeants locaux et les communautés elles-mêmes.
					</p>
					<p className="text-xl font-semibold">Comités de supervision de l'<span className="italic">Umuganda</span></p>
					<p className="my-4">
						Il existe des comités <span className="italic">Umuganda</span> au niveau du village, de la cellule, du secteur, du district, de la province et au niveau national. La structure en est présentée dans le tableau ci-dessous. Le niveau umudugudu (village) est responsable de la conduite de la coopération, de la collaboration et de la solidarité communautaire ainsi que de toutes les activités commençant à ce niveau.
					</p>
					<p className="my-4">
						Le rôle des comités de supervision umudugudu est de planifier le travail communautaire, d'organiser et de superviser l'activité de travail communautaire. Il effectue également l'évaluation du travail communautaire et réalise des rapports indiquant la valeur monétaire du travail. Ces comités travaillent aussi avec les communautés et décident conjointement des activités à entreprendre. Ils informent alors la communauté concernée sept jours avant le prochain <span className="italic">Umuganda</span>.
					</p>
					<p className="mt-4 font-semibold">Structure des comités de supervision de l'<span className="italic">Umuganda</span></p>
					<div className="my-4">
						<table className="w-full text-sm text-right border border-collapse border-gray-400 hgs-table">
							<tr className="font-semibold text-left uppercase bg-hgsBgLight " rowSpan="2">
								<td className="w-1/2">Comité de pilotage</td>
								<td>Comité technique</td>
							</tr>
							<tr className="text-center uppercase border bg-hgsBgLight" >
								<td colSpan="2">Au niveau national</td>
							</tr>
							<tr className="text-left">
								<td>
									Le rôle du comité de pilotage est de planifier, d'évaluer et d'encourager les membres du public à participer à l'Umuganda. Ce comité fait également connaître les résultats de l'<span className="italic">Umuganda</span>.
								</td>
								<td>
									Le comité technique est responsable de la coordination, de la mise en œuvre, de l'évaluation et du développement des programmes de l'<span className="italic">Umuganda</span>
								</td>
							</tr>
							<tr className="text-center uppercase border bg-hgsBgLight">
								<td colSpan="2">Au niveau de la province et de la ville de Kigali</td>
							</tr>
							<tr className="text-left">
								<td>
									Le comité de pilotage coordonne les activités de l'<span className="italic">Umuganda</span> au niveau du district. Il évalue et fait son rapport au comité de supervision au niveau national. Il encourage les citoyens à participer au travail communautaire.
								</td>
								<td>
									Le comité technique analyse les rapports des districts concernés et en fournit un résumé au MINALOC.
								</td>
							</tr>
							<tr className="text-center uppercase border bg-hgsBgLight">
								<td colSpan="2">Au niveau du district</td>
							</tr>
							<tr className="text-left">
								<td></td>
								<td>
									Les comités techniques préparent et supervisent l'<span className="italic">Umuganda</span>, l'évaluent et font un rapport au comité de supervision au niveau de la province ou de la ville de Kigali.
								</td>
							</tr>
							<tr className="text-center uppercase border bg-hgsBgLight">
								<td colSpan="2">
									Des comités techniques fonctionnent également au niveau du secteur, de la cellule et du village
								</td>
							</tr>
						</table>
					</div>
					<p className="my-4">
						Le comité de supervision du niveau administratif au sein duquel les travaux ont été effectués doit présenter un rapport après l'achèvement des travaux. Ce rapport doit indiquer la valeur monétaire des travaux entrepris.
					</p>
					<p className="my-4">
						Le comité de supervision des travaux communautaires au niveau national est tenu de soumettre un rapport au Premier Ministre tous les six mois. Les rapports <span className="italic">Umuganda</span>, quel que soit le niveau auquel ils sont produits, doivent inclure le nombre de personnes ayant participé aux travaux, les activités spécifiques entreprises ainsi que leur valeur monétaire.
					</p>
					<div className="grid gap-4 my-4 mt-4 md:grid-cols-2">
						<div className="">
							<img src={image046} alt="" />
						</div>
						<div className="">
							<img src={image037} alt="" />
						</div>
					</div>
					<p className="my-4 text-xl font-semibold">
						Dirigeants locaux
					</p>
					<p className="my-4">
						Le rôle des dirigeants locaux est de promouvoir la participation à l'<span className="italic">Umuganda</span>, de mettre en place des mécanismes de suivi des activités réalisées, de promouvoir la participation active des femmes et des jeunes, et de renforcer les comités de l’<span className="italic">Umuganda</span>.
					</p>
					<p className="my-4 text-xl font-semibold">
						La communauté
					</p>
					<p className="my-4">
						Enfin, le rôle de la communauté est de s'approprier la planification et l'organisation de l'<span className="italic">Umuganda</span>, de réaliser les travaux et de s'approprier les activités réalisées afin de les pérenniser.
					</p>
					<p className="my-4">
						Ceux qui ne peuvent pas participer pour des raisons professionnelles ou personnelles sont tenus d'en informer le comité, qui transmet ensuite ces informations à la communauté concernée. Si les membres de la communauté ne participent pas lorsqu'ils en ont la possibilité, le comité de surveillance peut leur infliger une amende qui doit être utilisée pour soutenir le travail communautaire. Toutefois, avant qu'une amende puisse être infligée, le comité doit d'abord en parler à la personne et l'encourager à participer.
					</p>
					<p className="my-4">
						Le comité de surveillance au niveau duquel les travaux ont été effectués doit présenter un rapport après la fin des travaux. Tout rapport concernant l’<span className="italic">Umuganda</span>, quel que soit le niveau auquel il est rattaché, doit inclure le nombre de personnes ayant participé aux travaux, les activités spécifiques entreprises et leur valeur monétaire. Le comité <span className="italic">Umuganda</span> au niveau national est tenu de soumettre un rapport au Premier Ministre tous les six mois.
					</p>
					<p className="my-4">
						En 2009, le Concours National <span className="italic">Umuganda</span> a été introduit pour encourager la présentation de rapports adéquats et pour promouvoir la participation à l'<span className="italic">Umuganda</span> à tous les niveaux de la société. Au niveau du district, la meilleure activité est récompensée par un certificat et un financement pour les projets futurs. Les trois meilleurs projets de chaque province sont récompensés. Les trois meilleurs projets de tout le Rwanda reçoivent un prix en espèces.
					</p>

					<div className="my-6">
						<div className="">
							<img src={image007} alt="" />
						</div>
					</div>

				</div>
				<Link
					to="/hgs/umuganda/impact"
					className="flex flex-row items-center justify-end mt-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					L'impact d'<span className="italic">Umuganda</span> &nbsp; <HiOutlineArrowNarrowRight />
				</Link>
				<Link
					to="/hgs/umuganda/overview"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; Aperçu
				</Link>
			</div>
		);
	}
}

export default UmugandaHowItWorks;
