import React, { Component } from "react";
import { Link } from "react-router-dom";
import CPLeftImage from "./CPLeftImage";
import PageName from "./PageName";
import HGSbgImage from "../assets/images/HGI-Homepage.jpg";
import { MdKeyboardArrowRight } from "react-icons/md";

export class WhatAre extends Component {
	render() {
		return (
			<div className="relative flex flex-col w-full md:h-screen">
				<div className="sm:hidden">
					<PageName
						title="Que sont les HGI"
						color="primary"
						accentColor="secondary"
					/>
				</div>

				<div className="grid gap-4 md:grid-cols-2">
					<div className="relative h-full">
						<div className="hidden md:flex">
							<CPLeftImage image={HGSbgImage} />
						</div>
						<div className="flex flex-col mt-20 border-b-8 md:hidden border-secondary">
							<div className="flex flex-row items-center px-4 py-2 text-xs uppercase">
								<div>
									<Link to="/" className="font-semibold text-secondary">
										Home
									</Link>
								</div>
								&nbsp;
								<MdKeyboardArrowRight className="text-xl" />
								&nbsp;
								<div className="font-semibold">
									Que sont Les initiatives endogènes du Rwanda ?
								</div>
							</div>
							<img src={HGSbgImage} alt="" />
						</div>
						<div className="top-0 flex flex-col w-full p-4 md:absolute md:h-screen md:bg-primary md:bg-opacity-75 lg:px-10 md:px-4 md:justify-center">
							<div className="py-12 overflow-y-auto text-justify lg:font-semibold lg:text-sm md:text-xs">
								<div className="text-xl font-semibold md:hidden">
									Que sont Les initiatives endogènes du Rwanda ?
								</div>
								<p className="my-4">
									Les initiatives endogènes rwandaises, en anglais Home Grown Initiatives (HGI), sont constituées d’une série de mécanismes institutionnels et organisationnels développée par les citoyens en fonction d’opportunités locales. Elles se basent sur les valeurs culturelles et l'histoire du pays dans le but d’accélérer son développement.
								</p>
								<p>
									Globalement, les HGI sont basées sur la communauté et donnent un rôle central à la population (société civile) pour faire face à des défis sociaux importants, défis tels que la promotion du développement rural et de la protection sociale, ou, dans le passé récent du Rwanda, la gestion des procès liés au génocide. Ces mécanismes s'inspirent souvent de la culture traditionnelle, ce qui renforce leur acceptation sociale. En outre, un certain nombre de bonnes pratiques ont été conçues en complément des HGI.
								</p>
								<p className="my-4">
									Les HGI sont apparues progressivement au sortir du contexte difficile de l'après-génocide. Les Rwandais étaient à l’époque confrontés à des problèmes de prime abord insurmontables : le pays connaissait une profonde fracture sociale et était confronté à un environnement international difficile. Dans leur détermination à préserver et à poursuivre leur autonomie (kwigira), les citoyens rwandais ont été dans l’obligation de trouver des solutions innovantes. Pour ce faire, ils ont utilisé l'une des ressources sociales les plus précieuses qui leur étaient disponibles : le patrimoine culturel.
								</p>
								<p>
									Les HGI ont fortement contribué au dynamisme de la transformation socio-économique et politique du Rwanda post-génocide. Elles ont également contribué à la réalisation des Objectifs du Millénaire pour le Développement (OMD). En 2020, la Banque mondiale a déclaré, à propos du Rwanda : "une forte focalisation sur les politiques et initiatives locales a contribué à une amélioration significative de l'accès aux services publics et des indicateurs de développement humain". Suite à leurs différents succès, la recherche d'initiatives endogènes pour traiter des questions qui concernent les citoyens a été inscrite dans la constitution.
								</p>
							</div>
						</div>
					</div>
					<div className="flex flex-col h-full px-4 overflow-x-auto md:pt-20 lg:px-8 lg:pr-24 md:pr-12">
						<p className="my-4 text-sm text-justify md:font-semibold">
							Les HGI interviennent dans les processus de gouvernance et de justice, d'économie et de bien-être social. Les principales HGI qui ont stimulé le développement socio-économique sont énumérées dans le Tableau 1 :
						</p>
						<div className="flex flex-col my-4 overflow-x-auto border">
							<table className="border border-collapse hgs-list-table border-secondary">
								<thead>
									<tr className="w-full px-4 py-1 font-semibold text-white uppercase bg-primary">
										<th>Gouvernance et justice</th>
										<th>Économie</th>
										<th>Protection sociale</th>
									</tr>
								</thead>

								<tbody className="text-sm">
									<tr>
										<td className="">
											<Link
												to="/hgs/gacaca/overview"
												className="pr-8 font-semibold hover:text-primary text-secondary"
											>
												Gacaca <br />
											</Link>
											<span>(Résolution traditionnelle des conflits)</span>
										</td>
										<td>
											<Link
												to="/hgs/umuganda/overview"
												className="pr-8 font-semibold hover:text-primary text-secondary"
											>
												Umuganda <br />
											</Link>
											<span>(Travail communautaire)</span>
										</td>
										<td>
											<Link
												to="/hgs/ubudehe/overview"
												className="pr-8 font-semibold hover:text-primary text-secondary"
											>
												Ubudehe <br />
											</Link>
											<span>
												(Programme de développement socio-économique participatif)
											</span>
										</td>
									</tr>
									<tr>
										<td>
											<Link
												to="/hgs/abunzi/overview"
												className="pr-8 font-semibold hover:text-primary text-secondary"
											>
												Abunzi <br />
											</Link>
											<span>(Comités de médiation et de réconciliation)</span>
										</td>
										<td>
											<Link
												to="/hgs/imihigo/overview"
												className="pr-8 font-semibold hover:text-primary text-secondary"
											>
												Imihigo <br />
											</Link>
											<span>(Contrats de performance)</span>
										</td>
										<td>
											<Link
												to="/hgs/girinka/overview"
												className="pr-8 font-semibold hover:text-primary text-secondary"
											>
												Girinka <br />
											</Link>
											<span>(Une vache par famille pauvre)</span>
										</td>
									</tr>
									<tr>
										<td>
											<Link
												to="/hgs/umushyikirano/overview"
												className="pr-8 font-semibold hover:text-primary text-secondary"
											>
												Inama y’Igihugu y’Umushyikirano <br />
											</Link>
											<span>(Conseil national du dialogue)</span>
										</td>
										<td rowSpan="3"></td>
										<td rowSpan="3"></td>
									</tr>
									<tr>
										<td>
											<Link
												to="/hgs/itorero/overview"
												className="pr-8 font-semibold hover:text-primary text-secondary"
											>
												Itorero/Ingando <br />
											</Link>
											<span>(Programme national d'éducation civique)</span>
										</td>
									</tr>
									<tr>
										<td>
											<Link
												to="/hgs/umwiherero/overview"
												className="pr-8 font-semibold hover:text-primary text-secondary"
											>
												Umwiherero <br />
											</Link>
											<span>(Retraite des dirigeants nationaux)</span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default WhatAre;
