import React, { Component } from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import HGSAnimatedContainer from "./HGSAnimatedContainer";
import PageName from "./PageName";
import image027 from "../assets/images/umwiherero.jpg";
import { Link } from "react-router-dom";

export class WhatAre6 extends Component {
    render() {
        return (
            <div className="relative px-4 md:p-40">
                <div className="hidden md:flex">
                    <PageName
                        title="Que sont les HGI"
                        color="primary"
                        accentColor="secondary"
                        subTitle={
                            <>
                                comment sont-ils apparus? <HiOutlineArrowNarrowRight />
                            </>
                        }
                        to="/hgs/how-did-they-emerge"
                    />
                </div>
                <div className="flex flex-col justify-center md:flex-row lg:gap-20 md:gap-8">
                    <div className="lg:my-20 md:my-12">
                        <HGSAnimatedContainer
                            to="/hgs/umwiherero/overview"
                            image={image027}
                            title="Umwiherero"
                            subtitle="Gouvernance et justice"
                            text={
                                <p>
                                    Umwiherero, désigne une tradition de la culture rwandaise 
                                    durant laquelle les dirigeants se retiraient dans un lieu 
                                    isolé pour réfléchir aux problèmes rencontrés par leurs 
                                    communautés, afin d'identifier des solutions.
                                </p>
                            }
                        />
                    </div>
                </div>
                <div className="flex md:hidden">
                    <Link
                        to="/hgs/how-did-they-emerge"
                        className="flex flex-row items-center justify-end w-full py-12 font-semibold hover:text-secondary text-primary"
                    >
                        comment sont-ils apparus? &nbsp; <HiOutlineArrowNarrowRight />
                    </Link>
                </div>
            </div>
        );
    }
}

export default WhatAre6;
