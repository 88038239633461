import React, { Component } from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import Kagame from "../assets/images/1.Overview.jpg";
import CPbgImage from "../assets/images/homeCP.jpg";
import PageName from "../components/PageName";

export class CPIntroduction extends Component {
	render() {
		return (
			<div className="text-justify">
				<PageName
					title="Profil du pays"
					color="primary"
					accentColor="fifth"
					subTitle={
						<>
							Les Faits &nbsp; <HiOutlineArrowNarrowRight />
						</>
					}
					to="/country/overview"
				/>
				<p className="text-3xl font-semibold">Aperçu</p>
				<div
					className="my-8 bg-center bg-no-repeat bg-cover"
					style={{
						backgroundImage: `url(${CPbgImage})`,
						height: "30rem",
					}}
				></div>
				<div className="">
					<p>
						Les paysages du Rwanda lui ont valu le nom de « Pays des Mille Collines ».
					</p>
					<p className="my-4">
						De taille modeste avec 26 338 kilomètres carrés, le pays est densément peuplé par 12,3 millions d'habitants. Soixante-dix pour cent de la population du Rwanda a moins de 30 ans. Bien que le Rwanda soit situé près de l'équateur, le pays jouit d'un climat modéré avec des températures comprises entre 24 degrés Celsius et 27 degrés Celsius. Son altitude varie entre 1 000 et 4 507 mètres au-dessus du niveau de la mer.
					</p>
					<p className="my-4">
						Le Rwanda est situé aux frontières de l'Afrique orientale et centrale. Il fait partie à la fois de la Communauté de l'Afrique de l'Est et de la Communauté Economique des États de l'Afrique centrale. C'est un pays enclavé, entouré par l'Ouganda, la Tanzanie, la République Démocratique du Congo (RDC) et le Burundi. Il partage par ailleurs le lac Kivu avec la RDC.
					</p>
					<p className="my-4">
						Presque tous les Rwandais parlent la langue nationale, le kinyarwanda. Le pays compte trois autres langues officielles, l'anglais, le français et le swahili.
					</p>
					<p className="my-4">
						Au cours des deux dernières décennies, le pays a connu une stabilité politique et sociale soutenue par une forte croissance économique. Le Rwanda est reconnu pour la qualité de sa gouvernance et le rôle important joué par les femmes dans la vie de la nation.
					</p>
				</div>
				<div className="flex flex-col w-full my-8 bg-gray-100 lg:flex-row">
					<div className="flex-1 p-4 md:p-8 lg:p-16 lg:px-16">
						<p className="my-4 font-semibold xl:text-xl">
							<span className="italic font-bold">"  </span>
							Après 1994, tout était une priorité et notre peuple était complètement brisé.
							Mais nous avons fait les choix fondamentaux qui nous guident encore aujourd'hui.
						</p>
						<div className="my-4 font-semibold text-left xl:text-xl">
							<p>Premièrement : Nous avons choisi de rester unis.</p>
							<p>Deuxièmement : Nous avons choisi de nous rendre des comptes à nous-mêmes.</p>
							<p>Troisièmement : Nous avons choisi d'être ambitieux<span className="italic font-bold"> "</span></p>
						</div>
						<p className="my-8 font-semibold text-fifth xl:text-lg">
							S.E. Paul Kagame, Président du Rwanda
						</p>
					</div>
					<div className="flex-1">
						<img src={Kagame} alt="" className="" />
					</div>
				</div>
				<Link
					to="/country/overview"
					className="flex flex-row items-center justify-end py-12 font-semibold md:justify-start hover:text-fifth text-primary"
				>
					Les faits &nbsp; <HiOutlineArrowNarrowRight />
				</Link>
			</div>
		);
	}
}

export default CPIntroduction;
