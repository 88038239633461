import React, { Component } from 'react'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import ReactPlayer from 'react-player/lazy';
import { Link } from 'react-router-dom';

export class UbudeheOverview extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">Aperçu</p>
				<div className="w-full my-4">
					<ReactPlayer
						width="100%"
						height="30rem"
						controls={true}
						loop={false}
						muted={true}
						url="https://youtu.be/yOxuSBkQxyQ"
					/>
				</div>
				<div className="my-6">
					<p className="my-4">
						<span className="italic">Ubudehe</span> réfère à l'ancienne pratique culturelle rwandaise d'activités collectives et de soutien mutuel pour résoudre les problèmes au sein de la communauté. <span className="italic">Ubudehe</span> traditionnel était principalement pratiqué lors des travaux champêtres.
					</p>
					<p className="my-4">
						Aujourd'hui, <span className="italic">Ubudehe</span> est une initiative de réduction de la pauvreté entreprise par le Gouvernement du Rwanda. Elle fournit aux communautés les compétences et le soutien nécessaires pour s'engager dans la résolution des problèmes et la prise de décisions pour leur propre développement. Le programme aide les citoyens à collaborer avec les institutions locales pour atteindre les objectifs fixés par la communauté.
					</p>
					<p className="my-4">
						<span className="italic">Ubudehe</span> a été réintroduit au Rwanda en 2001 en tant que moyen de renforcer les processus démocratiques et une gouvernance participative dans la prise de décisions. <span className="italic">Ubudehe</span> crée des opportunités pour les citoyens à tous les niveaux de la société, en particulier au niveau du village, d'interagir les uns avec les autres, de partager des idées, d'initier des institutions et de prendre des décisions pour le développement collectif.
					</p>
					<p className="my-4">
						<span className="italic">Ubudehe</span> est l'une des initiatives endogènes les plus connues au Rwanda pour son approche participative au développement en matière de réduction de la pauvreté. En 2008, le programme a remporté le Prix des Nations Unies pour la Fonction Publique pour l’excellence de sa prestation de services. <span className="italic">Ubudehe</span> est devenu l'un des principaux programmes de développement du pays.
					</p>
				</div>
				<Link
					to="/hgs/ubudehe/how-it-works"
					className="flex flex-row items-center justify-end py-12 font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<p>Comment fonctionne <span className="italic">Ubudehe</span>?</p> &nbsp; <HiOutlineArrowNarrowRight />
				</Link>
			</div>
		);
	}
}

export default UbudeheOverview
