import React, { Component } from "react";
// import rwPediaLogoBlack from "../assets/images/RWandaPedia Logo_Edit-06.png";
import rwPediaLogoWhite from "../assets/images/Rwandapedia_Logo_white.svg";
import NavigationDots from "../components/NavigationDots";
import NavigationScroll from "../components/NavigationScroll";
import { Link } from "react-router-dom";
// import homeCPImage from "../assets/images/cp-content-image009.jpeg";
import homeCP from "../assets/images/homeCP.jpg";

export class HomeCountryProfile extends Component {
	render() {
		return (
			<section className="home-section h-screen w-full bg-primary text-white">
				<div
					className="home-background bg-cover bg-no-repeat bg-center"
					style={{ backgroundImage: `url(${homeCP})` }}
				></div>
				<div className="home-cp-gradient absolute w-full h-full z-10"></div>
				<div className="sm:w-full sm:px-4 home-left flex flex-col md:px-8 pb-8 h-full">
					<div className="flex flex-row h-full items-center">
						<div className="home-intro-content pr-20">
							<div className="home-section-logo flex md:w-48 py-4 ">
								<img src={rwPediaLogoWhite} alt="" className="w-full h-full" />
							</div>
							<div className="home-title text-6xl font-bold">
								<h2 className="sm:text-2xl md:text-3xl xl:text-4xl">
									Profil du pays
								</h2>
							</div>
							<div className="home-intro-paragraph sm:text-sm xl:text-base pt-4 pr-8">
								<p className="my-2">
									Ce sont ses paysages qui ont valu au Rwanda le nom de{" "}
									<span className="font-bold">
										pays des mille collines.
									</span>
									{" "}
									Au cours des deux dernières décennies, le pays a connu une stabilité
									politique et sociale portée par une forte croissance économique. Le
									Rwanda est reconnu pour la qualité de sa gouvernance et le rôle important
									joué par les femmes dans la vie de la nation.
								</p>
							</div>
							<div className="flex flex-row w-full items-start">
								<div className="home-buttons flex flex-row">
									<Link
										to="/country/introduction"
										className="sm:btn lg:px-16 md:px-3 lg:py-6 md:py-2 my-5 text-white hover:bg-secondary font-bold border-2 border-white hover:border-secondary"
									>
										Apprendre plus
									</Link>
								</div>
							</div>
						</div>
						<div className="home-scroll-controls flex flex-col h-full w-auto justify-center items-center">
							<NavigationDots active={2} />
						</div>
					</div>
					<NavigationScroll to="home-3" />
				</div>
			</section>
		);
	}
}

export default HomeCountryProfile;
