import React, { Component } from "react";
import CPLeftImage from "./CPLeftImage";
import PageName from "./PageName";
import image033 from "../assets/images/What-Background.jpg";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";

export class WhatMakes extends Component {
	render() {
		return (
			<div className="relative flex flex-col w-full text-justify md:h-screen">
				<div className="sm:hidden">
					<PageName
						title="Que sont les HGI"
						color="primary"
						accentColor="secondary"
					/>
				</div>

				<div className="grid md:grid-cols-2">
					<div className="relative h-full">
						<div className="hidden md:flex">
							<CPLeftImage image={image033} />
						</div>
						<div className="flex flex-col mt-20 border-b-8 md:hidden border-secondary">
							<div className="flex flex-row items-center px-4 py-2 text-xs uppercase">
								<div>
									<Link to="/" className="font-semibold text-secondary">
										Home
									</Link>
								</div>
								&nbsp;
								<MdKeyboardArrowRight className="text-xl" />
								&nbsp;
								<div className="font-semibold">
									Comment les HGI fonctionnent-elles ?
								</div>
							</div>
							<img src={image033} alt="" />
						</div>
						<div className="top-0 flex flex-col w-full px-4 md:absolute md:h-screen md:bg-primary md:bg-opacity-75 lg:px-24 md:px-8 md:justify-center">
							<p className="my-4 font-semibold text-left lg:text-2xl md:text-2xl">
								Comment les HGI fonctionnent-elles ?
							</p>
							<p className="my-4 text-sm md:font-bold">
								L'efficacité des HGI dans le contexte rwandais dépend d'un certain nombre de facteurs. Le premier facteur est l'acceptation culturelle, qui est la force intrinsèque des HGI. Leur caractère endogène permet aux acteurs d’identifier intimement le cadre social dans lequel les activités sont entreprises et de fournir un cadre de compréhension mutuelle entre les citoyens et les administrateurs. Cela favorise également une véritable appropriation.
							</p>
						</div>
					</div>
					<div className="flex flex-col h-full overflow-x-auto text-sm md:pt-20 xl:px-20 md:px-8 lg:pr-24 md:pr-8">
						<p className="font-semibold text-left md:my-4 lg:text-2xl md:text-2xl">
							La volonté politique
						</p>
						<p className="">
							L’efficacité des HGI est également liée au contexte de développement plus large dans lequel elles opèrent. À cet égard, les orientations stratégiques définies lors des discussions au Village Urugwiro ont été cruciales. Ces discussions avaient été initiées pour trouver des solutions durables aux problèmes auxquels le pays était confronté, dans l’optique de conduire la transformation du Rwanda. Des réunions ont eu lieu tous les samedis de mai 1998 à mars 1999, réunissant au total 164 participants de toutes les couches de la société. Deux orientations stratégiques principales sont ressorties des discussions : d'une part, le renforcement de l'unité nationale comme but ultime vers lequel toutes les politiques doivent converger et, d'autre part, l'idée que les citoyens doivent être mis au centre des politiques et des stratégies de développement. Par la suite, les différentes stratégies de développement ont réaffirmé la volonté politique exprimée lors des discussions Urugwiro.
						</p>
						<p className="my-4">
							Les HGI ont donc été des véhicules essentiels pour enfin placer les citoyens au cœur du développement et de la transformation du pays. Depuis les discussions Urugwiro, les HGI se sont adaptées à l'évolution du contexte de développement, devenant par ailleurs plus techniques dans un souci d'efficacité et de transparence.
						</p>
					</div>
				</div>
			</div>
		);
	}
}

export default WhatMakes;
