import React, { Component } from "react";
// import rwPediaLogoBlack from "../assets/images/RWandaPedia Logo_Edit-06.png";
import rwPediaLogoWhite from "../assets/images/Rwandapedia_Logo_white.svg";
import NavigationDots from "../components/NavigationDots";
import NavigationScroll from "../components/NavigationScroll";
// import homeImage from "../assets/images/image59.jpeg";
import homeIntro from "../assets/images/homeIntro.jpg";
import { Link } from "react-router-dom";

export class HomeIntro extends Component {
	render() {
		return (
			<section className="w-full h-screen text-white home-section bg-primary">
				<div
					className="bg-center bg-no-repeat bg-cover home-background"
					style={{ backgroundImage: `url(${homeIntro})` }}
				></div>
				<div className="absolute z-10 w-full h-full home-intro-gradient"></div>
				<div className="flex flex-col h-full pb-8 overflow-y-auto sm:w-full sm:px-4 home-left md:px-8">
					<div className="flex flex-row items-center h-full">
						<div className="home-intro-content md:pr-8 lg:pr-20">
							<div className="flex py-4 home-section-logo md:w-48 ">
								<img src={rwPediaLogoWhite} alt="" className="w-full h-full" />
							</div>
							<div className="font-bold home-title sm:text-2xl md:text-3xl xl:text-4xl">
								<p className="">Bienvenue sur Rwandapedia</p>
							</div>
							<div className="pt-4 pr-12 home-intro-paragraph sm:text-sm xl:text-base">
								<p className="">
									Bienvenue sur Rwandapedia. Rwandapedia est une plateforme numérique créée
									dans le but de permettre aux étudiants, chercheurs, décideurs, journalistes
									et fonctionnaires de mieux comprendre le processus de développement du Rwanda.
									On y trouve des informations sur les Initiatives Endogènes Innovantes (Home Grown Initiatives: HGI) : les
									programmes sociaux, économiques, et de gouvernance conçus par le pays, sur
									base de sa culture, pour répondre à ses propres défis.
								</p>
								<p className="my-4">
									Rwandapedia offre à ses utilisateurs un accès gratuit à un vaste répertoire
									de documents, d'images et de vidéos, qui présentent les Initiatives Endogènes Innovantes
									du Rwanda dans les secteurs social, économique et de la gouvernance, ainsi que
									les principaux événements culturels et historiques du pays.
								</p>
							</div>
							<div className="flex flex-row items-start w-full">
								<div className="flex flex-row ">
									<Link
										to="/country/introduction"
										className="mr-4 font-bold text-white border-2 border-white sm:btn lg:px-8 xl:px-16 md:px-3 lg:py-6 md:py-2 hover:bg-secondary hover:border-secondary"
									>
										Profil du pays
									</Link>
									<Link
										to="/hgs/what-are-hgs"
										className="font-bold text-white border-2 border-white sm:btn lg:px-8 xl:px-16 md:px-3 lg:py-6 md:py-2 hover:bg-secondary hover:border-secondary"
									>
										Quels sont les HGI du Rwanda?
									</Link>
								</div>
								<div className="">
									<NavigationScroll to="home-2" />
								</div>
							</div>
						</div>
						<div className="flex flex-col items-center justify-center w-auto h-full home-scroll-controls">
							<NavigationDots active={1} />
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default HomeIntro;
