import React, { Component } from "react";
import {
	HiOutlineArrowNarrowLeft,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import Girinka3 from "../assets/images/Girinka3.jpg"

export class GirinkaImpact extends Component {
	render() {
		return (
			<div className="text-justify">
				<p className="text-3xl font-semibold">L'impact de <span className="italic">Girinka</span></p>
				<div className="">
					<img src={Girinka3} alt="" className="w-full" />
				</div>
				<div className="my-6">
					<p className="my-4">
						Le programme <span className="italic">Girinka</span> a un impact important dans la vie des Rwandais les plus pauvres. Cet impact se rèfléte dans cinq catégories:
					</p>
					<ul className="ml-8 hgs-ordered-list">
						<li>Production agricole</li>
						<li>Sécurité alimentaire</li>
						<li>Possession de bétail</li>
						<li>Résultats en matière de santé</li>
						<li>Cohésion sociale</li>
					</ul>
					<p className="my-4 font-semibold">Production agricole</p>
					<p className="my-4">
						L’initiative <span className="italic">Girinka</span> a contribué au renforcement de la production agricole au Rwanda. La production laitière a par exemple augmenté en raison de l'augmentation des vaches dans le pays et en fonction des dons aux bénéficiaires du programme qui ont reçu des vaches croisées plus productives que les espèces bovines locales. Entre 2010 et 2019, la production de lait a augmenté de 119%, passant de 372 619 litres en 2010 à 816 000 litres en 2019.  La construction de centres de collecte de lait a également augmenté, allant jusqu'à plus de 126 centres opérationnels à l'échelle nationale en 2019.
					</p>
					<p className="my-4">
						La plupart des bénéficiaires produisent suffisamment de lait pour en vendre une partie, ce qui leur procure un revenu monétaire. Le fumier produit par les vaches augmente la productivité des cultures des bénéficiaires, ce qui améliore, par extension, leur production alimentaire et leurs revenus. L’initiative <span className="italic">Girinka</span> a également permis aux bénéficiaires de diversifier leurs cultures, ce qui a conduit à une sécurité alimentaire renforcée.
					</p>
					<p className="my-4 font-semibold">Sécurité alimentaire</p>
					<p className="my-4">
						Selon l'Analyse Globale de la Vulnérabilité, de la Sécurité Alimentaire et de la Nutrition (AGVSAN), l'insécurité alimentaire a diminué au Rwanda, passant de 28% en 2006 à 19% en 2018. La diminution de l'insécurité alimentaire est directement liée au programme <span className="italic">Girinka</span>, particulièrement en ce qui concerne la réduction du retard de croissance chez les enfants.
					</p>
					<p className="my-4 font-semibold">Résultats en matière de santé</p>
					<p className="my-4">
						Le programme <span className="italic">Girinka</span> a contribué à réduire le niveau de malnutrition dans la population, et plus singulièrement chez les enfants de moins de cinq ans. Selon l'enquête Santé et Démographie de 2020, le pourcentage d'enfants souffrant d'un retard de croissance est passé de 51% en 2005 à 33% en 2020. Au cours de la même période, le pourcentage d'enfants émaciés est passé de 5% à 1% et le pourcentage d'enfants présentant une insuffisance pondérale est passé de 18% à 6%.
					</p>
					<p className="my-4 font-semibold">La cohésion sociale</p>
					<p className="my-4">
						Le programme  <span className="italic">Girinka</span> a joué un rôle important dans la reconstruction post-génocide au Rwanda. Pendant la période coloniale, la vache a été utilisée pour diviser les Rwandais selon des lignes ethniques. Le bétail était donc devenu un symbole d'élitisme et un bien réservé à une partie spécifique de la population du pays.
					</p>
					<p className="my-4">
						Cette distorsion a été promue par les gouvernements postcoloniaux successifs dans le cadre de politiques basées sur la division ethnique. Selon un rapport de l'UNICEF, 90% du bétail bovin du pays a été decimé lors du génocide de 1994 contre les Tutsis, du fait de l’association des vaches aux Tutsis. C'est à travers ce contexte historique que le programme <span className="italic">Girinka</span> a été introduit.
					</p>
					<p className="my-4">
						L’initiative <span className="italic">Girinka</span> a changé ce que signifie la possession d’une vache au Rwanda. Si un certain symbolisme de la prospérité est toujours attaché à la vache, en donnant du bétail aux plus pauvres de la société, le programme a contribué à mettre fin à la perception identitaire qui entourait la possession de vaches. Le principe de transmission de <span className="italic">Girinka</span>, qui consiste pour le bénéficiaire à offrir le sa première génisse à un membre de lea communauté, a contribué à reconstruire des relations sociales mises à mal lors du génocide de 1994 contre les Tutsis. En effet, le fait d'offrir une vache à quelqu'un (Gutanga Inka) crée un lien d'amitié et reste une pratique culturelle comprise et appréciée par les rwandais.
					</p>
					<p className="my-4">
						L’initiative joue également un rôle important de réintégration dans la société de groupes sensibles tels que les anciens détenus condamnés pour génocide. Une proportion élevée de ce groupe a déclaré que le fait d'avoir reçu une vache les a aidé à échapper à la pauvreté et à améliorer leur statut social au sein de la communauté.
					</p>
				</div>
				<Link
					to="/hgs/girinka/how-it-works"
					className="flex flex-row items-center justify-end pb-12 mt-4 text-xs font-semibold md:justify-start hover:text-secondary text-primary"
				>
					<HiOutlineArrowNarrowLeft /> &nbsp; Comment fonctionne Girinka
				</Link>
			</div>
		);
	}
}

export default GirinkaImpact;
